import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

export interface UserCard {
    id: string;
    name: string;
    surname: string;
    avatar: string;
    phone: string;
}


interface ConsultationProps {
    description: string;
    user: UserCard;
    doctor: UserCard;
    title: string;
    start: string;
    isDoctor: boolean;
    color: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: 345,
        },
        dateHeader: {
            textAlign: 'right',
            color: '#0172ba',
            fontWeight: 'bold',
            paddingBottom: '5px',
            borderBottom: '2px solid #e6e6e6',
            marginBottom: '2px'
        },
        titleHeader: {
            padding: '2px 5px',
            color: 'rgba(0,0,0,0.7)'
        }
    }),
);

const Consultation = (props: ConsultationProps) => {
    const classes = useStyles();
    return (
		<>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box component="p" className={classes.dateHeader}>
                                {moment(props.start).format('DD.MM.YYYY') }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.titleHeader} style={{ background: props.color }}>
                                { moment(props.start).format('HH:mm') } &nbsp;
                                { props.isDoctor ? `${props.user.name} ${props.user.surname}`: props.title }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                { props.isDoctor ? props.title : props.description }
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Avatar id="Avatar" sizes="40px" src={props.user.avatar && props.user.avatar} >
                                        {`${props.user.name[0]}${props.user.surname[0]}`}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={10}>
                                    {props.user.name} {props.user.surname}
                                    { props.isDoctor &&
                                        <>
                                            <br />
                                            { props.user.phone }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Avatar id="Avatar" sizes="40px" src={props.doctor.avatar && props.doctor.avatar} >
                                        {`${props.doctor.name[0]}${props.doctor.surname[0]}`}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={10}>
                                    {props.doctor.name} {props.doctor.surname}
                                    <br />
                                    {props.doctor.phone}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
		</>
    );
}

export default Consultation;