import {
    LOGIN_SUCCESS,
    CONFIRM_ACCOUNT,
    CONFIRM_ACCOUNT_SUCCESS,
    CONFIRM_ACCOUNT_FAILED,
    LOGOUT,
    UPDATE_TOKEN,
} from './types';

export function loginUserSuccess(token: string, role: boolean, id: number) {
    return {
        type: LOGIN_SUCCESS,
        payload: token,
        role: role,
        userId: id
    }
}

export function logoutUser() {
    return {
        type: LOGOUT
    }
}

export function confirmAccount() {
    return {
        type: CONFIRM_ACCOUNT,
    }
}

export function confirmAccountSuccess() {
    return {
        type: CONFIRM_ACCOUNT_SUCCESS,
    }
}

export function confirmAccountFailed(error: string) {
    return {
        type: CONFIRM_ACCOUNT_FAILED,
        payload: error
    }
}

export function updateToken(token: string) {
    return {
        type: UPDATE_TOKEN,
        payload: token
    }
}

