import {
    GET_NOTIFICATION_TYPES,
    GET_NOTIFICATION_TYPES_FAIL,
    GET_NOTIFICATION_TYPES_SUCCESS,
    GET_NOTIFICATION_STATUSES,
    GET_NOTIFICATION_STATUSES_FAIL,
    GET_NOTIFICATION_STATUSES_SUCCESS,
    ADD_NOTIFICATION_FAIL,
    ADD_NOTIFICATION_SUCCESS
} from './types';
import { IdNameType } from 'views/Measurements/measurements.types';

export function getNotificationTypes() {
    return {
        type: GET_NOTIFICATION_TYPES
    }
}

export function getNotificationTypesSuccess(notificationTypes: IdNameType[]) {
    return {
        type: GET_NOTIFICATION_TYPES_SUCCESS,
        payload: notificationTypes
    }
}

export function getNotificationTypesFail(errorMsg: string) {
    return {
        type: GET_NOTIFICATION_TYPES_FAIL,
    }
}

export function getNotificationStatuses() {
    return {
        type: GET_NOTIFICATION_STATUSES
    }
}

export function getNotificationStatusesSuccess(notificationStatuses: IdNameType[]) {
    return {
        type: GET_NOTIFICATION_STATUSES_SUCCESS,
        payload: notificationStatuses
    }
}

export function getNotificationStatusesFail(errorMsg: string) {
    return {
        type: GET_NOTIFICATION_STATUSES_FAIL,
    }
}

export function addNotificationSuccess(result: boolean) {
    return {
        type: ADD_NOTIFICATION_SUCCESS,
        payload: result
    }
}

export function addNotificationFail(errorMsg: string) {
    return {
        type: ADD_NOTIFICATION_FAIL,
    }
}