import { createSelector } from 'reselect'
import {
    getParametersPropositionsEntities,
    getParametersPropositionsResults,
    getParametersEntities,
    getParametersIds,
    getParametersUnits,
    getParametersUsers,
    getParameterFieldsPropositions,
    getMeasurementTableDataEntities,
    getMeasurementTableDataResults,
    getAddMeasurementParametersObject,
    getAviableUsers,
    getAllGraphsData,
    getGraphDetailsData,
} from './reducer'
import {
    ParameterNameResponseData,
    ParameterTableData,
    NormalizedParametersEntities,
    Parameter,
    IdNameType,
    User,
    ParameterFieldProposition,
    normalizedParameterPropositions,
    Field,
    normalizedMeasurements,
    MeasurementsTableRow,
    AddMeasurement,
    AddParameterObject,
    Schedule,
    GraphsSection,
    GraphDetails,
} from '../measurements.types';
import { denormalize } from 'normalizr';
import parametersSchema from './parametersSchema';
import parameterPropositionsSchema from './parameterPropositionsSchema';
import measurementTableDataSchema from './measurementTableDataSchema';
import { getAccoutData } from 'views/Account/store/reducer';
import { AC_User } from 'views/Account/account.types';
import { AppState } from 'index';


export const allParametersSelector = createSelector(
    getParametersEntities,
    getParametersIds,
    (entities: NormalizedParametersEntities, entitiesIds: string[]): Parameter[] => denormalize(entitiesIds, parametersSchema, entities)
);

export const parametersTableDataSelector = createSelector(
    getParametersEntities,
    getParametersIds,
    (entities: NormalizedParametersEntities, entitiesIds: string[]): ParameterTableData.TableRow[] => {
        let parametersTableData: ParameterTableData.TableRow[] = []
        entitiesIds.map(
            id => {
                const paramEntitie = entities.parameters[id];
                let fields: Field[] = [];
                let users: User[] = [];
                let schedules: Schedule[] = [];
                // tu zmieniłem filtrowanie w map by wszystkie pola odzyskać
                paramEntitie.fields.map(FieldId => (fields.push(entities.fields[FieldId])));
                paramEntitie.users.map(userID => users.push(entities.users[userID]))
                paramEntitie.schedule.map(userId => schedules.push(entities.schedules[userId]))


                const tableRowData = {
                    id: paramEntitie.id,
                    name: paramEntitie.name,
                    fields: fields,
                    unit: entities.units[paramEntitie.unit].name,
                    schedules: schedules,
                    users: users,
                    types: entities.types[paramEntitie.type],
                    equation: paramEntitie.equation,
                    active: paramEntitie.active
                }

                return parametersTableData.push(tableRowData);
            }
        )
        return parametersTableData;
    }
)

export const parametersPropositionsSelector = createSelector(
    getParametersPropositionsEntities,
    getParametersPropositionsResults,
    (entities: normalizedParameterPropositions.ParameterPropositionsEntities, entitiesIds: string[]): ParameterNameResponseData[] => {
        const originalData: ParameterNameResponseData[] = denormalize(entitiesIds, parameterPropositionsSchema, entities)
        return originalData
    }
);

export const unitsNamesSelector = createSelector(
    getParametersUnits,
    (data: { [id: string]: IdNameType }): string[] => {

        const dataKeysAr = !!data && Object.keys(data)
        if (data!! && dataKeysAr.length > 0) {
            return dataKeysAr.map(key => data[key].name)
        } else {
            return []
        }
    }
);

export const userNamesSelector = createSelector(
    getParametersUsers,
    (users: { [id: string]: User }) => {
        const dataNamesAr = !!users && Object.keys(users);
        if (!!users && dataNamesAr.length > 0) {
            return dataNamesAr.map(key => `${users[key].name} ${users[key].surname}`)
        } else {
            return []
        }
    }
);

export const parameterFieldsPropositionsSelector = createSelector(
    getParameterFieldsPropositions,
    (fieldsPropositions: { [id: string]: ParameterFieldProposition }): ParameterFieldProposition[] => Object.keys(fieldsPropositions).map(
        name => fieldsPropositions[name]
    )
);

export const measurementTableDataSelector = createSelector(
    getMeasurementTableDataEntities,
    getMeasurementTableDataResults,
    (entities: normalizedMeasurements.MeasurementEntities, ids: string[]): MeasurementsTableRow[] => {
        const data = denormalize(ids, measurementTableDataSchema, entities);
        //console.log('sele data ', data); // TUUU
        return data;
    }
)

export const parameterListArraySelector = createSelector(
    getAddMeasurementParametersObject,
    (parameters: { [key: string]: AddMeasurement.Parameter }) => Object.keys(parameters).map(key => parameters[key])
)

export const usersSelector = createSelector(
    (state: AppState) => getAviableUsers(state.measurements),
    (state: AppState) => getAccoutData(state.account),
    (users: AddParameterObject.AddParamAviableUsers[], acData: AC_User | undefined): AddParameterObject.AddParamAviableUsers[] => {
        let allUsers = !!acData ? [...users, {
            name: acData.user.name + ' ' + acData.user.surname,
            surname: acData.user.surname,
            avatar: acData.user.avatar,
            id: acData.user.id,
        }] : users

        return allUsers;
    }
)

export const parameterNamesSelector = createSelector(
    getParametersEntities,
    (entities: NormalizedParametersEntities): string[] =>
        (entities && entities.parameters) ? Object.keys(entities.parameters).map(parameter => entities.parameters[parameter].name) : []
);

export const parametersNamesTypesSelector = createSelector(
    getAllGraphsData,
    (data: GraphsSection.Parameter[]): { [id: string]: IdNameType } => {
        let namesTypesObject: { [id: string]: IdNameType } = {};
        data.map((graphData: GraphsSection.Parameter) =>
            namesTypesObject = {
                ...namesTypesObject, [graphData.measurement_parameter_id]: { id: graphData.measurement_parameter_id, name: graphData.name }
            }
        );
        return namesTypesObject;
    }
);

export const parameterNamesIdsUsersSelector = createSelector(
    getParametersEntities,
    (entities: NormalizedParametersEntities): { [key: string]: IdNameType & { users: string[] } } => {
        let namesTypesObject: { [id: string]: IdNameType & { users: string[] } } = {};
        if (entities && entities.parameters) {
            Object.keys(entities.parameters).map(parameter => {
                return +entities.parameters[parameter].active === 1 ? namesTypesObject = {
                    ...namesTypesObject, [entities.parameters[parameter].id]: {
                        name: entities.parameters[parameter].name,
                        id: entities.parameters[parameter].id,
                        users: entities.parameters[parameter].users
                    }
                } : null
            })
        }
        return namesTypesObject
    }
);

export const graphDetailsTableSelector = createSelector(
    getGraphDetailsData,
    (data: GraphDetails.GraphDetailsData): GraphDetails.DetailsTableRow[] => {

        let borders: { [name: string]: { min: string, max: string } } = {}
        data.chart_lines.map(line => borders = { ...borders, [line.name]: line.border })

        let finalTableData: GraphDetails.DetailsTableRow[] = data.chart_points.map(
            point => {
                let measurements: { name: string, value: string | number }[] = []
                let taken_medicines: { name: string }[] = [];
                let omitted_medicines: { name: string }[] = [];
                let omitted_measurements: { name: string }[] = [];
                let comment: string = '';
                let alerts: { name: string, value: string }[] = []


                Object.keys(point).map(key => {
                    const pointValueArray = point[key].split('##');
                    const value = pointValueArray[0];
                    const type = pointValueArray[1];
                    switch (type) {
                        case 'omitted_medicine':
                            omitted_medicines.push({ name: key });
                            break;
                        case 'medicine':
                            taken_medicines.push({ name: key });
                            break;
                        case 'comment':
                            comment = value;
                            break
                        case 'measurement':
                            if ((!!borders[key].max && +value > +borders[key].max) || (!!borders[key].min && +value < +borders[key].min)) {
                                alerts.push({ name: key, value: value })
                                break
                            }
                            measurements.push({ name: key, value: value })
                            break;
                        case 'omitted_measurement':
                            omitted_measurements.push({ name: key })
                            break;
                        default:
                            break;
                    }
                    return key;
                })
                return ({
                    measurements: measurements,
                    alerts: alerts,
                    taken_medicines: taken_medicines,
                    omitted_medicines: omitted_medicines,
                    omitted_measurements: omitted_measurements,
                    comment: comment,
                    date: point.date,
                })
            }
        )
        return finalTableData;
    }
)