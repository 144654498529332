import { DoctorsActionTypes, GET_USER_DOCTORS, GET_USER_PLACES, GET_SUGGESTION_OF_PLACES } from './types';
import { Doctor, Place, SuggestionOfPlace } from '../MyDoctorsDataTypes';

export interface DoctorsState {
    doctors: Array<Doctor> | undefined;
    places: Array<Place> | undefined;
    suggestionsOfPlaces: Array<SuggestionOfPlace> | undefined;
}

const initialState: DoctorsState = {
    doctors: undefined,
    places: undefined,
    suggestionsOfPlaces: undefined,
}

export function doctorsReducer(state = initialState, action: DoctorsActionTypes) {
    switch (action.type) {
        case GET_USER_DOCTORS: {
            return {
                ...state,
                doctors: action.payload,
            }
        }

        case GET_USER_PLACES: {
            return {
                ...state,
                places: action.payload,
            }
        }

        case GET_SUGGESTION_OF_PLACES: {
            return {
                ...state,
                suggestionsOfPlaces: action.payload,
            }
        }

        default:
            return state;
    }
}