import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { PatientDashboardState } from './reducer';
import {
    getNotificationDataSuccess,
    getNotificationDataFail,
    changeNotificationStatusSuccess,
    changeNotificationStatusFail,
    getMedicineToTakeDataSuccess,
    getMedicineToTakeDataFail,
    takeMedicinesSuccess,
    takeMedicinesFail,
    getMeasurementsToMakeDataSuccess,
    getMeasurementsToMakeDataFail,
    checkIsNewPatientSuccess,
    checkIsNewPatientFail
} from './actions';
import { AuthState } from 'Auth/store/reducer';
import loggedInInstance from 'axiosInstances/loggedIn.instance';
import {
    NotificationsRequestData,
    MedicinesToTakeRequestData,
    MeasurementsToMakeRequestData,
    CheckIsNewPatientRequestData
} from '../PatientDashboardTypes';
import { MedicineToTakeFormValues } from '../MedicinesToTakeAt/MedicineToTakeAtForm/MedicineToTakeAtForm';
import moment from 'moment';

export const thunkGetMedcines = (date: string): ThunkAction<Promise<boolean>, PatientDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<MedicinesToTakeRequestData> = await loggedInInstance.get(`medicine/medicines-to-take-at`, {
                params: {
                    date: date
                }
            });
            dispatch(getMedicineToTakeDataSuccess(data.medicines));
        } catch (error) {
            dispatch(getMedicineToTakeDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetNotifications = (start: string, end: string, limit: number): ThunkAction<Promise<boolean>, PatientDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<NotificationsRequestData> = await loggedInInstance.get(`notifications`, {
                params: {
                    start_date: start,
                    end_date: end,
                    limit: limit
                }
            });
            dispatch(getNotificationDataSuccess(data.notifications));
        } catch (error) {
            dispatch(getNotificationDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkChangeNotificationStatus = (notification: number, status: number): ThunkAction<Promise<boolean>, PatientDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.post(`notification/user/change-status`, {
                notification: notification,
                status: status
            });
            dispatch(changeNotificationStatusSuccess(true));
        } catch (error) {
            dispatch(changeNotificationStatusFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkTakeMedicines = (medicines: MedicineToTakeFormValues[]): ThunkAction<Promise<boolean>, PatientDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            let medicinesToSend: any[] = [];
            medicines.map((med: MedicineToTakeFormValues) => (
                medicinesToSend.push({
                    id: med.id,
                    amount: med.amount,
                    date: moment(med.date).format('YYYY-MM-DD HH:mm:ss')
                })
            ));
            await loggedInInstance.post(`medicine/take-many`, {
                medicines: medicinesToSend,
            });
            dispatch(takeMedicinesSuccess(true));
        } catch (error) {
            dispatch(takeMedicinesFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetMeasurements = (date: string): ThunkAction<Promise<boolean>, PatientDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<MeasurementsToMakeRequestData> = await loggedInInstance.get(`measurement/measurements-to-make-at`, {
                params: {
                    date: date
                }
            });
            dispatch(getMeasurementsToMakeDataSuccess(data.parameters));
        } catch (error) {
            dispatch(getMeasurementsToMakeDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkCheckIsNewPatient = (): ThunkAction<Promise<boolean>, PatientDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<CheckIsNewPatientRequestData> = await loggedInInstance.get(`profile/check-is-new-patient`);

            dispatch(checkIsNewPatientSuccess(data.patient));
        } catch (error) {
            dispatch(checkIsNewPatientFail(error.message));
        }
        return Promise.resolve(false)
    }