import * as yup from 'yup';

import { uniqBy, uniqWith } from 'utils/lodash';

yup.setLocale({
    array: {
        min: 'yupValidationDefaultMessages.array.min',
    },
    mixed: {
        required: 'yupValidationDefaultMessages.mixed.required',
        oneOf: 'yupValidationDefaultMessages.mixed.oneOf',
    },
    string: {
        email: 'yupValidationDefaultMessages.string.email',
        min: 'yupValidationDefaultMessages.string.min',
        max: 'yupValidationDefaultMessages.string.max',
    },
});

yup.addMethod(yup.array, 'uniqueByProperty', function (message: string, propName: string) {
    return this.test('uniqueByProperty', message, function (list) {

        let hasDuplicates: boolean = false;
        // console.log('JKJK', list)

        const uniqueArray = uniqBy(list, propName);
        // console.log(uniqueArray)

        hasDuplicates = (list.length === uniqueArray.length) ? false : true;

        return !hasDuplicates
    });
});

yup.addMethod(yup.array, 'uniqueWithComparator', function (message: string, predicate: (arrVal: any, othVal: any) => boolean) {
    return this.test('uniqueWithComparator', message, function (list) {

        let hasDuplicates: boolean = false;

        const uniqueArray = uniqWith(list, predicate);

        hasDuplicates = (list.length === uniqueArray.length) ? false : true;

        return hasDuplicates ? false : true;
    });
})



export default yup;