import {
    MedicinesActionTypes,
    GET_ALL_MEDICINES,
    GET_TAKEN_MEDICINES,
    GET_OMITTED_MEDICINES,
    GET_FUTURE_MEDICINES,
    GET_MEDICINE_DETAILS,
    GET_SUGGESTIONS_OF_MEDICINES,
    GET_MEDICINE_FORMS,
    GET_MEDICINE_DOSAGES,
    GET_USERS_WITH_PERMISSION_TO_PARTICULAR_MEDICINE,
    GET_DOSING_INTERVAL_UNITS,
    GET_DOSING,
    SET_MEDICINE_TO_OPEN_IN_MODAL,
    GET_ALL_DOCTOR_MEDICINES,
} from './types';
import {
    Medicine,
    MedicineTaken,
    MedicineOmitted,
    MedicineToTakeInFuture,
    MedicineDetails,
    MedicineListItem,
    MedicineForm,
    MedicineDosage,
    UserWithPermissionToParticularMedicine,
    DosingIntervalUnit,
    SimpleDosing,
    AdvancedDosing,
    DoctorMedicine,
} from '../medicines.types';
import { AppState } from '../../../';

export interface MedicinesState {
    allMedicines: Array<Medicine> | undefined;
    takenMedicines: Array<MedicineTaken> | undefined;
    omittedMedicines: Array<MedicineOmitted> | undefined;
    futureMedicines: Array<MedicineToTakeInFuture> | undefined;
    medicineDetails: MedicineDetails | undefined | null;
    suggestionOfMedicines: Array<MedicineListItem> | undefined;
    medicineForms: Array<MedicineForm> | undefined | null;
    medicineDosages: Array<MedicineDosage> | undefined | null;
    usersWithPermissionToParticularMedicine: Array<UserWithPermissionToParticularMedicine> | undefined | null;
    dosingIntervalUnits: Array<DosingIntervalUnit> | undefined;
    dosing: SimpleDosing | AdvancedDosing | undefined;
    medicineToOpenInModal: Medicine | DoctorMedicine | undefined;
    doctorMedicines: Array<DoctorMedicine> | undefined | null;
}

const initialState: MedicinesState = {
    allMedicines: undefined,
    takenMedicines: undefined,
    omittedMedicines: undefined,
    futureMedicines: undefined,
    medicineDetails: undefined,
    suggestionOfMedicines: undefined,
    medicineForms: undefined,
    medicineDosages: undefined,
    usersWithPermissionToParticularMedicine: undefined,
    dosingIntervalUnits: undefined,
    dosing: undefined,
    medicineToOpenInModal: undefined,
    doctorMedicines: undefined,
}

export function medicinesReducer(state = initialState, action: MedicinesActionTypes) {
    switch (action.type) {
        case GET_ALL_MEDICINES: {
            return {
                ...state,
                allMedicines: action.payload,
            }
        }
        case GET_TAKEN_MEDICINES: {
            return {
                ...state,
                takenMedicines: action.payload,
            }
        }
        case GET_OMITTED_MEDICINES: {
            return {
                ...state,
                omittedMedicines: action.payload,
            }
        }
        case GET_FUTURE_MEDICINES: {
            return {
                ...state,
                futureMedicines: action.payload,
            }
        }
        case GET_MEDICINE_DETAILS: {
            return {
                ...state,
                medicineDetails: action.payload,
            }
        }
        case GET_SUGGESTIONS_OF_MEDICINES: {
            return {
                ...state,
                suggestionOfMedicines: action.payload,
            }
        }
        case GET_MEDICINE_FORMS: {
            return {
                ...state,
                medicineForms: action.payload,
            }
        }
        case GET_MEDICINE_DOSAGES: {
            return {
                ...state,
                medicineDosages: action.payload,
            }
        }
        case GET_USERS_WITH_PERMISSION_TO_PARTICULAR_MEDICINE: {
            return {
                ...state,
                usersWithPermissionToParticularMedicine: action.payload,
            }
        }
        case GET_DOSING_INTERVAL_UNITS: {
            return {
                ...state,
                dosingIntervalUnits: action.payload,
            }
        }
        case GET_DOSING: {
            return {
                ...state,
                dosing: action.payload,
            }
        }
        case SET_MEDICINE_TO_OPEN_IN_MODAL: {
            return {
                ...state,
                medicineToOpenInModal: action.payload,
            }
        }
        case GET_ALL_DOCTOR_MEDICINES: {
            return {
                ...state,
                doctorMedicines: action.payload,
            }
        }

        default:
            return state;
    }
}

const getMedicinesState = (appState: AppState): MedicinesState => {
    return appState && appState.medicines;
}

export const getAllMedicinesFromRedux = (state: AppState): Array<Medicine> | undefined => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.allMedicines;
}

export const getTakenMedicinesFromRedux = (state: AppState): Array<MedicineTaken> | undefined => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.takenMedicines;
}

export const getOmittedMedicinesFromRedux = (state: AppState): Array<MedicineOmitted> | undefined => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.omittedMedicines;
}

export const getFutureMedicinesFromRedux = (state: AppState): Array<MedicineToTakeInFuture> | undefined => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.futureMedicines;
}

export const getAllMedicinesAmountFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    const allMedicines = medicinesState.allMedicines;
    return allMedicines ? allMedicines.length : 0;
}

export const getTakenMedicinesAmountFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    const takenMedicines = medicinesState.takenMedicines;
    return takenMedicines ? takenMedicines.length : 0;
}

export const getOmittedMedicinesAmountFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    const omittedMedicines = medicinesState.omittedMedicines;
    return omittedMedicines ? omittedMedicines.length : 0;
}

export const getFutureMedicinesAmountFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    const futureMedicines = medicinesState.futureMedicines;
    return futureMedicines ? futureMedicines.length : 0;
}

export const getMedicineDetailsFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.medicineDetails;
}

export const getSuggestionOfMedicinesFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.suggestionOfMedicines;
}

export const getMedicineDosagesFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.medicineDosages;
}

export const getMedicineFormsFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.medicineForms;
}

export const getUsersWithPermissionToParticularMedicineFromRedux = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.usersWithPermissionToParticularMedicine;
}

export const getDosingIntervalUnits = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.dosingIntervalUnits;
}

export const getDosing = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.dosing;
}

export const getMedicineToOpenInModal = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.medicineToOpenInModal;
}

export const getMedicineIdOfMedicineToOpenInModal = (state: AppState) => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.medicineToOpenInModal && medicinesState.medicineToOpenInModal.id;
}

export const getAllDoctorMedicinesFromRedux = (state: AppState): Array<DoctorMedicine> | undefined | null => {
    const medicinesState = getMedicinesState(state);

    return medicinesState.doctorMedicines;
}