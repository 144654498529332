import {
    AccountActionTypes,
    GET_ACCOUNT_DATA,
    GET_ACCOUNT_DATA_FAIL,
    GET_ACCOUNT_DATA_SUCCESS,
    UPDATE_ACCOUNT_DATA,
    UPDATE_ACCOUNT_DATA_FAIL,
    UPDATE_ACCOUNT_DATA_PARTIAL_FAIL,
    UPDATE_ACCOUNT_DATA_SUCCESS,
    UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS,
    UPDATE_LANGUAGE,
    UPDATE_LANGUAGE_SUCCESS,
    UPDATE_LANGUAGE_FAIL,
    UPDATE_ACCOUNT_DATA_PARTIAL,
    SET_LOCATION_FIELD,
    SET_LOCATION_FIELD_SUCCESS,
    SET_LOCATION_FIELD_FAIL,
    GET_ADDRESS_FROM_MAP,
    GET_ADDRESS_FROM_MAP_SUCCESS,
    GET_ADDRESS_FROM_MAP_FAIL,
    GET_COUNTRYID_SUCCESS,
    CREATE_LOCATION_FIELD,
    CREATE_LOCATION_FIELD_SUCCESS,
    CREATE_LOCATION_FIELD_FAIL,
    UPLOAD_AVATAR,
    UPLOAD_AVATAR_FAIL,
    GET_CAREGIVER_CHILDREN_DATA,
    GET_CAREGIVER_CHILDREN_DATA_FAIL,
    GET_CAREGIVER_CHILDREN_DATA_SUCCESS,
    GET_CAREGIVER_PARENTS_DATA,
    GET_CAREGIVER_PARENTS_DATA_FAIL,
    GET_CAREGIVER_PARENTS_DATA_SUCCESS,
    ADD_DIESEASE_MEDICINE_DEVICE,
    ADD_DIESEASE_MEDICINE_DEVICE_FAIL,
    ADD_DIESEASE_MEDICINE_DEVICE_SUCCESS,
} from "./types";
import { AC_User, UserDataStateTypes, AC_PlaceLocation } from '../account.types';
import { CaregiverObject } from '../CaregiversCard/CaregiversTypes';
import { AppState } from 'index';

export interface AccountState {
    requestedAccountData: boolean;
    recivedAccountData: boolean;
    accountDataUpdated: boolean;
    accountData: AC_User | undefined;
    acErrorMsg: string
    updateRequested: boolean;
    updateSuccess: boolean;
    requestedUpdateLanguage: boolean;
    recivedupdateLanguage: boolean;
    accountLanguageId: number;
    addressFromMap: string;
    addressRequested: boolean;
    addressRecived: boolean;
    addressCountryId: number;
    requestedCaregiverChildren: boolean,
    receivedCaregiverChildren: boolean,
    caregiverChildren: CaregiverObject[] | undefined;
    requestedCaregiverParents: boolean,
    receivedCaregiverParents: boolean,
    caregiverParents: CaregiverObject[] | undefined;
    // requestedAddDieseaseMedicineDevice: boolean;
    // recivedAddDieseaseMedicineDevice: boolean;
}

const initialState: AccountState = {
    requestedAccountData: false,
    recivedAccountData: false,
    accountDataUpdated: false,
    accountData: undefined,
    acErrorMsg: '',
    updateRequested: false,
    updateSuccess: false,
    requestedUpdateLanguage: false,
    recivedupdateLanguage: false,
    accountLanguageId: -1,
    addressFromMap: '',
    addressRequested: false,
    addressRecived: false,
    addressCountryId: 1,
    requestedCaregiverChildren: false,
    receivedCaregiverChildren: false,
    caregiverChildren: [],
    requestedCaregiverParents: false,
    receivedCaregiverParents: false,
    caregiverParents: [],
    // requestedAddDieseaseMedicineDevice: false,
    // recivedAddDieseaseMedicineDevice: false
}

function accountReducer(state = initialState, action: AccountActionTypes) {
    switch (action.type) {
        case GET_ACCOUNT_DATA:
            return {
                ...state, requestedAccountData: true, recivedAccountData: false, acErrorMsg: ''
            };
        case GET_ACCOUNT_DATA_FAIL:
            return {
                ...state, requestedAccountData: false, recivedAccountData: false, acErrorMsg: action.payload
            };
        case GET_ACCOUNT_DATA_SUCCESS:
            return {
                ...state, requestedAccountData: false, recivedAccountData: true, accountData: action.payload, accountLanguageId: action.langId
            };

        case UPLOAD_AVATAR:
        case CREATE_LOCATION_FIELD:
        case SET_LOCATION_FIELD:
        case UPDATE_ACCOUNT_DATA_PARTIAL:
        case UPDATE_ACCOUNT_DATA:

            return {
                ...state, updateRequested: true, updateSuccess: false, acErrorMsg: '',
            };
        case UPLOAD_AVATAR_FAIL:
        case CREATE_LOCATION_FIELD_FAIL:
        case SET_LOCATION_FIELD_FAIL:
        case UPDATE_ACCOUNT_DATA_FAIL:
        case UPDATE_ACCOUNT_DATA_PARTIAL_FAIL:
            return {
                ...state, updateRequested: false, updateSuccess: false, acErrorMsg: action.payload
            };

        case CREATE_LOCATION_FIELD_SUCCESS:
        case SET_LOCATION_FIELD_SUCCESS:
        case UPDATE_ACCOUNT_DATA_SUCCESS:
        case UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS:
            const changedData = action.payload as UserDataStateTypes;
            const changedDataKey = changedData.key;
            let currentACState = state.accountData;

            if (currentACState && currentACState[changedDataKey]) {
                let selectedPartofACData = changedDataKey && currentACState[changedDataKey];
                selectedPartofACData = changedData;
                (currentACState as AC_User) = { ...currentACState, [changedDataKey]: selectedPartofACData };
            }
            else {
                const fullLoad = action.payload as AC_User
                currentACState = fullLoad
            }

            return {
                ...state,
                updateRequested: false,
                updateSuccess: true,
                accountData: currentACState
            };
        case UPDATE_LANGUAGE:
            return {
                ...state, requestedUpdateLanguage: true, recivedupdateLanguage: false, acErrorMsg: ''
            };
        case UPDATE_LANGUAGE_SUCCESS:
            return {
                ...state, requestedUpdateLanguage: false, recivedupdateLanguage: true, acErrorMsg: '', accountLanguageId: action.payload
            };

        case UPDATE_LANGUAGE_FAIL:
            return {
                ...state, requestedUpdateLanguage: false, recivedupdateLanguage: false, acErrorMsg: action.payload
            };

        case GET_ADDRESS_FROM_MAP_SUCCESS:
            return {
                ...state, addressFromMap: action.payload, addressRecived: true, addressRequested: false,
            };

        case GET_ADDRESS_FROM_MAP:
            return {
                ...state, addressRecived: false, addressRequested: true, acErrorMsg: ''
            };

        case GET_ADDRESS_FROM_MAP_FAIL:
            return {
                ...state, addressRecived: false, addressRequested: true, acErrorMsg: action.payload
            };

        case GET_COUNTRYID_SUCCESS:
            return {
                ...state, addressCountryId: action.payload
            };

        case GET_CAREGIVER_CHILDREN_DATA:
            return {
                ...state,
                requestedCaregiverChildren: true,
                receivedCaregiverChildren: false,
                caregiverErrorMsg: ''
            };

        case GET_CAREGIVER_CHILDREN_DATA_FAIL:
            return {
                ...state,
                requestedCaregiverChildren: false,
                receivedCaregiverChildren: false,
                caregiverErrorMsg: action.payload
            };

        case GET_CAREGIVER_CHILDREN_DATA_SUCCESS:
            return {
                ...state,
                requestedCaregiverChildren: false,
                receivedCaregiverChildren: true,
                caregiverChildren: action.payload
            };

        case GET_CAREGIVER_PARENTS_DATA:
            return {
                ...state,
                requestedCaregiverParents: true,
                receivedCaregiverParents: false,
                caregiverErrorMsg: ''
            };

        case GET_CAREGIVER_PARENTS_DATA_FAIL:
            return {
                ...state,
                requestedCaregiverParents: false,
                receivedCaregiverParents: false,
                caregiverErrorMsg: action.payload
            };

        case GET_CAREGIVER_PARENTS_DATA_SUCCESS:
            return {
                ...state,
                requestedCaregiverParents: false,
                receivedCaregiverParents: true,
                caregiverParents: action.payload
            }
        case ADD_DIESEASE_MEDICINE_DEVICE:
            return {
                ...state, requestedAddDieseaseMedicineDevice: true, recivedAddDieseaseMedicineDevice: false, acErrorMsg: ''
            };
        case ADD_DIESEASE_MEDICINE_DEVICE_FAIL:
            return {
                ...state, requestedAddDieseaseMedicineDevice: false, recivedAddDieseaseMedicineDevice: true, acErrorMsg: action.payload
            };
        case ADD_DIESEASE_MEDICINE_DEVICE_SUCCESS:
            return {
                ...state, requestedAddDieseaseMedicineDevice: false, recivedAddDieseaseMedicineDevice: action.payload
            };

        //@TODO -
        // case CREATE_LOCATION_FIELD_SUCCESS:
        //     console.log(action.payload);
        //     return {
        //         ...state,
        //         updateRequested: false,
        //         updateSuccess: true,
        //     }

        default:
            return state;
    }
}

export const getAccoutData = (state: AccountState): AC_User | undefined => state.accountData;

export default accountReducer;

const getAccountState = (appState: AppState): AccountState => {
    return appState && appState.account;
}

export const getCaregiverChildren = (state: AppState) => {
    const accountState = getAccountState(state);

    return accountState.caregiverChildren;
}

export const getAccountDataByAppState = (state: AppState) => {
    const appState = getAccountState(state);

    return appState.accountData;
}

export const getUserData = (state: AppState) => {
    const accountData = getAccountDataByAppState(state);

    return accountData ? accountData.user : accountData;
}

export const getDoctoHospitalAddress = (state: AppState): AC_PlaceLocation | undefined => {
    const accountData = getAccountDataByAppState(state);
    return accountData ? accountData.place_health : undefined
}