import {
    MeasurementsActionTypes,
    GET_PARAMETERS_TABLE_DATA_SUCCESS,
    GET_MEASUREMENTS_TABLE_DATA_SUCCESS,
    GET_PARAMETER_PROPOSITIONS_SUCCESS,
    GET_UNITS_PROPOSITIONS_SUCCESS,
    GET_INTERVAL_UNITS_SUCCESS,
    GET_MEASUREMENT_CALCULATION_TYPES_SUCCESS,
    GET_MEASUREMENT_PARAMETER_FIELD_TYPES_SUCCESS,
    GET_MEASUREMENT_PARAMETER_FIELDS_PROPOSITIONS_SUCCESS,
    GET_MEASUREMENT_ADD_PARAMETERS_LIST_SUCCESS,
    GET_CAREGIVER_CHILDREN_SUCCESS,
    GET_PARAMETER_FIELDS_FOR_MEASUREMENT_SUCCESS,
    GET_PARAMETER_USERS_FOR_MEASUREMENT_SUCCESS,
    SET_SELECTED_MEASUREMENT,
    GET_LAST_TEN_MEASUREMENTS,
    GET_AVIABLE_COLORS_SUCCESS,
    GET_GRAPHS_DATA_SUCCESS,
    GET_PDF_MAIN_PERSONAL_DATA_SUCCESS,
    DETAILS_VIEW_SWITCH,
    GET_GRAPH_DETAILS_SUCCESS,
    SET_GRAPH_DETAILS_PARAMETER_ID,
    GET_PDF_USER_PARAMETER_DATA_SUCCESS
} from "./types";
import { NormalizedParameters, normalizedMeasurements, MeasurementField, NormalizedParametersEntities, Field, User, IdNameType, ParameterFieldProposition, normalizedParameterPropositions, AddMeasurement, AddParameterObject, GraphsSection, PdfGenerateSection, GraphDetails } from "../measurements.types";

import { NormalizedSchema } from "normalizr";

export interface MeasurementsState {
    parametersData: NormalizedSchema<NormalizedParametersEntities, string[]>;
    graphsData: any;
    parameterPropositions: normalizedParameterPropositions.ParameterPropositionsNormalizedData;
    unitsDictionary: IdNameType[];
    unitsIntervalDictionary: IdNameType[];
    measuremetCalculationTypesDictionary: IdNameType[];
    fieldUnitsTypesDictionary: IdNameType[];
    parameterFieldsPropositions: { [id: string]: ParameterFieldProposition }
    measurementTableData: NormalizedSchema<normalizedMeasurements.MeasurementEntities, string[]>
    addMeasurementParametersList: any;
    aviableUsers: AddParameterObject.AddParamAviableUsers[];
    listOfAddMeasurementModalFields: AddMeasurement.MeasurementParameterField[];
    listOfAddMeasurementModalUsers: IdNameType[];
    listOfAddMeasurementModalLastValues: { [fieldId: string]: { [userId: string]: number | string | '' } }
    selectedAddMeasurementParameter: IdNameType;
    recentMeasurements: AddMeasurement.RecentMeasurement;
    colors: string[];
    allGraphsData: GraphsSection.Parameter[];
    pdf: PdfGenerateSection.PdfFields;
    graphDetailsSelectedParameterId: string;
    graphDetailsData: GraphDetails.GraphDetailsData;
    inDetailsView: boolean
}

const initialState: MeasurementsState = {
    parametersData: { entities: { fields: {}, schedules: {}, units: {}, users: {}, types: {}, parameters: {}, borders: {} }, result: [] },
    graphsData: {},
    parameterPropositions: { entities: { fields: {}, units: {}, type: {}, parameters: {} }, result: [] },
    unitsDictionary: [],
    unitsIntervalDictionary: [],
    measuremetCalculationTypesDictionary: [],
    fieldUnitsTypesDictionary: [],
    parameterFieldsPropositions: {},
    measurementTableData: { entities: { fields: {}, measurements: {} }, result: [] },
    addMeasurementParametersList: {},
    aviableUsers: [],
    listOfAddMeasurementModalFields: [],
    listOfAddMeasurementModalUsers: [],
    listOfAddMeasurementModalLastValues: {},
    selectedAddMeasurementParameter: { id: '', name: '' },
    recentMeasurements: { unit: '', name: '', type: '', measurements: [] },
    colors: [],
    allGraphsData: [],
    pdf: {} as PdfGenerateSection.PdfFields,
    graphDetailsData: {
        parameter: { id: '', name: '', type: { id: '', name: '' }, unit: '' },
        chart_points: [],
        omitted_measurements: [],
        omitted_medicines: [],
        chart_lines: [],
        medicines: [],
    },
    graphDetailsSelectedParameterId: '',
    inDetailsView: false,
}

export function measurementsReducer(state = initialState, action: MeasurementsActionTypes) {
    switch (action.type) {

        case GET_PARAMETERS_TABLE_DATA_SUCCESS:
            return {
                ...state, parametersData: action.payload
            };
        case GET_UNITS_PROPOSITIONS_SUCCESS:
            const unitsDictionary = action.payload
            return {
                ...state, unitsDictionary
            };

        case GET_INTERVAL_UNITS_SUCCESS:
            const unitsIntervalDictionary = action.payload
            return {
                ...state, unitsIntervalDictionary
            };

        case GET_MEASUREMENT_CALCULATION_TYPES_SUCCESS:
            const measuremetCalculationTypesDictionary = action.payload
            return {
                ...state, measuremetCalculationTypesDictionary
            };

        case GET_PARAMETER_PROPOSITIONS_SUCCESS:
            let { parameterPropositions } = state;
            parameterPropositions = { ...action.payload }
            return {
                ...state, requestSent: false, answerRecived: true, parameterPropositions
            };
        case GET_MEASUREMENT_PARAMETER_FIELD_TYPES_SUCCESS:
            const fieldUnitsTypesDictionary = action.payload;
            return {
                ...state, fieldUnitsTypesDictionary
            }
        case GET_MEASUREMENT_PARAMETER_FIELDS_PROPOSITIONS_SUCCESS:
            const parameterFieldsPropositions = action.payload
            return {
                ...state, parameterFieldsPropositions
            }

        case GET_MEASUREMENTS_TABLE_DATA_SUCCESS:
            let { measurementTableData } = state
            measurementTableData = { ...action.payload };
            return {
                ...state, measurementTableData
            }
        case GET_MEASUREMENT_ADD_PARAMETERS_LIST_SUCCESS:
            const addMeasurementParametersList = action.payload

            return {
                ...state, addMeasurementParametersList
            }

        case GET_CAREGIVER_CHILDREN_SUCCESS:
            const aviableUsers = action.payload
            return {
                ...state, aviableUsers
            }

        case GET_PARAMETER_FIELDS_FOR_MEASUREMENT_SUCCESS:
            const listOfAddMeasurementModalFields: AddMeasurement.MeasurementParameterField[] = action.payload;

            let listOfAddMeasurementModalLastValues: {
                [key: string]: {
                    [id: string]: number | string | ''
                }
            } = {}
            listOfAddMeasurementModalFields.map(field => {
                let listItem = { ...listOfAddMeasurementModalLastValues, [field.id]: {} }
                field.last_value.map(lastVal => listItem[field.id] = {
                    ...listItem[field.id],
                    [lastVal.user_id]: lastVal.value
                })
                return listOfAddMeasurementModalLastValues = listItem;
            })

            return {
                ...state, listOfAddMeasurementModalFields, listOfAddMeasurementModalLastValues
            }

        case GET_PARAMETER_USERS_FOR_MEASUREMENT_SUCCESS:
            const listOfAddMeasurementModalUsers = action.payload;
            return {
                ...state, listOfAddMeasurementModalUsers
            }

        case SET_SELECTED_MEASUREMENT:
            const selectedAddMeasurementParameter = action.payload;
            return {
                ...state, selectedAddMeasurementParameter
            }

        case GET_LAST_TEN_MEASUREMENTS:
            const recentMeasurements = action.payload;
            return {
                ...state, recentMeasurements
            }

        case GET_AVIABLE_COLORS_SUCCESS:
            const colors = action.payload;
            return {
                ...state, colors
            }

        case GET_GRAPHS_DATA_SUCCESS:
            const allGraphsData = action.payload;
            return {
                ...state, allGraphsData
            }
        case GET_PDF_MAIN_PERSONAL_DATA_SUCCESS:
            state.pdf.main_personal_data = action.payload
            const stateUpdated = state.pdf.main_personal_data

            return {
                ...state, stateUpdated
            }

        case GET_PDF_USER_PARAMETER_DATA_SUCCESS:
            state.pdf.user_parameters = action.payload
            const stateUPUpdated = state.pdf.user_parameters

            return {
                ...state, stateUPUpdated
            }

        case DETAILS_VIEW_SWITCH:
            const inDetailsView = action.payload;
            return {
                ...state, inDetailsView
            }

        case GET_GRAPH_DETAILS_SUCCESS:
            const graphDetailsData = action.payload;
            return {
                ...state, graphDetailsData
            };

        case SET_GRAPH_DETAILS_PARAMETER_ID:
            const graphDetailsSelectedParameterId = action.payload;
            return {
                ...state, graphDetailsSelectedParameterId
            };

        default:
            return state;
    }
}

export const getParametersPropositions = (state: MeasurementsState): normalizedParameterPropositions.ParameterPropositionsNormalizedData => state.parameterPropositions;
export const getParametersPropositionsEntities = (state: MeasurementsState): normalizedParameterPropositions.ParameterPropositionsEntities => state.parameterPropositions.entities
export const getParametersPropositionsResults = (state: MeasurementsState): string[] => state.parameterPropositions.result;
export const getParametersPropositionsFields = (state: MeasurementsState): normalizedParameterPropositions.ParameterFields => state.parameterPropositions.entities.fields;
export const getParameterPropositionsUnits = (state: MeasurementsState): normalizedParameterPropositions.ParameterUnits => state.parameterPropositions.entities.units;
export const getParameterPropositionsParameters = (state: MeasurementsState): normalizedParameterPropositions.ParameterParameters => state.parameterPropositions.entities.parameters;
export const getParameterPropositionsType = (state: MeasurementsState): normalizedParameterPropositions.ParameterType => state.parameterPropositions.entities.type;

export const getParametersData = (state: MeasurementsState): NormalizedParameters => state.parametersData;
export const getParametersEntities = (state: MeasurementsState): NormalizedParametersEntities => state.parametersData.entities;
export const getParametersIds = (state: MeasurementsState): string[] => state.parametersData.result;
export const getParametersFields = (state: MeasurementsState): { [id: string]: Field } => state.parametersData.entities.fields;
export const getParametersUnits = (state: MeasurementsState): { [id: string]: IdNameType } => state.parametersData.entities.units;
export const getParametersUsers = (state: MeasurementsState): { [id: string]: User } => state.parametersData.entities.users;
export const getParameterFieldTypesDictionary = (state: MeasurementsState) => state.fieldUnitsTypesDictionary;

export const getParameterFieldsPropositions = (state: MeasurementsState): { [name: string]: ParameterFieldProposition } => state.parameterFieldsPropositions;

export const getMeasurementTableDataEntities = (state: MeasurementsState): normalizedMeasurements.MeasurementEntities => state.measurementTableData.entities;
export const getMeasurementTableDataResults = (state: MeasurementsState): string[] => state.measurementTableData.result;
export const getMeasurementTableData = (state: MeasurementsState): { [key: string]: normalizedMeasurements.NormalizedMeasurement } => state.measurementTableData.entities.measurements;
export const getMeasurementTableDataFields = (state: MeasurementsState): { [key: string]: MeasurementField } => state.measurementTableData.entities.fields;
// export const getParametersFieldsIds = (state: MeasurementsState): string[]  => state.parametersData.entities.parameters.fields;
export const getAddMeasurementParametersObject = (state: MeasurementsState): { [key: string]: AddMeasurement.Parameter } => state.addMeasurementParametersList;
export const getTypeOfSelectedParameter = (state: MeasurementsState): IdNameType => (
    !!state.selectedAddMeasurementParameter && !!state.addMeasurementParametersList[state.selectedAddMeasurementParameter.name]
) ?
    state.addMeasurementParametersList[state.selectedAddMeasurementParameter.name].type :
    '';
export const getAviableUsers = (state: MeasurementsState): AddParameterObject.AddParamAviableUsers[] => state.aviableUsers;
export const getAviableBorders = (state: MeasurementsState): { [key: string]: normalizedParameterPropositions.FieldBorders } => state.parametersData.entities.borders;

export const getAllGraphsData = (state: MeasurementsState): GraphsSection.Parameter[] => state.allGraphsData;

export const getGraphDetailsData = (state: MeasurementsState): GraphDetails.GraphDetailsData => state.graphDetailsData;