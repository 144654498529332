
import { schema } from 'normalizr';

const field = new schema.Entity('fields', {}, { idAttribute: (value) => value.name.toLowerCase() });
const type = new schema.Entity('type', {}, { idAttribute: (value) => value.name.toLowerCase() });
const unit = new schema.Entity('units', {}, { idAttribute: (value) => value.name.toLowerCase() });

const parameter = new schema.Entity('parameters', {
    fields: [field],
    type: type,
    unit: unit,
}, { idAttribute: (value) => value.name.toLowerCase() })

const parameterPropositionsSchema = [parameter];

export default parameterPropositionsSchema;
