export const GET_NOTIFICATIONS_DATA = '[Notifications] Get Notifications Data';
export const GET_NOTIFICATIONS_DATA_FAIL = '[Notifications] Get Notifications Data Fail';
export const GET_NOTIFICATIONS_DATA_SUCCESS = '[Notifications] Get Notifications Data Success';
export const CHANGE_NOTIFICATION_STATUS_FAIL = '[Notifications] Change Notification status Fail';
export const CHANGE_NOTIFICATION_STATUS_SUCCESS = '[Notifications] Change Notification status Success';
export const GET_MEDICINES_TO_TAKE_DATA = '[Medicines to take at] Get Medicines Data';
export const GET_MEDICINES_TO_TAKE_DATA_FAIL = '[Medicines to take at] Get Medicines Data Fail';
export const GET_MEDICINES_TO_TAKE_DATA_SUCCESS = '[Medicines to take at] Get Medicines Data Success';
export const TAKE_MEDICINES_FAIL = '[Medicines to take at] Take Medicines Fail';
export const TAKE_MEDICINES_SUCCESS = '[Medicines to take at] Take Medicines Success';
export const GET_MEASUREMENTS_TO_MAKE_DATA = '[Measurements to make] Measuremenst to make';
export const GET_MEASUREMENTS_TO_MAKE_DATA_FAIL = '[Measurements to make] Measuremenst to make Fail';
export const GET_MEASUREMENTS_TO_MAKE_DATA_SUCCESS = '[Measurements to make] Measuremenst to make Success';
export const CHECK_IS_NEW_PATIENT = '[Check is new pateint] Check is new patient';
export const CHECK_IS_NEW_PATIENT_SUCCESS = '[Check is new pateint] Check is new patient Success';
export const CHECK_IS_NEW_PATIENT_FAIL = '[Check is new pateint] Check is new patient Fail';
interface GetNotificationsData {
    type: typeof GET_NOTIFICATIONS_DATA;
}

interface GetNotificationsDataFail {
    type: typeof GET_NOTIFICATIONS_DATA_FAIL;
    payload: string;
}

interface GetNotificationsDataSuccess {
    type: typeof GET_NOTIFICATIONS_DATA_SUCCESS;
    payload: any;
}

interface ChangeNotificationStatusFail {
    type: typeof CHANGE_NOTIFICATION_STATUS_FAIL;
    payload: string;
}

interface ChangeNotificationStatusSuccess {
    type: typeof CHANGE_NOTIFICATION_STATUS_SUCCESS;
    payload: any;
}

interface GetMedicineToTakeData {
    type: typeof GET_MEDICINES_TO_TAKE_DATA;
}

interface GetMedicineToTakeDataFail {
    type: typeof GET_MEDICINES_TO_TAKE_DATA_FAIL;
    payload: string;
}

interface GetMedicineToTakeDataSuccess {
    type: typeof GET_MEDICINES_TO_TAKE_DATA_SUCCESS;
    payload: any;
}

interface TakeMedicinesFail {
    type: typeof TAKE_MEDICINES_FAIL;
    payload: string;
}

interface TakeMedicinesSuccess {
    type: typeof TAKE_MEDICINES_SUCCESS;
    payload: any;
}

interface GetMeasurementsToMakeData {
    type: typeof GET_MEASUREMENTS_TO_MAKE_DATA;
}

interface GetMeasurementsToMakeDataFail {
    type: typeof GET_MEASUREMENTS_TO_MAKE_DATA_FAIL;
    payload: string;
}

interface GetMeasurementsToMakeDataSuccess {
    type: typeof GET_MEASUREMENTS_TO_MAKE_DATA_SUCCESS;
    payload: any;
}

interface CheckIsNewPatient {
    type: typeof CHECK_IS_NEW_PATIENT;
}

interface CheckIsNewPatientFail {
    type: typeof CHECK_IS_NEW_PATIENT_FAIL;
    payload: string;
}

interface CheckIsNewPatientSuccess {
    type: typeof CHECK_IS_NEW_PATIENT_SUCCESS;
    payload: any;
}

export type PatientDashboardActionTypes =
    GetNotificationsData |
    GetNotificationsDataFail |
    GetNotificationsDataSuccess |
    ChangeNotificationStatusFail |
    ChangeNotificationStatusSuccess |
    GetMedicineToTakeData |
    GetMedicineToTakeDataFail |
    GetMedicineToTakeDataSuccess |
    TakeMedicinesFail |
    TakeMedicinesSuccess |
    GetMeasurementsToMakeData |
    GetMeasurementsToMakeDataFail |
    GetMeasurementsToMakeDataSuccess |
    CheckIsNewPatient |
    CheckIsNewPatientFail |
    CheckIsNewPatientSuccess
;
