export const GET_CALENDAR_DATA = '[Calendar] Get Calendar Data';
export const GET_CALENDAR_DATA_FAIL = '[Calendar] Get Calendar Data Fail';
export const GET_CALENDAR_DATA_SUCCESS = '[Calendar] Get Calendar Data Success';
export const EXPORT_CALENDAR_SUCCESS = '[Calendar] Export Calendar Success';
export const EXPORT_CALENDAR_FAIL = '[Calendar] Export Calendar Fail';
export const ADD_CALENDAR_EVENT_SUCCESS = '[Calendar] Add Calendar Event Success';
export const ADD_CALENDAR_EVENT_FAIL = '[Calendar] Add Calendar Event Fail';

interface GetCalendarData {
    type: typeof GET_CALENDAR_DATA;
}

interface GetCalendarDataFail {
    type: typeof GET_CALENDAR_DATA_FAIL;
    payload: string;
}

interface GetCalendarDataSuccess {
    type: typeof GET_CALENDAR_DATA_SUCCESS;
    payload: any;
}

interface ExportCalendarFail {
    type: typeof EXPORT_CALENDAR_FAIL;
    payload: string;
}

interface ExportCalendarSuccess {
    type: typeof EXPORT_CALENDAR_SUCCESS;
    payload: any;
}

interface AddCalendarEventSuccess {
    type: typeof ADD_CALENDAR_EVENT_SUCCESS;
    payload: string;
}

interface AddCalendarEventFail {
    type: typeof ADD_CALENDAR_EVENT_FAIL;
    payload: any;
}

export type CalendarActionTypes =
    GetCalendarData |
    GetCalendarDataFail |
    GetCalendarDataSuccess |
    ExportCalendarFail |
    ExportCalendarSuccess |
    AddCalendarEventSuccess |
    AddCalendarEventFail
;
