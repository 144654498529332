import { Action } from 'redux';
import { loginUserSuccess, confirmAccount, confirmAccountSuccess, confirmAccountFailed, logoutUser } from './actions';
import { UserLoginData } from './types';
import { ThunkAction } from 'redux-thunk';
import { AuthState } from './reducer';
import axios from 'axios';
import { Dispatch } from 'react';
import decryptToken, { expDateFromToken } from '../JwtDecrypt';
import loggedInInstance from '../../axiosInstances/loggedIn.instance';
import { ChangePasswordForm } from '../auth.types';
import { setError } from '../../axiosInstances/store/actions';

import { setInLocalStorage } from 'utils/LocalStorage';
import checkAuthState from '../../axiosInstances/checkAuthState';
import { I18n } from 'react-redux-i18n';

import { RemedizerApiResponse } from 'views/Measurements/measurements.types';

interface ErrorMessage {
    message: string;
    code: string | number;
}

interface DecryptedToken {
    iat: number;
    exp: number;
    user_id: number;
    username: string;
}

export const thunkLogInUser = (data: UserLoginData): ThunkAction<Promise<boolean>, AuthState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            const res = await axios.post('login', data)
            const token = res.data.token;
            const role = res.data.isDoctor;
            setInLocalStorage('token', token);
            const decryptedToken: DecryptedToken = decryptToken(token);
            const userId = decryptedToken.user_id;
            const expDate: Date = expDateFromToken(decryptedToken.exp);
            setInLocalStorage('expires', `${expDate}`);
            setInLocalStorage('role', `${role}`);
            setInLocalStorage('userId', `${userId}`)
            dispatch(loginUserSuccess(token, role, userId));
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    };


export const thunkResetPasswordRequest = (email: string): ThunkAction<Promise<string>, AuthState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<string> => {
        try {
            const res: RemedizerApiResponse & { data: { message: string } } = await axios.post('password/reset/request', { email: email })
            const message = res.data.message;
            return Promise.resolve(message)
        } catch (error) {
            return Promise.reject('')
        }
    };

export const thunkResetPasswordConfirm = (plainPassword: { first: string, second: string }, token: string): ThunkAction<Promise<boolean>, AuthState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            await axios.post('password/reset/confirm', {
                plainPassword: plainPassword
            }, {
                    params: {
                        token: token
                    }
                })
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    };

export const thunkRegisterUserinModal = (userData: { email: string, language_id: number }): ThunkAction<Promise<string>, AuthState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<string> => {
        try {
            const res: RemedizerApiResponse & { data: { message: string } } = await axios.post('register', userData)
            const message = res.data.message
            return Promise.resolve(message)
        } catch (error) {
            return Promise.reject('')
        }
    };

export const thunkConfirmAccount = (token: string, languageId: number): ThunkAction<void, AuthState, void, Action<any>> => (dispatch: Dispatch<Action>) => {
    dispatch(confirmAccount());
    axios.get('register/confirm', {
        params: {
            'token': token,
            'language_id': languageId
        }
    })
        .then(
            () => {
                return dispatch(confirmAccountSuccess())
            }
        )
        .catch((error: ErrorMessage) => {
            return dispatch(confirmAccountFailed(error.message))
        })
}

export const thunkChangePassword = (form: ChangePasswordForm): ThunkAction<Promise<boolean>, AuthState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.post('password/change', form)
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        };
    }

export const thunkCheckIfTokenIsValid = (code: string | number): ThunkAction<void, AuthState, void, Action<any>> => (dispatch: Dispatch<Action>) => {
    if (!checkAuthState() || code === ('401' || 401)) {
        let count: number = 3;
        dispatch(setError(I18n.t(`account.invalidToken`, { seconds: count }), +code));
        dispatch(logoutUser());
        // const timer = setInterval(function () {
        //     if (count <= 0) {
        //         clearInterval(timer);
        //         dispatch(logoutUser());
        //         dispatch(setError(I18n.t(`account.loginToContinue`)));
        //     } else {
        //         if (window.location.pathname !== '/') {
        //             dispatch(setError(I18n.t(`account.invalidToken`, { seconds: count }), +code));
        //         }
        //         count--;
        //     }
        // }, 1000);

        return null;
    }
}