import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { TemplatesState } from './reducer';
import {
    getTemplatesDataSuccess,
    getTemplatesDataFail,
    findParametersDataSuccess,
    findParametersDataFail,
    addTemplateSuccess,
    addTemplateFail
} from './actions';
import { AuthState } from 'Auth/store/reducer';
import loggedInInstance from 'axiosInstances/loggedIn.instance';

import { TemplatesRequestData, ParameterRequestData } from '../TemplatesTypes';
import { AddTemplateFormValues } from '../AddTemplateForm/AddTemplateForm';

export const thunkGetTemplates = (): ThunkAction<Promise<boolean>, TemplatesState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {

        try {
            const { data }: AxiosResponse<TemplatesRequestData> = await loggedInInstance.get(`template/list`);
            dispatch(getTemplatesDataSuccess(data.templates));
        } catch (error) {
            dispatch(getTemplatesDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetParameterPropositions = (filter: string): ThunkAction<Promise<boolean>, TemplatesState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<ParameterRequestData> = await loggedInInstance.get(`measurement/parameter/find`, {
                params: {
                    name: filter
                }
            });
            const { parameters } = data;
            dispatch(findParametersDataSuccess(parameters));
        } catch (error) {
            dispatch(findParametersDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkAddTemplate = (values: AddTemplateFormValues, templateId?: string): ThunkAction<Promise<boolean>, TemplatesState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            if (templateId) {
                await loggedInInstance.put(`template/${templateId}`, {
                    ...values
                });
            } else {
                await loggedInInstance.post(`template/create`, {
                    ...values
                });
            }

            dispatch(addTemplateSuccess(true));
        } catch (error) {
            dispatch(addTemplateFail(error.message));
        }
        return Promise.resolve(false)
    }