import { AuthActionTypes, LOGIN_SUCCESS, CONFIRM_ACCOUNT, CONFIRM_ACCOUNT_SUCCESS, CONFIRM_ACCOUNT_FAILED, LOGOUT, UPDATE_TOKEN } from './types';
import decryptToken, { expDateFromToken } from '../JwtDecrypt';
import { setInLocalStorage } from 'utils/LocalStorage';

interface DecryptedToken {
    iat: number;
    exp: number;
    user_id: number;
    username: string;
}

export interface AuthState {
    onRegistration: boolean;
    loggedIn: boolean;
    sentLoginRequest: boolean;
    recivedToken: boolean;
    error: string;
    modalError: string;
    registrationAttempt: boolean;
    registrationSuccess: boolean;
    getLaguagesAttempt: boolean;
    confirmAccountAttempt: boolean;
    confirmAccountSuccess: boolean;
    token: string;
    isDoctor: boolean;
    userId: number;
    changePasswordRequested: boolean;
    changePasswordSuccess: boolean;
}

const initialState: AuthState = {
    onRegistration: false,
    loggedIn: false,
    sentLoginRequest: false,
    recivedToken: false,
    error: '',
    modalError: '',
    registrationAttempt: false,
    registrationSuccess: false,
    getLaguagesAttempt: false,
    confirmAccountAttempt: false,
    confirmAccountSuccess: false,
    token: '',
    isDoctor: false,
    userId: NaN,
    changePasswordRequested: false,
    changePasswordSuccess: false
}

export function authReducer(state = initialState, action: AuthActionTypes) {
    switch (action.type) {

        case LOGIN_SUCCESS:
            return {
                ...state,
                recivedToken: true,
                sentLoginRequest: false,
                error: '',
                loggedIn: true,
                token: action.payload,
                modalError: '',
                isDoctor: action.role,
                userId: action.userId as number
            };
        case LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('expires');
            localStorage.removeItem('role');
            localStorage.removeItem('userId');
            localStorage.removeItem('languageName');
            return {
                ...state, token: '', loggedIn: false
            }

        case CONFIRM_ACCOUNT:
            return {
                ...state, confirmAccountAttempt: true, confirmAccountSuccess: false, error: '', modalError: ''
            };
        case CONFIRM_ACCOUNT_SUCCESS:
            return {
                ...state, confirmAccountAttempt: false, confirmAccountSuccess: true
            };
        case CONFIRM_ACCOUNT_FAILED:
            return {
                ...state, confirmAccountAttempt: false, confirmAccountSuccess: false, error: action.payload
            };


        case UPDATE_TOKEN:
            const token = action.payload;
            setInLocalStorage('token', token);
            const decryptedToken: DecryptedToken = decryptToken(token);
            const expDate: Date = expDateFromToken(decryptedToken.exp);
            setInLocalStorage('expires', `${expDate}`);
            return {
                ...state,
                token: token,
            };

        default:
            return state;
    }
}

export default authReducer;
