import {
    Medicine,
    MedicineTaken,
    MedicineOmitted,
    MedicineToTakeInFuture,
    MedicineDetails,
    MedicineListItem,
    MedicineForm,
    MedicineDosage,
    UserWithPermissionToParticularMedicine,
    DosingIntervalUnit,
    SimpleDosing,
    DoctorMedicine,
} from '../medicines.types';

export const GET_ALL_MEDICINES = '[Medicines] Get all medicines of a patient';
export const GET_TAKEN_MEDICINES = '[Medicines] Get taken medicines of a patient';
export const GET_OMITTED_MEDICINES = '[Medicines] Get omitted medicines of a patient';
export const GET_FUTURE_MEDICINES = '[Medicines] Get future medicines of a patient';
export const GET_MEDICINE_DETAILS = '[Medicines] Get a medicine details';
export const GET_SUGGESTIONS_OF_MEDICINES = '[Medicines] Get suggestion of medicines';
export const GET_MEDICINE_FORMS = '[Medicines] Get forms of a medicine';
export const GET_MEDICINE_DOSAGES = '[Medicines] Get dosages of a medicine';
export const GET_USERS_WITH_PERMISSION_TO_PARTICULAR_MEDICINE = '[Medicines] Get users with permission to particular medicine';
export const GET_DOSING_INTERVAL_UNITS = '[Medicines] Get dosing interval units';
export const GET_DOSING = '[Medicines] Get dosing';
export const SET_MEDICINE_TO_OPEN_IN_MODAL = '[Medicines] set a medicine to open in modal';
export const GET_ALL_DOCTOR_MEDICINES = '[Medicines doctor] Get all medicines of a patient';

interface GetMedicines {
    type: typeof GET_ALL_MEDICINES;
    payload: Array<Medicine>;
}

interface GetTakenMedicines {
    type: typeof GET_TAKEN_MEDICINES;
    payload: Array<MedicineTaken>;
}

interface GetOmittedMedicines {
    type: typeof GET_OMITTED_MEDICINES;
    payload: Array<MedicineOmitted>;
}

interface GetFutureMedicines {
    type: typeof GET_FUTURE_MEDICINES;
    payload: Array<MedicineToTakeInFuture>;
}

interface GetMedicineDetails {
    type: typeof GET_MEDICINE_DETAILS;
    payload: MedicineDetails;
}

interface GetSuggestionOfMedicines {
    type: typeof GET_SUGGESTIONS_OF_MEDICINES;
    payload: Array<MedicineListItem>;
}

interface GetMedicineForms {
    type: typeof GET_MEDICINE_FORMS;
    payload: Array<MedicineForm>;
}

interface GetMedicineDosages {
    type: typeof GET_MEDICINE_DOSAGES;
    payload: Array<MedicineDosage>;
}

interface GetUsersWithPermissionToParticularMedicine {
    type: typeof GET_USERS_WITH_PERMISSION_TO_PARTICULAR_MEDICINE;
    payload: Array<UserWithPermissionToParticularMedicine>;
}

interface GetDosingIntervalUnits {
    type: typeof GET_DOSING_INTERVAL_UNITS;
    payload: Array<DosingIntervalUnit>;
}

interface GetDosing {
    type: typeof GET_DOSING;
    payload: SimpleDosing;
}

interface SetMedicineToOpenInModal {
    type: typeof SET_MEDICINE_TO_OPEN_IN_MODAL;
    payload: Medicine | DoctorMedicine | undefined;
}

interface GetAllDoctorMedicines {
    type: typeof GET_ALL_DOCTOR_MEDICINES;
    payload: Array<DoctorMedicine>;
}

export type MedicinesActionTypes = GetMedicines |
    GetTakenMedicines |
    GetOmittedMedicines |
    GetFutureMedicines |
    GetMedicineDetails |
    GetSuggestionOfMedicines |
    GetMedicineForms |
    GetMedicineDosages |
    GetUsersWithPermissionToParticularMedicine |
    GetDosingIntervalUnits |
    GetDosing |
    SetMedicineToOpenInModal |
    GetAllDoctorMedicines
    ;