import * as React from 'react';
import logo from 'images/Logo-white.png';
import './Footer.css';
import moment from 'moment';

const footer = () => (
    <div className="footer w-100 h-auto p-0">
        <div className="container">
            <div className="row">
                <div className="col-12 footer-padding w-100 d-flex justify-content-between flex-column flex-md-row align-items-center">
                    <div className="navbar-brand">
                        <img src={logo} alt="Remedizer Logo" />
                    </div>
                    <span>Copyright Remedizer {moment().format('YYYY')}</span>
                </div>
            </div>
        </div>
    </div>
);

export default footer;
