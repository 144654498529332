import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import moment from 'moment';
import { CalendarState } from './reducer';
import {
    getCalendarDataSuccess,
    getCalendarDataFail,
    exportCalendarSuccess,
    exportCalendarFail,
    addCalendarEventSuccess,
    addCalendarEventFail
} from './actions';
import { AuthState } from 'Auth/store/reducer';
import loggedInInstance from 'axiosInstances/loggedIn.instance';
import { getUserIdFromLocalStorage } from 'utils/LocalStorage';

import { CalendarRequestData, CalendarRequestExport } from '../CalendarTypes';

interface AddEventFormValues {
    title: string;
    description: string;
    date: Date
}

export const thunkGetCalendarData = (start: string, end: string, withMedicines: boolean, withMeasurements: boolean, withConsultations: boolean): ThunkAction<Promise<boolean>, CalendarState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<CalendarRequestData> = await loggedInInstance.get(`event/calendar`, {
                params: {
                    start: start,
                    end: end,
                    medicines: +withMedicines,
                    measurements: +withMeasurements,
                    consultations: +withConsultations,
                }
            });
            dispatch(getCalendarDataSuccess(data.data));
        } catch (error) {
            dispatch(getCalendarDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkExportCalendar = (start: string, end: string, withMedicines: boolean, withMeasurements: boolean, withConsultations: boolean): ThunkAction<Promise<boolean>, CalendarState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<CalendarRequestExport> = await loggedInInstance.get(`event/export/calendar`, {
                params: {
                    start: start,
                    end: end,
                    medicines: +withMedicines,
                    measurements: +withMeasurements,
                    consultations: +withConsultations
                }
            });
            dispatch(exportCalendarSuccess(data.url));
        } catch (error) {
            dispatch(exportCalendarFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkAddCalendarEvent = (form: AddEventFormValues): ThunkAction<Promise<boolean>, CalendarState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const user = getUserIdFromLocalStorage();
            await loggedInInstance.post(`event/create`, {
                "user": user,
                "type": 1,
                "title": form.title,
                "description": form.description,
                "date": moment(form.date).format('YYYY-MM-DD HH:mm:ss'),
                "object": {
                }
            });
            dispatch(addCalendarEventSuccess(true));
            return Promise.resolve(false);
        } catch (error) {
            dispatch(addCalendarEventFail(error.message));
            return Promise.resolve(false);
        }
    }