import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { ImportantMessagesState } from './reducer';
import {
    getNotificationTypesSuccess,
    getNotificationTypesFail,
    getNotificationStatusesSuccess,
    getNotificationStatusesFail,
    addNotificationSuccess,
    addNotificationFail,
} from './actions';
import { AuthState } from 'Auth/store/reducer';
import loggedInInstance from 'axiosInstances/loggedIn.instance';

import { NotificationDictRequestData } from '../ImportantMessagesTypes';
import { NotificationFormValues } from '../Notification/NotificationForm/NotificationForm';

export const thunkGetNotificationDict = (dict: string): ThunkAction<Promise<boolean>, ImportantMessagesState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<NotificationDictRequestData> = await loggedInInstance.get(`notification/dictionary/${dict}`);
            switch (dict) {
                case 'notification-type':
                    dispatch(getNotificationTypesSuccess(data.elements));
                    break;
                case 'notification-status':
                    dispatch(getNotificationStatusesSuccess(data.elements));
                    break;
            }
        } catch (error) {
            switch (dict) {
                case 'notification-type':
                    dispatch(getNotificationTypesFail(error.message));
                    break;
                case 'notification-status':
                    dispatch(getNotificationStatusesFail(error.message));
                    break;
            }
        }
        return Promise.resolve(false)
    }

export const thunkAddNotification = (values: NotificationFormValues): ThunkAction<Promise<boolean>, ImportantMessagesState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.post(`notification/create`, {
                content: values.content,
                topic: values.topic,
                type: values.type.value,
                status: values.status,
            });

            dispatch(addNotificationSuccess(true));
        } catch (error) {
            dispatch(addNotificationFail(error.message));
        }
        return Promise.resolve(false)
    }