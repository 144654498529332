import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { User } from 'views/Measurements/measurements.types';
import moment from 'moment';
interface DefaultEventProps {
    description: string;
    user: User;
    title: string;
    start: string;
    color: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: 345,
        },
        dateHeader: {
            textAlign: 'right',
            color: '#0172ba',
            fontWeight: 'bold',
            paddingBottom: '5px',
            borderBottom: '2px solid #e6e6e6',
            marginBottom: '2px'
        },
        titleHeader: {
            padding: '2px 5px',
            color: 'rgba(0,0,0,0.7)'
        }
    }),
);

const DefaultEvent = (props: DefaultEventProps) => {
    const classes = useStyles();
    return (
		<>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box component="p" className={classes.dateHeader}>
                                {moment(props.start).format('DD.MM.YYYY') }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.titleHeader} style={{ background: props.color }}>
                                { moment(props.start).format('HH:mm') } &nbsp;
                                { props.title }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {props.description}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Avatar id="Avatar" sizes="40px" src={props.user.avatar && props.user.avatar} >
                                        {`${props.user.name[0]}${props.user.surname[0]}`}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={10}>
                                    {props.user.name} {props.user.surname}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
		</>
    );
}

export default DefaultEvent;