import {
    GET_PATIENTS_TABLE_DATA,
    GET_PATIENTS_TABLE_DATA_SUCCESS,
    GET_PATIENTS_TABLE_DATA_FAIL,
    GET_CHANGE_CARE_LEVEL_SUCCESS,
    GET_CHANGE_CARE_LEVEL_FAIL,
    GET_DICT_SUCCESS,
    GET_DICT_FAIL,
    GET_PLACES_SUCCESS,
    GET_PLACES_FAIL,
    GET_MEDICINE_SUCCESS,
    GET_MEDICINE_FAIL,
    GET_MEDICINE_IN_STORAGE_SUCCESS,
    GET_MEASUREMENT_PARAMETERS_SUCCESS,
    ADD_NEW_PATAIENT

} from "./types";
import { FilterData } from "./reducer";
import newPatient from "../AddPatientForm/PersonalDataForm/Patient.type";

export function getPatientsTableData() {
    return {
        type: GET_PATIENTS_TABLE_DATA,
    };
}



export function getDictSuccess(data: any, type: keyof FilterData) {
    return {
        type: GET_DICT_SUCCESS,
        type_of_dict: type,
        payload: data,
    };
}

export function getDictFail(error: any) {
    return {
        type: GET_DICT_FAIL,
        payload: error,
    };
}

export function getMeasurementParametersSuccess(data: any) {
    return {
        type: GET_MEASUREMENT_PARAMETERS_SUCCESS,
        payload: data,
    };
}


export function getPlacesSuccess(places: any) {
    return {
        type: GET_PLACES_SUCCESS,
        payload: places,
    };
}

export function getPlacesFail(error: any) {
    return {
        type: GET_PLACES_FAIL,
        payload: error,
    };
}

export function getMedicinesSuccess(medicines: any) {
    return {
        type: GET_MEDICINE_SUCCESS,
        payload: medicines,
    };
}

export function getMedicinesInStorageSuccess(medicines: any, id: string) {
    return {
        type: GET_MEDICINE_IN_STORAGE_SUCCESS,
        payload: medicines,
        medicineId: id,
        id: id
    };
}

export function getMedicinesFail(error: any) {
    return {
        type: GET_MEDICINE_FAIL,
        payload: error,
    };
}


export function getPatientsTableDataSuccess(data: any) {
    return {
        type: GET_PATIENTS_TABLE_DATA_SUCCESS,
        payload: data,
    };
}

export function getPatientsTableDataFail(error: string) {
    return {
        type: GET_PATIENTS_TABLE_DATA_FAIL,
        payload: error,
    };
}

export function getChangeCareLevelSuccess() {
    return {
        type: GET_CHANGE_CARE_LEVEL_SUCCESS
    };
}

export function getChangeCareLevelFail(errorMsg: string) {
    return {
        type: GET_CHANGE_CARE_LEVEL_FAIL,
        payload: errorMsg
    };
}

export function addNewPatient(user: newPatient.ExistingPatient) {
    return {
        type: ADD_NEW_PATAIENT,
        payload: user,
    }
}