import {
    GET_PARAMETERS_TABLE_DATA_SUCCESS,
    GET_MEASUREMENTS_TABLE_DATA_SUCCESS,
    GET_PARAMETER_PROPOSITIONS_SUCCESS,
    GET_UNITS_PROPOSITIONS_SUCCESS,
    GET_INTERVAL_UNITS_SUCCESS,
    GET_MEASUREMENT_CALCULATION_TYPES_SUCCESS,
    GET_MEASUREMENT_PARAMETER_FIELD_TYPES_SUCCESS,
    GET_MEASUREMENT_PARAMETER_FIELDS_PROPOSITIONS_SUCCESS,
    ADD_NEW_PARAMETER_SUCCESS,
    ADD_EXISTING_PARAMETER_TO_USER_SUCCESS,
    GET_MEASUREMENT_ADD_PARAMETERS_LIST_SUCCESS,
    GET_CAREGIVER_CHILDREN_SUCCESS,
    GET_PARAMETER_FIELDS_FOR_MEASUREMENT_SUCCESS,
    GET_PARAMETER_USERS_FOR_MEASUREMENT_SUCCESS,
    SET_SELECTED_MEASUREMENT,
    GET_LAST_TEN_MEASUREMENTS,
    GET_AVIABLE_COLORS_SUCCESS,
    GET_GRAPHS_DATA_SUCCESS,
    GET_PDF_MAIN_PERSONAL_DATA_SUCCESS,
    GET_GRAPH_DETAILS_SUCCESS,
    DETAILS_VIEW_SWITCH,
    SET_GRAPH_DETAILS_PARAMETER_ID,
    GET_PDF_USER_PARAMETER_DATA_SUCCESS
} from "./types";
import { IdNameType, normalizedParameterPropositions, AddParameterObject, normalizedMeasurements, NormalizedParametersEntities, AddMeasurement, GraphsSection, PdfGenerateSection, GraphDetails } from "../measurements.types";

import { NormalizedSchema } from "normalizr";

export function getMeasurementsTableDataSuccess(data: NormalizedSchema<normalizedMeasurements.MeasurementEntities, string[]>) {
    return {
        type: GET_MEASUREMENTS_TABLE_DATA_SUCCESS,
        payload: data,
    };
}

export function getParametersTableDataSuccess(data: NormalizedSchema<NormalizedParametersEntities, string[]>) {
    return {
        type: GET_PARAMETERS_TABLE_DATA_SUCCESS,
        payload: data,
    };
}


export function getParameterPropositionsSuccess(data: NormalizedSchema<normalizedParameterPropositions.ParameterPropositionsEntities, string[]>) {
    return {
        type: GET_PARAMETER_PROPOSITIONS_SUCCESS,
        payload: data
    }
}

export function getUnitsDictionarySuccess(data: IdNameType[]) {
    return {
        type: GET_UNITS_PROPOSITIONS_SUCCESS,
        payload: data
    }
}

export function getUnitsIntervalDictionarySuccess(data: IdNameType[]) {
    return {
        type: GET_INTERVAL_UNITS_SUCCESS,
        payload: data
    }
}

export function getMeasurementsCalculationTypesDictionarySuccess(data: IdNameType[]) {
    return {
        type: GET_MEASUREMENT_CALCULATION_TYPES_SUCCESS,
        payload: data
    }
}

export function getMeasurementsParameterFieldTypesDictionarySuccess(data: IdNameType[]) {
    return {
        type: GET_MEASUREMENT_PARAMETER_FIELD_TYPES_SUCCESS,
        payload: data
    }
}

export function getMeasurementParameterFieldPropositionsSuccess(data: any) {
    return {
        type: GET_MEASUREMENT_PARAMETER_FIELDS_PROPOSITIONS_SUCCESS,
        payload: data
    }
}

export function addNewParameterSuccess(data: AddParameterObject.NewParameterObject) {
    return {
        type: ADD_NEW_PARAMETER_SUCCESS,
        payload: data
    }
}

export function addExistingParameterToUserSuccess(data: AddParameterObject.ExistingParameterObject) {
    return {
        type: ADD_EXISTING_PARAMETER_TO_USER_SUCCESS,
        payload: data
    }
}

export function getMeasurementAddParametersListSuccess(data: any) {
    return {
        type: GET_MEASUREMENT_ADD_PARAMETERS_LIST_SUCCESS,
        payload: data
    }
}

export function getCaregiverChildrenSuccess(data: AddParameterObject.AddParamAviableUsers[]) {
    return {
        type: GET_CAREGIVER_CHILDREN_SUCCESS,
        payload: data
    }
}

export function getParameterFieldsForMeasurementSuccess(data: AddMeasurement.MeasurementParameterField[]) {
    return {
        type: GET_PARAMETER_FIELDS_FOR_MEASUREMENT_SUCCESS,
        payload: data
    }
}

export function getParameterUsersForMeasurementSuccess(users: IdNameType[]) {
    return {
        type: GET_PARAMETER_USERS_FOR_MEASUREMENT_SUCCESS,
        payload: users
    }
}

export function setSelectedMeasrement(id: IdNameType) {
    return {
        type: SET_SELECTED_MEASUREMENT,
        payload: id
    }
}

export function getLastTenMeasurements(data: AddMeasurement.RecentMeasurement) {
    return {
        type: GET_LAST_TEN_MEASUREMENTS,
        payload: data
    }
}

export function getAvibleColorsSuccess(data: string[]) {
    return {
        type: GET_AVIABLE_COLORS_SUCCESS,
        payload: data
    }
}

export function getGraphsDataSuccess(data: GraphsSection.Parameter[]) {
    return {
        type: GET_GRAPHS_DATA_SUCCESS,
        payload: data
    }
}

export function getPdfMainPersonalDataSuccess(data: PdfGenerateSection.MainPersonalData) {
    return {
        type: GET_PDF_MAIN_PERSONAL_DATA_SUCCESS,
        payload: data
    }
}

export function getPdfUserParameterDataSuccess(data: PdfGenerateSection.UserMeasurement[]) {
    return {
        type: GET_PDF_USER_PARAMETER_DATA_SUCCESS,
        payload: data
    }
}

export function getGraphDetailsSuccess(data: GraphDetails.GraphDetailsData) {
    return {
        type: GET_GRAPH_DETAILS_SUCCESS,
        payload: data
    }
}

export function detailsViewSwitch(data: boolean) {
    return {
        type: DETAILS_VIEW_SWITCH,
        payload: data
    }
}

export function setGraphDetailsParameterId(id: string) {
    return {
        type: SET_GRAPH_DETAILS_PARAMETER_ID,
        payload: id
    }
}
