import { createSelector } from 'reselect'
import { getPatientsData, getParameters } from './reducer'
import { PatientDataCardMenuData } from '../patients.types';
import { OptionType } from 'components/FormInputs/SelectField/SelectField';


export const allParametersSelector = createSelector(
    getPatientsData,
    getParameters,
    (res, parameters) => {
        let parametersObj: { [key: string]: OptionType[] } = {}
        if (parameters.length > 0) {
            parameters.map(param =>
                param.users.map(
                    user => {
                        const params = parametersObj[user.id] ? parametersObj[user.id].map(param => param) : []
                        return parametersObj = { ...parametersObj, [user.id]: [param, ...params] }
                    }
                )
            )
        }

        return res.map((el, idx) => {
            el['id'] = `p${idx}`;
            el['userNameSurname'] = `${el.user.parameters.name} ${el.user.parameters.surname}`;
            el.user = { ...el.user, measurement_parameters: parametersObj[el.user.id] }
            return el
        });
    }
);

export const LinkBarDataSelector = createSelector(
    getPatientsData,
    (data) => {
        let menuData: { [key: string]: PatientDataCardMenuData } = {}
        data.map(element => menuData = {
            ...menuData,
            [element.user.id]: {
                avatar: element.user.avatar,
                name: element.user.parameters.name,
                surname: element.user.parameters.surname,
                age: element.user.parameters.age,
                diesease: element.user.parameters.diesease,
                id: element.user.id
            }
        })
        return menuData;
    }
)