import {
    ImportantMessagesActionTypes,
    GET_NOTIFICATION_TYPES,
    GET_NOTIFICATION_TYPES_FAIL,
    GET_NOTIFICATION_TYPES_SUCCESS,
    GET_NOTIFICATION_STATUSES,
    GET_NOTIFICATION_STATUSES_FAIL,
    GET_NOTIFICATION_STATUSES_SUCCESS,
    ADD_NOTIFICATION_FAIL,
    ADD_NOTIFICATION_SUCCESS
} from "./types";
import { IdNameType } from 'views/Measurements/measurements.types';

export interface ImportantMessagesState {
    receivedNotificationTypes: boolean,
    notificationTypes: IdNameType[] | undefined,
    receivedNotificationStatuses: boolean,
    notificationStatuses: IdNameType[] | undefined,
    receivedAddNotification: boolean,
}

const initialState: ImportantMessagesState = {
    receivedNotificationTypes: false,
    notificationTypes: [],
    receivedNotificationStatuses: false,
    notificationStatuses: [],
    receivedAddNotification: false
}

export function importantMessagesReducer(state = initialState, action: ImportantMessagesActionTypes) {
    switch (action.type) {

        case GET_NOTIFICATION_TYPES:
            return {
                ...state,
                requestedNotificationTypes: true,
                receivedNotificationTypes: false,
                importantMessagesErrorMsg: ''
            };

        case GET_NOTIFICATION_TYPES_FAIL:
            return {
                ...state,
                requestedNotificationTypes: false,
                receivedNotificationTypes: false,
                importantMessagesErrorMsg: action.payload
            };

        case GET_NOTIFICATION_TYPES_SUCCESS:
            return {
                ...state,
                requestedNotificationTypes: false,
                receivedNotificationTypes: true,
                notificationTypes: action.payload
            }

        case GET_NOTIFICATION_STATUSES:
            return {
                ...state,
                requestedNotificationStatuses: true,
                receivedNotificationStatuses: false,
                importantMessagesErrorMsg: ''
            };

        case GET_NOTIFICATION_STATUSES_FAIL:
            return {
                ...state,
                requestedNotificationStatuses: false,
                receivedNotificationStatuses: false,
                importantMessagesErrorMsg: action.payload
            };

        case GET_NOTIFICATION_STATUSES_SUCCESS:
            return {
                ...state,
                requestedNotificationStatuses: false,
                receivedNotificationStatuses: true,
                notificationStatuses: action.payload
            }

        case ADD_NOTIFICATION_SUCCESS:
            return {
                ...state,
                receivedAddNotification: action.payload
            }

        case ADD_NOTIFICATION_FAIL:
            return {
                ...state,
                receivedAddNotification: action.payload
            }

        default:
            return state;
    }
}