import { Doctor, Place, SuggestionOfPlace } from '../MyDoctorsDataTypes';

export const GET_USER_DOCTORS = '[MyDoctors] Get all doctors';
export const GET_USER_PLACES = '[MyDoctors] Get all places';
export const GET_SUGGESTION_OF_PLACES = '[MyDoctors] Get all suggestion of places';

interface GetUserDoctors {
    type: typeof GET_USER_DOCTORS;
    payload: Array<Doctor>;
}

interface GetUserPlaces {
    type: typeof GET_USER_PLACES;
    payload: Array<Place>;
}

interface GetSuggestionOfPlaces {
    type: typeof GET_SUGGESTION_OF_PLACES;
    payload: Array<SuggestionOfPlace>;
}

export type DoctorsActionTypes = GetUserDoctors |
    GetUserPlaces |
    GetSuggestionOfPlaces
    ;