import {
    GET_ALERTS_DATA,
    GET_ALERTS_DATA_FAIL,
    GET_ALERTS_DATA_SUCCESS,
    GET_ALERT_MEASUREMENT_CHART,
    GET_ALERT_MEASUREMENT_CHART_FAIL,
    GET_ALERT_MEASUREMENT_CHART_SUCCESS,
    CLEAR_ALERT_MEASUREMENT_CHARTS,
    APPROVE_ALERT_FAIL,
    APPROVE_ALERT_SUCCESS,
    GET_PATIENT_PLACES_DATA,
    GET_PATIENT_PLACES_DATA_FAIL,
    GET_PATIENT_PLACES_DATA_SUCCESS,
    CREATE_CONSULTATION_FAIL,
    CREATE_CONSULTATION_SUCCESS,
    GET_MEASUREMENT_DOCTOR_LIST_DATA,
    GET_MEASUREMENT_DOCTOR_LIST_DATA_FAIL,
    GET_MEASUREMENT_DOCTOR_LIST_DATA_SUCCESS,
    APPROVE_MEASUREMENT_FAIL,
    APPROVE_MEASUREMENT_SUCCESS,
    APPROVE_MEASUREMENT_ALL_FAIL,
    APPROVE_MEASUREMENT_ALL_SUCCESS,
    GET_CONSULTATIONS_DOCTOR_DATA,
    GET_CONSULTATIONS_DOCTOR_DATA_FAIL,
    GET_CONSULTATIONS_DOCTOR_DATA_SUCCESS,
    EDIT_CONSULTATION_FAIL,
    EDIT_CONSULTATION_SUCCESS,
    COMPLETE_ALL_CONSULTATIONS_FAIL,
    COMPLETE_ALL_CONSULTATIONS_SUCCESS,
    GET_CALENDAR_DOCTOR_DATA,
    GET_CALENDAR_DOCTOR_DATA_FAIL,
    GET_CALENDAR_DOCTOR_DATA_SUCCESS,
    GET_NOTIFICATIONS_DOCTOR_DATA,
    GET_NOTIFICATIONS_DOCTOR_DATA_FAIL,
    GET_NOTIFICATIONS_DOCTOR_DATA_SUCCESS,
    CHANGE_NOTIFICATION_STATUS_DOCTOR_FAIL,
    CHANGE_NOTIFICATION_STATUS_DOCTOR_SUCCESS
} from './types';
import { AlertObject, AlertMeasurementChart, PlaceObject, DoctorMeasurementsObject } from '../DoctorDashboardTypes';
import { CalendarObject } from 'views/Calendar/CalendarTypes';
import { NotificationObject } from 'views/Dashboard/Patient/PatientDashboardTypes';

export function getAlertData() {
    return {
        type: GET_ALERTS_DATA
    }
}

export function getAlertDataSuccess(alerts: AlertObject[]) {
    return {
        type: GET_ALERTS_DATA_SUCCESS,
        payload: alerts
    }
}

export function getAlertDataFail(errorMsg: string) {
    return {
        type: GET_ALERTS_DATA_FAIL,
    }
}

export function getAlertMeasurementChart() {
    return {
        type: GET_ALERT_MEASUREMENT_CHART
    }
}

export function clearAlertMeasurementCharts() {
    return {
        type: CLEAR_ALERT_MEASUREMENT_CHARTS
    }
}

export function getAlertMeasurementChartSuccess(id: string, measurements: AlertMeasurementChart) {
    return {
        type: GET_ALERT_MEASUREMENT_CHART_SUCCESS,
        payload: measurements,
        id: id
    }
}

export function getAlertMeasurementChartFail(errorMsg: string) {
    return {
        type: GET_ALERT_MEASUREMENT_CHART_FAIL,
    }
}

export function approveAlertSuccess(result: boolean) {
    return {
        type: APPROVE_ALERT_SUCCESS,
        payload: result
    }
}

export function approveAlertFail(errorMsg: string) {
    return {
        type: APPROVE_ALERT_FAIL,
    }
}

export function getPatientPlacesData() {
    return {
        type: GET_PATIENT_PLACES_DATA
    }
}

export function getPatientPlacesDataSuccess(places: PlaceObject[]) {
    return {
        type: GET_PATIENT_PLACES_DATA_SUCCESS,
        payload: places
    }
}

export function getPatientPlacesDataFail(errorMsg: string) {
    return {
        type: GET_PATIENT_PLACES_DATA_FAIL,
    }
}

export function createConsultationSuccess(result: boolean) {
    return {
        type: CREATE_CONSULTATION_SUCCESS,
        payload: result
    }
}

export function createConsultationFail(errorMsg: string) {
    return {
        type: CREATE_CONSULTATION_FAIL,
    }
}

export function getMeasurementDoctorListData() {
    return {
        type: GET_MEASUREMENT_DOCTOR_LIST_DATA
    }
}

export function getMeasurementDoctorListDataSuccess(data: DoctorMeasurementsObject) {
    return {
        type: GET_MEASUREMENT_DOCTOR_LIST_DATA_SUCCESS,
        payload: data
    }
}

export function getMeasurementDoctorListDataFail(errorMsg: string) {
    return {
        type: GET_MEASUREMENT_DOCTOR_LIST_DATA_FAIL,
    }
}

export function approveMeasurementSuccess(result: boolean) {
    return {
        type: APPROVE_MEASUREMENT_SUCCESS,
        payload: result
    }
}

export function approveMeasurementFail(errorMsg: string) {
    return {
        type: APPROVE_MEASUREMENT_FAIL,
    }
}

export function approveAllMeasurementSuccess(result: boolean) {
    return {
        type: APPROVE_MEASUREMENT_ALL_SUCCESS,
        payload: result
    }
}

export function approveAllMeasurementFail(errorMsg: string) {
    return {
        type: APPROVE_MEASUREMENT_ALL_FAIL,
    }
}

export function getConsultationsDoctorData() {
    return {
        type: GET_CONSULTATIONS_DOCTOR_DATA
    }
}

export function getConsultationsDoctorDataSuccess(data: CalendarObject[]) {
    return {
        type: GET_CONSULTATIONS_DOCTOR_DATA_SUCCESS,
        payload: data
    }
}

export function getConsultationsDoctorDataFail(errorMsg: string) {
    return {
        type: GET_CONSULTATIONS_DOCTOR_DATA_FAIL,
    }
}

export function editConsultationSuccess(result: boolean) {
    return {
        type: EDIT_CONSULTATION_SUCCESS,
        payload: result
    }
}

export function editConsultationFail(errorMsg: string) {
    return {
        type: EDIT_CONSULTATION_FAIL,
    }
}

export function completeAllConsultationsSuccess(result: boolean) {
    return {
        type: COMPLETE_ALL_CONSULTATIONS_SUCCESS,
        payload: result
    }
}

export function completeAllConsultationsFail(errorMsg: string) {
    return {
        type: COMPLETE_ALL_CONSULTATIONS_FAIL,
    }
}

export function getCalednarDoctorData() {
    return {
        type: GET_CALENDAR_DOCTOR_DATA
    }
}

export function getCalednarDoctorDataSuccess(data: CalendarObject[]) {
    return {
        type: GET_CALENDAR_DOCTOR_DATA_SUCCESS,
        payload: data
    }
}

export function getCalednarDoctorDataFail(errorMsg: string) {
    return {
        type: GET_CALENDAR_DOCTOR_DATA_FAIL,
    }
}

export function getNotificationsDoctorData() {
    return {
        type: GET_NOTIFICATIONS_DOCTOR_DATA
    }
}

export function getNotificationsDoctorDataSuccess(data: NotificationObject[]) {
    return {
        type: GET_NOTIFICATIONS_DOCTOR_DATA_SUCCESS,
        payload: data
    }
}

export function getNotificationsDoctorDataFail(errorMsg: string) {
    return {
        type: GET_NOTIFICATIONS_DOCTOR_DATA_FAIL,
    }
}

export function changeNotificationStatusDoctorSuccess(result: boolean) {
    return {
        type: CHANGE_NOTIFICATION_STATUS_DOCTOR_SUCCESS,
        payload: result
    }
}

export function changeNotificationStatusDoctorFail(errorMsg: string) {
    return {
        type: CHANGE_NOTIFICATION_STATUS_DOCTOR_FAIL,
    }
}