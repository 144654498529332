import * as React from 'react';
import RemedizerPulse from '../RemedizerPulse/RemedizerPulse';

const FallbackRoute = () => {
    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ minHeight: '100%', marginTop: '0px' }}>
            <RemedizerPulse width="200px" height="200px" viewBox={"0 0 108.5 109.333"} style={{ width: 200, height: 200 }} className="" />
        </div>
    );
}

export default FallbackRoute;