
import { schema } from 'normalizr';

const border = new schema.Entity('borders', {}, { idAttribute: 'uuid' });
const field = new schema.Entity('fields', { borders: [border] }, { idAttribute: 'field_id' });
const type = new schema.Entity('types')
const schedule = new schema.Entity('schedules', {}, { idAttribute: 'uuid' })
const user = new schema.Entity('users');
const unit = new schema.Entity('units');

const parameter = new schema.Entity('parameters', {
    type: type,
    fields: [field],
    users: [user],
    schedule: [schedule],
    unit: unit
})

const parametersSchema = [parameter];

export default parametersSchema;
