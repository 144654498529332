import React, { memo, FC } from 'react';
import { TextField, makeStyles, createStyles, Theme, InputAdornment } from '@material-ui/core';
import { getIn } from 'formik';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    errorContainer: {
        fontSize: '0.7rem',
        height: '0.7rem',
        width: '100%',
        padding: '0.3rem',
        color: '#fe4332',
        fontWeight: 500,
    },
}))

interface IFormikTextField {
    field: any;
    form: any;
    variant: string | undefined;
    margin: string | undefined;
    type?: string;
    minNumberValue?: number;
    maxNumberValue?: number;
    className?: string;
    translateProps?: {
        min?: string | number
        max?: string | number
    };
    InputProps?: {
        endAdornment?: any;
        disableUnderline?: boolean;
    };
}

const FormikTextField: FC<IFormikTextField> = memo((props) => {
    const internalClasses = useStyles();
    const { field, form, variant, margin, type, minNumberValue, maxNumberValue, className, translateProps, InputProps, ...restParams } = props;
    const { name } = field;
    const { touched, errors, setFieldValue, setFieldTouched } = form;

    const errorMessage = getIn(errors, name);
    const isTouched = getIn(touched, name);
    const isError = (errorMessage && isTouched) ? true : false;

    const handleBlur = () => {
        setFieldTouched(name, true);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = e;

        if (type === 'number') {
            const numberValue = Number(value);

            if (minNumberValue && maxNumberValue) {

                if (minNumberValue <= numberValue && numberValue <= maxNumberValue) {
                    setFieldValue(field.name, numberValue);
                }

            } else if (minNumberValue) {

                if (minNumberValue <= numberValue) {
                    setFieldValue(field.name, numberValue);
                }

            } else if (maxNumberValue) {

                if (numberValue <= maxNumberValue) {
                    setFieldValue(field.name, numberValue);
                }

            } else {
                setFieldValue(field.name, numberValue);
            }
        } else {
            setFieldValue(field.name, value);
        }
    }

    return (<div className={internalClasses.root}>
        <TextField
            {...field}
            variant={variant ? variant : 'standard'}
            error={isError && isError}
            margin={margin ? margin : 'none'}
            onChange={handleChange}
            onBlur={handleBlur}
            type={type}
            className={className && className}
            {...restParams}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    isError ? <InputAdornment position="end">
                        <i className="fas fa-exclamation-triangle" style={{ color: 'red' }}></i>
                        {InputProps && InputProps.endAdornment}
                    </InputAdornment>
                        :
                        InputProps && InputProps.endAdornment
                ),
                ...InputProps
            }}
        />

        {
            isError
                ?
                // <Translate
                //     className={internalClasses.errorContainer}
                //     value={errorMessage}
                //     min={(translateProps && translateProps.min) ? translateProps.min : undefined}
                //     max={(translateProps && translateProps.max) ? translateProps.max : undefined}
                // />
                <span className={internalClasses.errorContainer}>
                    {I18n.t(errorMessage, translateProps)}
                </span>
                :
                null
        }
    </div>)
})

export default FormikTextField;