import React from 'react';
import { CalendarObject } from 'views/Calendar/CalendarTypes';
import { ReferenceObject } from 'popper.js';
import { getLanguageNameFromLocalStorage } from 'utils/LocalStorage';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'index';
import { Drawer, withStyles, Theme, createStyles, IconButton, Box } from '@material-ui/core';
import logo_green from 'images/Logo.png'
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import plLocale from '@fullcalendar/core/locales/pl';
import enLocale from '@fullcalendar/core/locales/en-gb';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { thunkGetCalendarDoctorList, DoctorDashboardCalendarState } from '../store';
import 'views/Calendar/Calendar.css'
import './DoctorCalendar.css'
import { I18n } from 'react-redux-i18n';
import RemedizerPulse from 'components/RemedizerPulse/RemedizerPulse';
import { View } from '@fullcalendar/core';
import Event from 'views/Calendar/Event/Event';

interface DoctorCalendarProps {
    getCalendarData: (start: string, end: string) => Promise<boolean>;
    calendarData: CalendarObject[] | undefined;
    classes: any;
    isDrawerVisible: boolean;
    changeVisibility: Function;
}

interface DoctorCalendarState {
    isDataFetched: boolean;
    isMouseEntered: boolean;
    calendarData: CalendarObject[] | undefined;
    start: string;
    end: string;
    height: number;
    popperElement: ReferenceObject | null;
    popperElementDetails: {} | null;
}

const initialDoctorCalendarState: DoctorCalendarState = {
    isDataFetched: false,
    isMouseEntered: false,
    calendarData: [],
    start: '',
    end: '',
    height: window.innerHeight,
    popperElement: null,
    popperElementDetails: null,
}

class DoctorCalendar extends React.Component<DoctorCalendarProps, DoctorCalendarState> {

    _isMounted: boolean = false
    state = {
        ...initialDoctorCalendarState
    }

    componentDidMount() {
        this._isMounted = true;
    }

    getCalendarEvents = (info: any, successCallback: any, failureCallback: any) => {
        const start = moment(info.start).format('YYYY-MM-DD');
        const end = moment(info.end).format('YYYY-MM-DD');
        !!this._isMounted && this.setState({ isDataFetched: false });
        !!this._isMounted && this.props.getCalendarData(start, end).then((res: Boolean) => {
            const data = this.props.calendarData || [];
            !!this._isMounted && this.setState({
                isDataFetched: true,
                start: start,
                end: end
            });
            successCallback(data);
        });
    }

    viewSkeletonRender = (arg: {el: HTMLElement, view: View}) => {
        let height = window.innerHeight;

        switch(arg.view.type) {
            case 'dayGridMonth' :
                height = 524;
            break;
        }
        this.setState({ height: height });
    }
    eventMouseEnter = (info:any) => {
        let details = info.event._def.extendedProps.details;
        details.title = info.event.title;
        details.color = info.event.backgroundColor;
        details.start = moment(info.event.start).format('YYYY-MM-DD HH:mm:ss');

        this.setState({
            isMouseEntered: true,
            popperElement: info.el,
            popperElementDetails: details
        });
    }
    eventMouseLeave = (info:any) => {
        this.setState({
            isMouseEntered: false,
            popperElement: null,
            popperElementDetails: null
        });
    }

    buttonIcons = {
        prev: " fas fa-chevron-left",
        next: " fas fa-chevron-right' data-text='"+I18n.t('calendar.next'),
    }

    calendarRef = React.createRef<FullCalendar>();

    render() {
        const userlanguage = getLanguageNameFromLocalStorage() || 'en';
        const { classes, isDrawerVisible, changeVisibility } = this.props;

        return (
            <>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    anchor="left"
                    open={!isDrawerVisible}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <img src={logo_green} alt="Remedizer Logo" className={classes.logo} />

                    <Box className={classes.calendarToolbar}>
                        <IconButton onClick={() => changeVisibility(false)} >
                            <i className="fas fa-times-circle"></i>
                        </IconButton>
                    </Box>

                    {!this.state.isDataFetched &&
                        <Box className={classes.loading}>
                            <RemedizerPulse width="25px" height="25px" viewBox={"0 0 108.5 109.333"} style={{ width: 25, height: 25 }} className="" />
                        </Box>
                    }
                    {this.state.isMouseEntered &&
                        <Event el={this.state.popperElement} open={this.state.isMouseEntered} details={this.state.popperElementDetails} doctor={true} />
                    }
                    <FullCalendar
                        ref={this.calendarRef}
                        defaultView="listDay"
                        locales={[plLocale, enLocale]}
                        locale={userlanguage}
                        plugins={[ dayGridPlugin, listPlugin ]}
                        eventLimit={true}
                        header={{
                            left: 'prev,next title listDay,listWeek,dayGridMonth',
                            center: '',
                            right: ''
                        }}
                        contentHeight={this.state.height - 165}
                        events={this._isMounted ? this.getCalendarEvents : ''}
                        themeSystem="bootstrapFontAwesome"
                        buttonIcons={this.buttonIcons}
                        buttonText={{
                            month: I18n.t('dashboard.consultation.month'),
                            listDay: I18n.t('dashboard.consultation.day'),
                            listWeek: I18n.t('dashboard.consultation.week')
                        }}
                        eventMouseEnter={this.eventMouseEnter}
                        eventMouseLeave={this.eventMouseLeave}
                        viewSkeletonRender={this.viewSkeletonRender}
                    />
                </Drawer>
                {
                    !isDrawerVisible &&
                    <Box className={classes.openIcon}>
                        <IconButton onClick={() => changeVisibility(true)}  >
                            <i className="far fa-calendar-alt"></i>
                        </IconButton>
                    </Box>
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<DoctorDashboardCalendarState, void, Action<any>>) => ({
    getCalendarData: (start: string, end: string): Promise<boolean> => dispatch(thunkGetCalendarDoctorList(start, end))
});

const mapStateToProps = (state: AppState) => {
    return {
        calendarData: state.doctorDashboardCalendar.calendarData
    }
}

const drawerWidth = 240;
const styles = (theme: Theme) => createStyles({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: 'hidden'
    },
    logo: {
        padding: '30px 25px'
    },
    openIcon: {
        zIndex: 2001,
        position: 'fixed',
        top: '100px',
        background: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
    },
    toolbar: theme.mixins.toolbar,
    calendarToolbar : {
        position: 'absolute',
        top: '95px',
        right: '5px',
        '& > button' : {
            fontSize: '12px',
            padding: '5px'
        }
    },
    loading: {
        top: '159px',
        width: '100%',
        left: '0',
        textAlign: 'center',
        background: '#fff',
        zIndex: 10,
        position: 'absolute',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DoctorCalendar));