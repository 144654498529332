import { Dictionary } from './Dictionary'

/* eslint-disable no-template-curly-in-string */

const en: Dictionary = {
	nav: {
		Home: 'Home',
		Login: 'Login',
		Logout: 'Logout',
		ChangePassword: 'Change Password',
		Register: 'Register',
		Meds: 'Meds',
		MedChecks: 'Med Checks',
		Calendar: 'Calendar',
		My_Doctors: 'My Doctors',
		Patients: 'Patients',
		Sicknesses_Schemas: 'Sicknesses Schemas',
		Account: 'Account',
		Pricing: 'Pricing',
		fontSize: {
			selectContrast: 'contrast:',
			selectText: 'font size:'
		}
	},
	loginForm: {
		loginTitle: 'Login',
		registerTitle: 'Register',
		email: 'Enter your e-mail',
		password: 'Enter your password',
		newPassword: 'New password',
		submit: 'Send',
		resetPassword: 'Did you forget your Password?',
		rePassword: 'Type your new password again',
		goBack: 'Go back to login',
		registerEmail: 'Enter your email',
		setNewPassword: 'Set new password',
	},
	startPage: {
		registerSection: {
			Title: 'Register and user free!',
			Paragraph: 'As a doctor, patient or caregiver of the sick person.',
			ButtonGoogle: 'Register with Google',
			ButtonFacebook: 'Register with Facebook',
			ToolTipFacebook: 'Will be avaible soon!',
			ToolTipGoogle: 'Will be avaible soon!',
		},
		textBox: {
			LeftTitle: 'Diary',
			LeftText: 'Self-observation by patients.',
			CenterTitle: 'Your medicines',
			CenterText: 'Information about the medicines owned by the patient, their dosage and times of use.',
			RightTitle: 'Communication',
			RightText: 'The system allows automatic transmission of information about the health condition to the monitoring unit.'
		},
		textSection: {
			FirstSectionTitle: 'DIARY',
			FirstSectionText: 'Self-observation by patients.',
			FirstSectionTitleLower: 'diary',
			SecondSectionTitle: 'YOUR MEDICINES',
			SecondSectionText: 'Information about the medicines owned by the patient, their dosage and times of use.',
			SecondSectionTitleLower: 'your medicines',
			ThirdSectionTitle: 'COMMUNICATION',
			ThirdSectionText: 'The system allows automatic transmission of information about the health condition to the monitoring unit.',
			ThirdSectionTitleLower: 'communication',
		},
		screensSection: {
			Title: 'Easily create an account',
			Paragraph: 'To set up an account in the Remedizer system, enter your email address and receive a message with an activation link.'
		},
	},
	modal: {
		buttons: {
			submit: 'Submit',
			close: 'Cancel',
			save: 'Save',
			cancel: 'Cancel',
			confirm: 'Confirm',
			skip: 'Skip',
			saveAndGo: 'Save and move forward',
			goNext: 'Move forward'
		},
		uniFields: {
			password: 'Password',
			email: 'Email',
			name: 'Name',
			surname: 'Surname',
			age: 'Age',
			rePassowrd: 'Retype password',
			email_or_phone: "Email or phone",
			new_password: 'New password',
			new_password_confirm: 'Retype new password',
			actual_password: 'Actual Password'
		},
		titles: {
			deleteParam: 'Delete parameter'
		}
	},
	404: {
		error: ' Error 404! No such adress.',
		message: ' You will be redirected to valid address in 5 seconds '
	},
	account: {
		invalidToken: 'Your session is over. You will be logged out in %{seconds} s',
		loginToContinue: 'Login to contiune',
		languagePicker: {
			language: 'Language',
			pickLanguage: 'Pick your language',
			pl: 'Polish',
			en: 'English',
			de: 'German',
			fr: 'French',
		},
		userData: {
			birthDate: 'Birth date',
			doctorPlaces: "Doctor's hospital",
			description: 'Description',
			idNumber: 'Id number',
			weight: 'Weight',
			height: 'Height',
			glucose: 'Glucose',
			sex: 'Sex',
			sickness: 'Sickness',
			machine: 'Machine',
			email: 'Email',
			phone: 'Phone',
			address: 'Adress',
			closestHospital: 'Closest Hospital',
			closestPharmacy: 'Closest Pharmacy',
			nameSurname: 'Name and surname',
			medicineDevice: 'Device',
			diesease: 'Sickness',
			glucose_measurement_unitEU: 'mmol/l',
			glucose_measurement_unitEN: 'mg/dl',
			height_unitEU: 'Centimeters',
			height_unitEN: 'Foot',
			weight_unitEU: 'Kilograms',
			weight_unitEN: 'Pounds',
			unitsLabel: 'Units',
			edition: 'Edit',
			cancelEdit: 'Cancel Edit',
			confirmEdit: 'Confirm Changes',
			addAvatar: 'Add avatar',
			caregiversTable: {
				headerLabelName: 'Name',
				headerLabelSurname: 'Surname',
				headerLabelEmail: 'Email',
				headerLabelAccepted: 'Accepted',
				sectionLabelCaregiverChildren: 'Your wards',
				sectionLabelCaregiverParents: 'Your caregivers',
				caregiverPermissionAccepted: 'Yes',
				caregiverPermissionRejected: 'No',
				caregiverPermissionPending: 'Pending',
				noCaregiverChildrenLabel: 'No wards',
				noCaregiverParentslabel: 'No caregivers'
			},
			caregiverRequest: {
				emailInputPlaceholder: 'Email address',
				addAccountButtonLabel: 'Add account',
				addButtonLabel: 'Add',
				informationLabel: 'You sent a request for add a user to an account group of your wards. Information about accepting or rejecting will be send to your email.',
				emailWrongFormatMsg: 'Wrong email format',
				emailRequiredMsg: 'Email is required',
			},
			caregiverResponse: {
				acceptButtonLabel: 'Accept',
				rejectButtonLabel: 'Reject',
				deleteButtonLabel: 'Remove',
				modalTitle: 'Question',
				modalQuestion: 'Do you really want to remove this caregiver?'
			},
			addUserDataForm: {
				title: 'Add element',
				name: 'Field name',
				value: 'Field value',
			},
			addDieseaseForm: {
				title: 'Add diesease',
				name: 'Diesease name'
			},
			addMedicineDeviceForm: {
				title: 'Add medicine device',
				name: 'Medicine device name',
				weight: 'Weight',
				changesRotation: 'Changes rotation',
			},
			requiredFields: 'Required fields'
		},
		webPushForm: {
			title: 'Notifications',
			question: 'Allow notifications',
			yes: 'Yes',
			no: 'No',
			unsupported: 'Your browser does not support notifications or their sending is blocked.\nChange your browser settings or use modern browsers.'
		}
	},
	formInputs: {
		selectLabels: {
			unit: 'Filter by Units',
			user: 'Filter by Users',
			every: 'Every',
			calendarUnit: 'Interval unit',
			typeOfMeasurement: 'Type of measurement calculation',
			paramField: 'Field type',
			dieseases_P: "Dieseases",
			places_P: "Places",
			medicines_P: "Medicines",
			parameters_P: "Parameters",
			medicine_devices_P: "Medicine device",
			age_from: "Age from",
			age_to: "Age to",
			parameterName: 'Filter by parameter name',
			selectNumberOfResults: 'Filter by the number of measurements',
		},
		hourPickerLabels: {
			onHour: 'On hour'
		},
		autocompleateLabels: {
			parameterSelect: 'Prameter name',
			unitSelect: 'Parameter unit',
			fieldSelect: 'Field name',
		}
	},
	patients: {
		patients: "Patients",
		add_patient: "Add patient",
		sort: "Sort A-Z",
		age: "years old",
		age_1: "year old",
		age_2: "years old",
		age_3: "years old",
		age_4: "years old",
		contact: "Kontakt",
		email: "email",
		phone: "tel.",
		distant_care: "Distant care",
		close_care: "Close care",
		filters_button: "filters",
		search_patient: "Search patient",
		userCard: 'User card',
		importantMeassages: 'Important messages',
		medicines: 'Medicines',
		parameters: 'Parameters',
		data: 'Data',
		ageInYears: 'years',
		noUser: 'You have norights to see the selected user',
		from: 'from',
		to: 'to',
		patient_data: 'Personal data',
		add_device_sickness: 'Device and sickness',
		add_medicines: 'Taken medicines',
		add_parameters: 'Measured parameters',
		optional: 'Optional',
	},
	measurements: {
		pdf: {
			modal_title: 'Generate a pdf with patient data',
			share_modal_title: 'Generate and share a pdf with patient data',
			generate_pdf: 'Generate a pdf',
			send_email: 'Send',
			what_form_of_pdf: 'In what form do you want to generate a pdf?',
			date_time_range: "Date time range",
			informatation_shared_with_your_data: "Select the information to be shared with data parameters",
			parameters: "Parameters",
			basic_patient_medical_data: "Basic patient medical data",
			personal_data: "Personal data",
			from_begining: "From begining",
			recipients_email: "recipient's email",
			right_side_empty_information: "Click the \"Generate PDF\" button to generate or the \"Send\" button to send an email with the generated PDF",
			error_status: "An error occured",
			generate_pdf_success_status: "PDF was correctly generated",
			send_pdf_success_status: "PDF sent correctly",
			form_of_pdf: {
				table: "Table",
				chart: "Chart",
			},
			additional_elements_to_show: {
				medicines_taken: "Medicines taken",
				medicines_untaken: "Medicines untaken",
				medicine_devices: "Medicine device",
				medicine_storage: "Medicine storage",
				alerts: "Alerts",
				my_doctors: "My doctors",
				dieseases: "Dieseases",
				future_dozens: "Future dozens"
			},
			personalData: {
				name: "Name",
				surname: "Surname",
				birth_date: "Birth date",
				age: "Age",
				description: "Description",
				address: "Address",
				patient_id: "Patient ID",
				mail: "E-mail",
				phone: "Phone",
				medicine_devices: "Medicine devices",
				dieseases: "Dieseases",
				places: "Places",
				initials: "Initials",
				avatar: "Avatar",
				avatar_url: "Avatar"
			}
		},
		bar: {
			addParameter: 'Add parameter',
			addMeasurement: 'Add measurement',
			views: 'Views',
			measurements: 'Measurements',
			parameters: 'Parameters',
			graphs: 'Graphs',
			paramFromSchema: 'Add parameter from template',
			from: 'from',
			to: 'to',
			or: 'or',
			lastLimit: 'Last',
			resultsPerParameter: 'results for each of the parameters',
			allResults: 'All parameters',
			unit: 'Unit',
			user: 'Users',
			allUsers: 'All users',
			allParams: 'All parameters',
		},
		parametersTable: {
			action: 'Action',
			parameter: 'Parameter',
			unit: 'Unit',
			schedule: 'Schedule of measurement',
			users: 'Users',
			mesurementType: 'Type of Measurement',
			attachment: 'Attachment',
			interval: 'Every',
			day: 'day',
			month: 'month',
			week: 'week',
			on: 'on: ',
			numberOfResults: 'Number of results on page: ',
			select: 'Select filter',
			change: 'Change',
			filters: 'Filters',
			noResults: 'No matching results',
			andNOthers: 'And %{count} others',
			exportToPdf: 'Export to pdf',
			shareData: 'Share data',
			addUser: 'Add user',
			delete: 'Delete',
			active: 'Active',
			inactive: 'Inactive',
			noUserId: 'You nead to crete user first, to see this table',
		},
		measurementsTable: {
			measurements: 'Measurement A-Z',
			value: 'Value',
			date: 'Date of measurement',
			filterSearch: 'Find measurements',
			filterReset: 'Reset',
			startDate: 'Start date',
			endDate: 'End date',
		},
		addParamModal: {
			editSchedule: "Edit schedule",
			advanced: "Advanced Settings",
			basic: "Basic Settings",
			parameterName: "Parameter name",
			periodicity: "Periodicity",
			tooltips: {
				addHour: 'Add hour',
				removeHour: 'Remove hour',
				removeField: 'Remove field',
				addField: 'Add field'
			},
			createOrFindParameter: 'Create or find parameter',
			createOrFindField: 'Create or find field',
			createOrFindUnit: 'Create or find unit',
			incorectValueSelectedPickSomethingElse: 'Incorect value selected. Pick something else',
			equation: 'Equation:',
			noFieldSelected: 'No field selected',
		},
		addExistingParameterModal: {
			title: "Assign parameter",
			description: "Assign %{parameter} parameter to user:",
			user: "User",
			userPlaceholder: "Find user"
		},
		addMeasurementModal: {
			commentAndAttachment: 'Add comment and/or attachment',
			addAttachment: 'Add Attachment',
			skipMeasurement: 'Skip',
			saveMeasurement: 'Save',
			addComment: 'Add a comment',
			closeMeasurement: 'Close',
			compleated: 'Mesurements done',
			noParameters: 'To be able to add measurement, You need to add parameter first',
		},
		graphsSection: {
			alerts: 'Alerts',
			omittedMedicines: 'Omitted medicines',
			omittedMeasurements: 'Omitted measurements',
			lastMeasurement: 'Last measurement',
			noResult: 'No results',
			takenMedicines: 'Taken medicines',
			attachment: 'Attachment',
			comment: 'Comment',
			table: 'Table',
			details: 'Graph',
			omitted_medicine: 'omitted',
			noMeasurements: 'No measurements ordered',
			noMedicines: 'No medicines ordered',
			measurements_results: 'Results',
			date: 'Date',
			medicines: 'Medicines',
			none: 'None',
			omitted_measurement: 'not made',
			noGraphsToDisplay: 'There are no graphs to display. Add some masurements to see them.',
			noMatchingUser: "Selected user don't have this parameter",
			confirmFilters: "Confirm user change by clicking 'ok' to see data"
		}
	},
	yupValidationDefaultMessages: {
		mixed: {
			required: 'Required',
			oneOf: 'Passwords are not equal',
		},
		string: {
			email: 'Incorrect email',
			min: 'Minimum %{min} characters',
			max: 'Maximum %{max} characters',
		},
		array: {
			min: 'Equation needs to have at least  %{min} elements'
		}
	},
	myDoctors: {
		doctor: {
			headerTitle: 'My doctors',
			headerButton: 'Add doctor',
			name: 'Name and surname',
			specialization: 'Specialization',
			place: 'Place',
			callButton: 'Call',
			chatButton: 'Chat',
			noDoctorsMessage: 'No assigned doctors',
			modalTitle: 'Add doctor',
			modalEmailPlaceHolder: 'Email',
			modalNamePlaceHolder: 'Name',
			modalSurnamePlaceHolder: 'Surname',
			modalDeleteQuestion: 'Remove a doctor from a list?',
		},
		place: {
			headerTitle: 'My places',
			headerButton: 'Add place',
			name: 'Place',
			address: 'Address',
			noPlacesMessage: 'No assigned places',
			modalDeleteQuestion: 'Remove a place from a list?',
			modalTitle: 'Add place',
			modalSelectPlaceHolder: ' Enter a place name',
		},
		email: 'Email',
		phone: 'Phone',
		deleteDoctorButton: 'Delete',
		downloadPdfButton: 'Download Pdf',
	},
	calendar: {
		prev: 'Previous',
		next: 'Next',
		exportEvents: 'Export events',
		addEvent: 'Add event',
		addEventForm: {
			modalTitle: 'Add event',
			title: 'Title',
			description: 'Description',
			date: 'Date',
		},
		formValidation: {
			required: 'This field is required'
		},
		filterMedicine: 'Show medicines',
		filterMeasurements: 'Show measurements',
		filterConsultations: 'Show consultations',
	},
	filters: {
		medicine_device1: 'Medicine device 1',
		medicine_device2: 'Medicine device 2',
		medicine_device3: 'Medicine device 3',
		schorzenie1: 'Diesease 1',
		schorzenie2: 'Diesease 2',
		schorzenie3: 'Diesease 3',
		marketing: 'Marketing',
		name: 'Name',
		surname: 'Surname',
		weight_unit: 'Weight unit',
		height_unit: 'Height unit',
		glucose_measurement_unit: 'Glucose measurement unit',
		birth_date: 'Birth date',
		description: 'Description',
		pesel: 'PESEL No',
		sex: 'Sex',
		phone: 'Phone',
		rotations: 'Device rotation',
		specialization: 'Specialization',
		place_health: 'Medical facility',
	},
	medicines: {
		table: {
			nameLabel: 'Medicine',
			amountLabel: 'Amount',
			sufficientToLabel: 'Sufficient to',
			dosageLabel: 'Dosage',
			takenLabel: 'Taken by',
			assignedToLabel: 'Assigned to',
			timeOfTakenLabel: 'Taken at',
			shouldBeTakenByLabel: 'Should be taken by',
			shouldBeTakenAtLabel: 'Should be taken at',
			hasToBeTakenAt: 'Has to be taken at',
			hasToTake: 'Has to take',
			addUserButtonToolTip: 'Add a user',
			changeDosingButtonLabel: 'Change',
			noResults: 'No results',
			fromDayLabel: 'From date',
			toDayLabel: 'To date',
			dosingLabel: 'Dosing',
			noteLabel: 'Note',
		},
		toolBar: {
			addMedicineButtonLabel: 'Add medicine',
		},
		searchBar: {
			addMedicineButtonTooltip: 'Add a medicine',
			searchButtonLabel: 'Search',
			searchText: {
				minLength: 'Minimum 3 characters',
				label: 'Search medicine',
			},
			date: {
				startDateBeforeEndDate: 'Starting date has to be before ending date',
				endDateAfterStartDate: 'Ending date has to be after starting date',
				startDatePickerLabel: 'Start date',
				endDatePickerLabel: 'End date',
			},
			select: {
				selectLabel: 'Show:',
				showAllMedicines: 'All medicines',
				showTakenMedicines: 'Taken medicines',
				showFutureMedicines: 'Future medicines',
				showSkippedMedicines: 'Omitted medicines',
			}
		},
		modal: {
			modalTitle: 'Add medicine',
			reservesTabLabel: 'Reserves',
			scheduleTabLabel: 'Taking schedule',
			saveMedicine: 'Save medicine in storage',
			saveSchedule: 'Save schedule',
			medicineReservesTab: {
				titlePlaceholder: 'Search medicine name',
				amountlabel: 'Amount',
				formLabel: 'Form',
				dosagesLabel: 'Dosage',
				whoElseLabel: 'Who else can use the medicine beside you?',
				usersAssignedToMedicineLabel: 'Users',
				isUserAlarmedAboutEndOfMedicineLabel: 'Notification about the end of the medicine',
				isUserAlarmedAboutEndOfMedicineSwitchYesLabel: 'Yes',
				isUserAlarmedAboutEndOfMedicineSwitchNoLabel: 'No',
				isUserAlarmedAboutEndOfMedicineSwitchTrailingText: 'days to end of medicine',
				medicineDetailsPlaceholder: 'Search a medicine name, to show a flyer',
				priceLabel: 'Price',
				packageLabel: 'Package',
				producerLabel: 'Producer',
				deleteButtonLabel: 'Delete medicine',
				deleteModalQuestion: 'Do you really want to delete this medicine?',
				patientSelectLabel: 'Patient',
				medicineDetails: {
					driving: 'No influence on driving',
					noDriving: 'No driving',
					alcohol: 'Can drink',
					noAlcohol: 'No drinking',
					pregnacy: 'Can influence in pregnacy',
					noPregnacy: 'Can not be taken during pregnacy',
					lactation: 'No influence on lactation',
					noLactation: 'Can not be taken during lactation',
					trimester1: 'No influence during first trimester',
					noTrimester1: 'Can not be taken during first trimester',
					trimester2: 'No influence during second trimester',
					noTrimester2: 'Can not be taken during second trimester',
					trimester3: 'No influence during third trimester',
					noTrimester3: 'Can not be taken during third trimester',
					downloadFlyer: 'Download flyer',
				},
			},
			medicineScheduleTab: {
				fastDosingSettingsLabel: 'Fast dosing settings',
				advancedDosingSettingsLabel: 'Advanced dosing settings',
				every: 'Every',
				interval: 'Interval',
				hour: 'Hour',
				amount: 'Amount',
				addHour: 'Add schedule',
				removeHour: 'Remove schedule',
				remove: 'Remove',
				add: 'Add',
				errorNoDoubledHours: "Hours can't be repeated",
				errorAtLeastOneHour: 'Minimum one schedule is required',
			},
		},
		addUserToMedicineModal: {
			label: 'Add user',
		},
	},
	notifications: {
		noResults: 'No notifications result',
		handleReadClick: 'Sign as readed',
		handleDeleteClick: 'Delete notification',
		status: {
			readed: 'Readed: %{date}'
		},
		type: {
			consultation: 'Consultation',
			measurement: 'Measurement',
			medicineReminder: 'Medicine reminder',
			phone: 'Phone consultation',
		},
		addNotificationForm: {
			type: 'Notification type',
			typePlaceholder: 'Find notification type',
			content: 'Content'
		}
	},
	medicinesToTake: {
		noResults: 'No medicines to take',
		medicinesToTakeAt: 'Medicines to take at',
		takeMedicines: 'Take medicines',
		takeMedicine: 'Take medicine',
		dosage: 'Dosage'
	},
	measurementsToMake: {
		lastMeasurement: 'Last measurement',
		measurementsToMake: 'Measurements to make',
		makeMeasurements: 'Make measurements',
		noResults: 'No measurement to make',
		notMeasuredYet: 'Measurement not yet taken'
	},
	dashboard: {
		medicines: {
			yourStorage: 'Your medicine storage',
			yourStorageDescription: 'Here you will see all the medications you should take that day',
			storageLink: 'Put your first medicine into storage',
		},
		parameters: {
			yourParameters: 'Your measurements',
			yourParametersDescription: 'Here you will see all the scheduled measurements you need to make on a given day',
			parametersLink: 'Enter your first parameter',
		},
		caregivers: {
			yourNotifications: 'Your notifications',
			yourNotificationsDescription: 'Here you will see all notifications you have received from your doctor',
			inviteCaregiver: 'Invite a doctor to Remedizer',
		},
		alerts: {
			noComments: 'No comments',
			noAttachments: 'No attachments',
			years: 'Years',
			messages: 'You have %{count} messages that are waiting for verification',
			message: 'You have %{count} message waiting for verification',
			compareResults: 'Compare results of %{parameter} from last:',
			noResults: 'No results',
			noResultsChart: 'No results for this time period',
			chartSelect: {
				d5: '5 days',
				d10: '10 days',
				d20: '20 days',
				m1: '1 month',
				m3: '3 months',
				m6: '6 months',
				y1: '1 year',
				y2: '2 years',
				y3: '3 years',
			},
			approve: 'Approve',
			requiresConsultation: 'Requires consultation',
			approveAll: 'Approve all',
		},
		orderByDates: 'Order by dates',
		measurements: {
			alerts: 'Alerts',
			notApproved: 'Not approved',
			approved: 'Approved',
			modalQuestion: 'Do you really want to approve all measurements?'
		},
		consultations: 'Consultations',
		consultation: {
			closest: 'Closest',
			completed: 'Completed',
			visitContact: 'Visit',
			phoneContact: 'Phone contact',
			edit: 'Edit',
			completeConsultation: 'Complete consultation',
			sendRequestPhone: 'A phone contact request has been sent',
			sendRequestVisit: 'A visit contact request has been sent',
			complateAll: 'Completed all',
			month: 'Month',
			week: 'Week',
			day: 'Day',
			consultation: 'Phone',
			visit: 'Visit',
			modalTitle: 'Question',
			modalQuestion: 'Do you really want to complete all consultations?',
		}
	},
	templates: {
		addTemplate: 'Add template',
		table: {
			name: 'Name',
			parameters: 'Parameters'
		},
		measurementType: {
			manual: 'Manually entered',
			auto: 'Automatic measurement',
			equation: 'Calculation measurement',
		},
		addTemplateForm: {
			modalTitle: 'Add template',
			editModalTitle: 'Edit template',
			name: 'Template name',
			type: 'Template type',
			typeDiesease: 'Diesease',
			typeDevice: 'Device',
			templateParameters: 'Template parameters:',
			parameter: 'Parameter',
			searchParameter: 'Search parameter',
			removeParameter: 'Remove parameter',
			addParameter: 'Add parameter',
		},
		addParamFromTemplate: {
			selectMinOne: 'At least one parameter must be selected',
			deleteParameterMessage: 'Do you really want to delete parameter?',
			selectTemplate: 'Select template to add parameters',
		}
	},
	consultation: {
		tab: {
			phone: 'Phone consultation',
			visit: 'Visit consultation'
		},
		phone: {
			header: 'Date when the patient should call you:',
		},
		visit: {
			header: 'Date of the visit:',
			place: 'Place of visit:',
			placeLabel: 'Place',
			placePlaceholder: 'Find place',
		}
	},
	importantMessages: {
		header: {
			closestEvent: 'Closest consultation',
			addEvent: 'Add consultation',
			notifications: 'Notifications',
			addNotification: 'Add notification'
		}
	},
	confirmAccount: {
		pleaseWait: 'Please Wait. Registration in progress',
		accountConfirmed: 'Account confirmed corectly',
		somethingGoesWrong: 'Something went wrong',
		backToHomePage: 'Back to home page'
	}
}

export default en;
