import axios from 'axios';
import handleErrors from './ErrorHandling/ErrorHandle';
import { getTokenFromLocalStorage } from 'utils/LocalStorage';
import responseHandler from './ResponseHandler/ResponseHandler';
import url from 'config/backend/url';


const loggedInInstance = axios.create({
    baseURL: url,
    withCredentials: true,
})

loggedInInstance.interceptors.request.use(function (config) {
    const accessToken = getTokenFromLocalStorage() || '';
    config.headers = {
        Authorization: `Bearer ${accessToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    config.responseType = 'json';
    if (!config.params) {
        config.params = {};
    }
    config.params['t'] = new Date().getTime();
    return config;
}, function (error) {
    const changedError = handleErrors(error)
    return Promise.reject(changedError);
});

loggedInInstance.interceptors.response.use(function (response) {
    responseHandler(response)
    return response;
}, function (error) {
    const changedError = handleErrors(error);
    return Promise.reject(changedError);
});


export default loggedInInstance;
