import {
    GET_NOTIFICATIONS_DATA,
    GET_NOTIFICATIONS_DATA_FAIL,
    GET_NOTIFICATIONS_DATA_SUCCESS,
    CHANGE_NOTIFICATION_STATUS_FAIL,
    CHANGE_NOTIFICATION_STATUS_SUCCESS,
    GET_MEDICINES_TO_TAKE_DATA,
    GET_MEDICINES_TO_TAKE_DATA_FAIL,
    GET_MEDICINES_TO_TAKE_DATA_SUCCESS,
    TAKE_MEDICINES_FAIL,
    TAKE_MEDICINES_SUCCESS,
    GET_MEASUREMENTS_TO_MAKE_DATA,
    GET_MEASUREMENTS_TO_MAKE_DATA_FAIL,
    GET_MEASUREMENTS_TO_MAKE_DATA_SUCCESS,
    CHECK_IS_NEW_PATIENT,
    CHECK_IS_NEW_PATIENT_SUCCESS,
    CHECK_IS_NEW_PATIENT_FAIL
} from './types';
import { NotificationObject, MedicineObject, MeasurementObject, PatientObject } from '../PatientDashboardTypes';

export function getNotificationData() {
    return {
        type: GET_NOTIFICATIONS_DATA
    }
}

export function getNotificationDataSuccess(notifications: NotificationObject[]) {
    return {
        type: GET_NOTIFICATIONS_DATA_SUCCESS,
        payload: notifications
    }
}

export function getNotificationDataFail(errorMsg: string) {
    return {
        type: GET_NOTIFICATIONS_DATA_FAIL,
    }
}

export function changeNotificationStatusSuccess(result: boolean) {
    return {
        type: CHANGE_NOTIFICATION_STATUS_SUCCESS,
        payload: result
    }
}

export function changeNotificationStatusFail(errorMsg: string) {
    return {
        type: CHANGE_NOTIFICATION_STATUS_FAIL,
    }
}

export function getMedicineToTakeData() {
    return {
        type: GET_MEDICINES_TO_TAKE_DATA
    }
}

export function getMedicineToTakeDataSuccess(medicines: MedicineObject[]) {
    return {
        type: GET_MEDICINES_TO_TAKE_DATA_SUCCESS,
        payload: medicines
    }
}

export function getMedicineToTakeDataFail(errorMsg: string) {
    return {
        type: GET_MEDICINES_TO_TAKE_DATA_FAIL,
    }
}

export function takeMedicinesSuccess(result: boolean) {
    return {
        type: TAKE_MEDICINES_SUCCESS,
        payload: result
    }
}

export function takeMedicinesFail(errorMsg: string) {
    return {
        type: TAKE_MEDICINES_FAIL,
    }
}

export function getMeasurementsToMakeData() {
    return {
        type: GET_MEASUREMENTS_TO_MAKE_DATA
    }
}

export function getMeasurementsToMakeDataSuccess(measurements: MeasurementObject[]) {
    return {
        type: GET_MEASUREMENTS_TO_MAKE_DATA_SUCCESS,
        payload: measurements
    }
}

export function getMeasurementsToMakeDataFail(errorMsg: string) {
    return {
        type: GET_MEASUREMENTS_TO_MAKE_DATA_FAIL,
    }
}

export function checkIsNewPatient() {
    return {
        type: CHECK_IS_NEW_PATIENT
    }
}

export function checkIsNewPatientSuccess(patient: PatientObject) {
    return {
        type: CHECK_IS_NEW_PATIENT_SUCCESS,
        payload: patient
    }
}

export function checkIsNewPatientFail(errorMsg: string) {
    return {
        type: CHECK_IS_NEW_PATIENT_FAIL,
    }
}