import {
    UserDataActionTypes,
    GET_USER_DATA_PROFILE,
    GET_USER_DATA_PROFILE_FAIL,
    GET_USER_DATA_PROFILE_SUCCESS,
    USER_UPDATE_LANGUAGE_FAIL,
    USER_UPDATE_LANGUAGE_SUCCESS,
    USER_UPDATE_ACCOUNT_DATA_PARTIAL,
    USER_UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS,
    USER_UPDATE_ACCOUNT_DATA_PARTIAL_FAIL,
    USER_SET_LOCATION_FIELD,
    USER_SET_LOCATION_FIELD_SUCCESS,
    USER_SET_LOCATION_FIELD_FAIL,
    USER_CREATE_LOCATION_FIELD,
    USER_CREATE_LOCATION_FIELD_SUCCESS,
    USER_CREATE_LOCATION_FIELD_FAIL,
    // USER_UPLOAD_AVATAR_SUCCESS,
    USER_UPLOAD_AVATAR_FAIL,
    USER_ACTIVE_PARAMETER_SUCCESS,
    USER_ACTIVE_PARAMETER_FAIL
} from "./types";
import { AC_User, UserDataStateTypes } from "views/Account/account.types";
import { Fields } from "views/Patients/patients.types";
import { FilterData } from "views/Patients";
export interface UserDataState {
    receivedUserDataProfile: boolean;
    userDataProfile: AC_User | undefined;
    acErrorMsg: string
    updateRequested: boolean;
    updateSuccess: boolean;
    requestedUpdateLanguage: boolean;
    recivedupdateLanguage: boolean;
    accountLanguageId: number;

    patients: any;
    requestSent: boolean;
    answerRecived: boolean;
    patientsData: Fields[];
    filtres: FilterData;
}

const initialState: UserDataState = {
    receivedUserDataProfile: false,
    userDataProfile: undefined,
    acErrorMsg: '',
    updateRequested: false,
    updateSuccess: false,
    requestedUpdateLanguage: false,
    recivedupdateLanguage: false,
    accountLanguageId: -1,
    patients: [],
    requestSent: false,
    answerRecived: false,
    patientsData: [],
    filtres: {
        medicine_in_storage_users: [],
        user_dieseases: [],
        user_parameters: [],
        user_medicine_devices: [],
        places: [],
        medicines: []
    }
}

export function userDataReducer(state = initialState, action: UserDataActionTypes) {
    switch (action.type) {

        case GET_USER_DATA_PROFILE:
            return {
                ...state,
                requestedUserDataProfile: true,
                receivedUserDataProfile: false,
                userDataErrorMsg: ''
            };

        case GET_USER_DATA_PROFILE_FAIL:
            return {
                ...state,
                requestedUserDataProfile: false,
                receivedUserDataProfile: false,
                userDataErrorMsg: action.payload
            };

        case GET_USER_DATA_PROFILE_SUCCESS:
            return {
                ...state,
                requestedUserDataProfile: false,
                receivedUserDataProfile: true,
                userDataProfile: action.payload
            }
        // case UPLOAD_AVATAR:
        case USER_CREATE_LOCATION_FIELD:
        case USER_SET_LOCATION_FIELD:
        case USER_UPDATE_ACCOUNT_DATA_PARTIAL:

            return {
                ...state, updateRequested: true, updateSuccess: false, acErrorMsg: '',
            };
        case USER_UPLOAD_AVATAR_FAIL:
        case USER_CREATE_LOCATION_FIELD_FAIL:
        case USER_SET_LOCATION_FIELD_FAIL:
        case USER_UPDATE_ACCOUNT_DATA_PARTIAL_FAIL:
        case USER_ACTIVE_PARAMETER_FAIL:
            return {
                ...state, updateRequested: false, updateSuccess: false, acErrorMsg: action.payload
            };

        case USER_CREATE_LOCATION_FIELD_SUCCESS:
        case USER_SET_LOCATION_FIELD_SUCCESS:
        case USER_UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS:
            const changedData = action.payload as UserDataStateTypes;
            const changedDataKey = changedData.key;
            let currentACState = state.userDataProfile;

            if (currentACState && currentACState[changedDataKey]) {
                let selectedPartofACData = changedDataKey && currentACState[changedDataKey];
                selectedPartofACData = changedData;
                (currentACState as AC_User) = { ...currentACState, [changedDataKey]: selectedPartofACData };
            } else if (currentACState && !currentACState.hasOwnProperty(changedDataKey)) {
                (currentACState as AC_User) = { ...currentACState, [changedDataKey]: changedData };
            } else {
                const fullLoad = (action.payload as unknown) as AC_User;
                currentACState = fullLoad;
            }

            return {
                ...state,
                updateRequested: false,
                updateSuccess: true,
                userDataProfile: currentACState
            };
        case USER_ACTIVE_PARAMETER_SUCCESS:
            const element = action.payload;
            const key = element.key;
            const active = element.active;

            let currentState = state.userDataProfile;
            if (currentState && currentState[key]) {
                let changedElement = currentState[key];
                changedElement.active = active;
                (currentState as AC_User) = { ...currentState, [key]: changedElement };
            }
            return {
                ...state,
                updateRequested: false,
                updateSuccess: true,
                userDataProfile: currentState
            };

        case USER_UPDATE_LANGUAGE_SUCCESS:
            return {
                ...state, requestedUpdateLanguage: false, recivedupdateLanguage: true, acErrorMsg: '', accountLanguageId: action.payload
            };

        case USER_UPDATE_LANGUAGE_FAIL:
            return {
                ...state, requestedUpdateLanguage: false, recivedupdateLanguage: false, acErrorMsg: action.payload
            };
        default:
            return state;
    }
}