import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import './RemedizerPulse.css';
import {getRoleFromLocalStorage} from 'utils/LocalStorage';

interface Props {
	width: string;
	height: string;
	className: string;
	viewBox?: any;
  style?: any
}

const RemedizerPulse = React.memo((props: Props) => {
  const role = getRoleFromLocalStorage();

const checkRole = () => {
	if ((role && role.length > 0 && role === 'true') || !role ) {
		return true;
	}
	return false;
}

  let bgColor= "#019EBD" 
  bgColor =  checkRole() === true ? "#019EBD" : '#0173bc';

  return (
	<SvgIcon {...props}>
	  <circle fill='#7FCBDB' stroke="black" strokeWidth="4" cx="51.5" cy="53.917" r="43.75" />
		  <circle className="RemedizerPulse" fill="#FFFFFF" cx="14.758" cy="54.049" r="4" />
	  <path
		fill={`${bgColor}`}         
		d="M51.057,2.215C22.859,2.215,0,25.074,0,53.274c0,28.201,22.858,51.059,51.057,51.059
		c28.201,0,51.058-22.856,51.058-51.059C102.115,25.075,79.258,2.215,51.057,2.215z M86.578,56.45l-17.355-0.004
		c-0.361,0-0.691,0.188-0.846,0.489l-10.496,18.41c-0.777,1.547-3.243,1.333-3.691-0.31l-9.066-29.647
		c-0.213-0.773-1.357-0.887-1.748-0.18l-7.145,11.259c-0.158,0.291-0.483,0.477-0.838,0.477H15.538c-0.519,0-0.938-0.385-0.938-0.861
		v-4.061c0-0.477,0.42-0.854,0.938-0.854h15.953c0.357,0,0.679-0.184,0.837-0.473l11.744-19.469c0.843-1.601,3.413-1.36,3.879,0.366
		l9.008,29.917c0.211,0.791,1.406,0.887,1.766,0.143l5.766-10.183c0.146-0.31,0.486-0.51,0.854-0.51h21.234
		c0.52,0,0.938,0.38,0.938,0.856v3.776C87.514,56.066,87.098,56.45,86.578,56.45z"
	  />
	</SvgIcon>  
  );
})

export default RemedizerPulse;
