import {
    TemplatesActionTypes,
    GET_TEMPLATES_DATA,
    GET_TEMPLATES_DATA_FAIL,
    GET_TEMPLATES_DATA_SUCCESS,
    FIND_PARAMETERS_DATA,
    FIND_PARAMETERS_DATA_FAIL,
    FIND_PARAMETERS_DATA_SUCCESS
} from "./types";
import { Template } from '../TemplatesTypes';
import { Fields } from "views/Patients/patients.types";
import { FilterData } from "views/Patients";
import { Parameter } from "views/Measurements/measurements.types";

export interface TemplatesState {
    receivedTemplates: boolean,
    receivedParameters: boolean,
    templates: Template[] | undefined,
    parameters: Parameter[],
    addTemplateResult: boolean,
    patients: any;
    requestSent: boolean;
    answerRecived: boolean;
    patientsData: Fields[];
    filtres: FilterData;
}

const initialState: TemplatesState = {
    receivedTemplates: false,
    receivedParameters: false,
    templates: [],
    parameters: [],
    addTemplateResult: false,
    patients: [],
    requestSent: false,
    answerRecived: false,
    patientsData: [],
    filtres: {
        medicine_in_storage_users: [],
        user_dieseases: [],
        user_parameters: [],
        user_medicine_devices: [],
        places: [],
        medicines: []
    }
}

export function templatesReducer(state = initialState, action: TemplatesActionTypes) {
    switch (action.type) {

        case GET_TEMPLATES_DATA:
            return {
                ...state,
                requestedTemplates: true,
                receivedTemplates: false,
                templatesErrorMsg: ''
            };

        case GET_TEMPLATES_DATA_FAIL:
            return {
                ...state,
                requestedTemplates: false,
                receivedTemplates: false,
                templatesErrorMsg: action.payload
            };

        case GET_TEMPLATES_DATA_SUCCESS:
            return {
                ...state,
                requestedTemplates: false,
                receivedTemplates: true,
                templates: action.payload
            }

        case FIND_PARAMETERS_DATA:
            return {
                ...state,
                requestedParameters: true,
                receivedParameters: false,
                parametersErrorMsg: ''
            };

        case FIND_PARAMETERS_DATA_FAIL:
            return {
                ...state,
                requestedParameters: false,
                receivedParameters: false,
                parametersErrorMsg: action.payload
            };

        case FIND_PARAMETERS_DATA_SUCCESS:
            return {
                ...state,
                requestedParameters: false,
                receivedParameters: true,
                parameters: action.payload
            }

        default:
            return state;
    }
}