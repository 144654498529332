import { SET_ERROR, SET_SUCCESS, NEW_REQUEST_SENT } from './types';

export function setError(error: string, code?: number) {
    return {
        type: SET_ERROR,
        payload: error,
        code: code,
    }
}

export function setSuccess(message: string, code?: number) {
    return {
        type: SET_SUCCESS,
        payload: message,
        code: code,
    }
}

export function newRequestSent() {
    return {
        type: NEW_REQUEST_SENT,
    }
}