import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import accountReducer, { AccountState } from 'views/Account/store/reducer';
import { syncTranslationWithStore, i18nReducer, I18nState } from 'react-redux-i18n';

import handleErrors from './axiosInstances/ErrorHandling/ErrorHandle';
import responseHandler from './axiosInstances/ResponseHandler/ResponseHandler';

import { createStore, applyMiddleware, combineReducers, compose, Action } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import authReducer, { AuthState } from './Auth/store/reducer';
import { axReducer, AxState } from './axiosInstances/store/reducer';
import { MeasurementsState, measurementsReducer } from './views/Measurements/store';
import { PatientsState, patientsReducer } from 'views/Patients/store';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { doctorsReducer, DoctorsState } from 'views/MyDoctors/store/reducer';
import { CalendarState, calendarReducer } from './views/Calendar/store';
import { medicinesReducer, MedicinesState } from '../src/views/Medicines/store/reducer';
import { patientDashboardReducer, PatientDashboardState } from 'views/Dashboard/Patient/store';
import { doctorDashboardReducer, DoctorDashboardState, DoctorDashboardCalendarState, doctorDashboardCalendarReducer } from 'views/Dashboard/Doctor/store';
import { templatesReducer, TemplatesState } from './views/Templates/store';
import { importantMessagesReducer, ImportantMessagesState } from 'views/ImportantMessages/store';
import { userDataReducer, UserDataState } from './views/UserData/store';
import { languagesReducer, LanguagesState } from './Languages';
import url from 'config/backend/url';

export interface AppState {
  languages: LanguagesState;
  auth: AuthState;
  i18n: I18nState;
  account: AccountState;
  ax: AxState;
  measurements: MeasurementsState;
  doctors: DoctorsState;
  calendar: CalendarState;
  patients: PatientsState;
  medicines: MedicinesState;
  patientDashboard: PatientDashboardState;
  templates: TemplatesState;
  doctorDashboard: DoctorDashboardState;
  doctorDashboardCalendar: DoctorDashboardCalendarState;
  importantMessages: ImportantMessagesState;
  userData: UserDataState;
}

axios.defaults.baseURL = url;

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  config.responseType = 'json';
  config.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  config.responseType = 'json';
  return config;
}, function (error) {
  const changedError = handleErrors(error)
  return Promise.reject(changedError);
});

axios.interceptors.response.use(function (response) {
  responseHandler(response)
  return response;
}, function (error) {
  const changedError = handleErrors(error);
  return Promise.reject(changedError);
});

const appReducers = combineReducers({
  languages: languagesReducer,
  auth: authReducer,
  i18n: i18nReducer,
  account: accountReducer,
  ax: axReducer,
  measurements: measurementsReducer,
  doctors: doctorsReducer,
  calendar: calendarReducer,
  patients: patientsReducer,
  medicines: medicinesReducer,
  patientDashboard: patientDashboardReducer,
  templates: templatesReducer,
  doctorDashboard: doctorDashboardReducer,
  doctorDashboardCalendar: doctorDashboardCalendarReducer,
  importantMessages: importantMessagesReducer,
  userData: userDataReducer,
})

const rootReducers = (state: any, action: Action) => {

  if (action.type === '[Auth] Logout') {
    const languages = state.languages;
    const translations = state.i18n;
    state = {};
    state.i18n = translations;
    state.languages = languages;
  }
  return appReducers(state, action)
}

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(rootReducers, undefined, composeEnhancers(applyMiddleware(thunk)));

syncTranslationWithStore(store);

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiPickersUtilsProvider>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
