export const SET_ERROR = '[Axios] Set Error';
export const SET_SUCCESS = '[Axios] Set Success';
export const NEW_REQUEST_SENT = '[Axios] New Request Sent'

interface SetError {
    type: typeof SET_ERROR;
    payload: string;
    code: number;
}

interface SetSuccess {
    type: typeof SET_SUCCESS;
    payload: string;
    code: number;
}

interface NewRequestSent {
    type: typeof NEW_REQUEST_SENT;
}

export type AxActionTypes = SetError | SetSuccess | NewRequestSent;
