import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { getRoleFromLocalStorage } from 'utils/LocalStorage';

const tableBackground = '#ffffff';

const patientPalette = {
    text: {
        primary: '#3b4a4f',
        secondary: '#779098',
        disabled: '#cccccc',
    },
    primary: {
        main: '#0173bc',
        dark: '#015285',
        light: '#139de8',
    },
    secondary: {
        main: '#f7f8fa',
        light: '#2fc8e8',
        dark: '#f7f8fa'
    },
    error: {
        main: '#FE4332',
        dark: '#d3382a',
    }
}

const doctorPoalette = {
    text: {
        primary: '#3b4a4f',
        secondary: '#779098',
        disabled: '#cccccc',
    },
    primary: {
        main: '#02a0be',
        light: '#2fc8e8',
        dark: '#02687c'
    },
    secondary: {
        main: '#f7f8fa',
        light: '#2fc8e8',
        dark: '#02687c'
    },
    error: {
        main: '#FE4332',
        dark: '#d3382a',
    }
}



function appTheme(isloggedIn: boolean): Theme {

    const palette = getRoleFromLocalStorage() === 'false' && getRoleFromLocalStorage() !== null && isloggedIn ? patientPalette : doctorPoalette;

    const theme = createMuiTheme({
        palette: palette,
        overrides: {
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: `${palette.secondary.main}`,
                    color: `${palette.text.primary}`,
                }
            },
            MuiAvatar: {
                root: {
                    marginRight: '5px',
                    fontWeight: 400,
                    fontSize: '1rem',
                },
            },
            MuiTextField: {
                root: {
                    minWidth: '100%',
                    border: 0,
                    '& .MuiInput-underline:hover': {
                        borderBottom: '0!important',
                        '&:before': {
                            borderBottom: `1px solid ${palette.primary.main}`,
                        }
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: '1px solid #efeeee',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottom: `1px solid ${palette.primary.main}`,
                    },
                    '& .MuiInput-underline.Mui-error:after': {
                        transform: 'scaleX(1)',
                        borderBottom: '2px solid #FE4332'
                    },
                    '& .MuiInput-input': {
                        fontFamily: "Lato",
                        fontWeight: 700,
                    },
                    '& .MuiInputLabel-root': {
                        color: '#779098',
                        fontFamily: "Lato",
                        fontWeight: 700
                    }
                }
            },
            MuiSelect: {
                root: {
                    minWidth: 290,
                    height: 'auto'
                }
            },

            MuiTableSortLabel: {
                root: {
                    color: 'white',
                    ' &:hover': {
                        color: '#ddd'
                    },
                    '&.MuiTableSortLabel-active': {
                        color: 'white'
                    },
                    '&.MuiTableSortLabel-icon': {
                        color: 'white'
                    },
                },
                active: {
                    color: 'white',
                },
                icon: {
                    color: 'white',
                    '&:hover': {
                        color: '#ddd'
                    },
                }
            },
            MuiTable: {
                root: {
                    boxShadow: '0 0 46px rgba(21,47,54,0.13)',
                    overflowX: 'scroll',
                    minHeight: '300px',
                    borderRadius: '6px 6px 6px 6px',
                    backgroundColor: tableBackground,
                },
            },
            MuiTabs: {
                indicator: {
                    backgroundColor: `${palette.primary.main}`,
                    '& > span': {
                        backgroundColor: `${palette.primary.main}`
                    }
                }
            },
            MuiTableRow: {
                root: {
                    '&:last-child': {
                        borderBottom: 'none'
                    }
                }
            },
            MuiTableCell: {
                root: {
                    padding: '14px 14px 14px 16px',
                    minWidth: '70px',
                    '&:last-child, &:first-child': {
                        borderBottom: "none"
                    }
                }
            },
            MuiButton: {
                root: {
                    borderRadius: 4,
                    border: 0,
                    transition: '0.3s',
                    width: 201,
                    height: 55,
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    letterSpacing: 0,
                },
                sizeSmall: {
                    width: 'auto',
                    height: 'auto',
                    minHeight: 64,
                },
                contained: {
                    backgroundColor: `${palette.primary.main}`,
                    color: '#ffffff;',
                    '&:hover': {
                        backgroundColor: `${palette.primary.dark}`,
                        color: `${palette.text.disabled}`
                    }
                },
                outlined: {
                    backgroundColor: '#f7f8fa',
                    color: `${palette.primary.main}`,
                    border: `3px solid ${palette.primary.main}`,
                    '&:hover': {
                        backgroundColor: `${palette.primary.dark}`,
                        color: `${palette.text.disabled}`
                    }
                },
                label: {
                    transform: 'Capitalize',
                    color: 'inherit',
                    lineHeight: '21px'
                },
            },
        }
    })
    return theme
}
export default appTheme;
