export const GET_NOTIFICATION_TYPES = '[Notification types] Get notification types';
export const GET_NOTIFICATION_TYPES_FAIL = '[Notification types] Get notification types Fail';
export const GET_NOTIFICATION_TYPES_SUCCESS = '[Notification types] Get notification types Success';
export const GET_NOTIFICATION_STATUSES = '[Notification Statuses] Get notification Statuses';
export const GET_NOTIFICATION_STATUSES_FAIL = '[Notification Statuses] Get notification Statuses Fail';
export const GET_NOTIFICATION_STATUSES_SUCCESS = '[Notification Statuses] Get notification Statuses Success';
export const ADD_NOTIFICATION_SUCCESS = '[Notification] Add Notification Success';
export const ADD_NOTIFICATION_FAIL = '[Notification] Add Notification Fail';

interface GetNotificationTypes {
    type: typeof GET_NOTIFICATION_TYPES;
}

interface GetNotificationTypesFail {
    type: typeof GET_NOTIFICATION_TYPES_FAIL;
    payload: string;
}

interface GetNotificationTypesSuccess {
    type: typeof GET_NOTIFICATION_TYPES_SUCCESS;
    payload: any;
}

interface GetNotificationStatuses {
    type: typeof GET_NOTIFICATION_STATUSES;
}

interface GetNotificationStatusesFail {
    type: typeof GET_NOTIFICATION_STATUSES_FAIL;
    payload: string;
}

interface GetNotificationStatusesSuccess {
    type: typeof GET_NOTIFICATION_STATUSES_SUCCESS;
    payload: any;
}

interface AddNotificationSuccess {
    type: typeof ADD_NOTIFICATION_SUCCESS;
    payload: string;
}

interface AddNotificationFail {
    type: typeof ADD_NOTIFICATION_FAIL;
    payload: any;
}

export type ImportantMessagesActionTypes =
    GetNotificationTypes |
    GetNotificationTypesFail |
    GetNotificationTypesSuccess |
    GetNotificationStatuses |
    GetNotificationStatusesFail |
    GetNotificationStatusesSuccess |
    AddNotificationFail |
    AddNotificationSuccess
;
