export const GET_ALERTS_DATA = '[Alerts] Get Alerts Data';
export const GET_ALERTS_DATA_FAIL = '[Alerts] Get Alerts Data Fail';
export const GET_ALERTS_DATA_SUCCESS = '[Alerts] Get Alerts Data Success';
export const GET_ALERT_MEASUREMENT_CHART = '[Alerts Measurements] Get Alerts Measurement Data';
export const GET_ALERT_MEASUREMENT_CHART_FAIL = '[Alerts Measurements] Get Alerts Measurement Data Fail';
export const GET_ALERT_MEASUREMENT_CHART_SUCCESS = '[Alerts Measurements] Get Alerts Measurement Data Success';
export const CLEAR_ALERT_MEASUREMENT_CHARTS = '[Alert Measurements] Clear Alerts Measurement charts';
export const APPROVE_ALERT_FAIL = '[Approve Alerts] Approve Alerts Data Fail';
export const APPROVE_ALERT_SUCCESS = '[Approve Alerts] Approve Alerts Data Success';
export const GET_PATIENT_PLACES_DATA = '[Patient places] Get Patient places Data';
export const GET_PATIENT_PLACES_DATA_FAIL = '[Patient places] Get Patient places Data Fail';
export const GET_PATIENT_PLACES_DATA_SUCCESS = '[Patient places] Get Patient places Data Success';
export const CREATE_CONSULTATION_FAIL = '[Create consultation] Create consultation Data Fail';
export const CREATE_CONSULTATION_SUCCESS = '[Create consultation] Create consultation Data Success';
export const GET_MEASUREMENT_DOCTOR_LIST_DATA = '[Measurement doctor list] Get Measurement doctor list Data';
export const GET_MEASUREMENT_DOCTOR_LIST_DATA_FAIL = '[Measurement doctor list] Get Measurement doctor list Data Fail';
export const GET_MEASUREMENT_DOCTOR_LIST_DATA_SUCCESS = '[Measurement doctor list] Get Measurement doctor list Data Success';
export const APPROVE_MEASUREMENT_FAIL = '[Approve Measurement] Approve measurement Fail';
export const APPROVE_MEASUREMENT_SUCCESS = '[Approve Measurement] Approve measurement Success';
export const APPROVE_MEASUREMENT_ALL_FAIL = '[Approve All Measurement] Approve All measurement Fail';
export const APPROVE_MEASUREMENT_ALL_SUCCESS = '[Approve All Measurement] Approve All measurement Success';
export const GET_CONSULTATIONS_DOCTOR_DATA = '[Consultations doctor list] Get Consultations doctor list Data';
export const GET_CONSULTATIONS_DOCTOR_DATA_FAIL = '[Consultations doctor list] Get Consultations doctor list Data Fail';
export const GET_CONSULTATIONS_DOCTOR_DATA_SUCCESS = '[Consultations doctor list] Get Consultations doctor list Data Success';
export const EDIT_CONSULTATION_FAIL = '[Edit consultation] Edit consultation Fail';
export const EDIT_CONSULTATION_SUCCESS = '[Edit consultation] Edit consultation Success';
export const COMPLETE_ALL_CONSULTATIONS_FAIL = '[Complete all consultations] Complete all consultations Fail';
export const COMPLETE_ALL_CONSULTATIONS_SUCCESS = '[Complete all consultations] Complete all consultations Success';
export const GET_CALENDAR_DOCTOR_DATA = '[Calendar doctor data] Get calendar doctor list Data';
export const GET_CALENDAR_DOCTOR_DATA_FAIL = '[Calendar doctor data] Get calendar doctor list Data Fail';
export const GET_CALENDAR_DOCTOR_DATA_SUCCESS = '[Calendar doctor data] Get calendar doctor list Data Success';
export const GET_NOTIFICATIONS_DOCTOR_DATA = '[Notifications doctor data] Get notifications doctor list Data';
export const GET_NOTIFICATIONS_DOCTOR_DATA_FAIL = '[Notifications doctor data] Get notifications doctor list Data Fail';
export const GET_NOTIFICATIONS_DOCTOR_DATA_SUCCESS = '[Notifications doctor data] Get notifications doctor list Data Success';
export const CHANGE_NOTIFICATION_STATUS_DOCTOR_FAIL = '[Change notification status doctor] Change notification status doctor Fail';
export const CHANGE_NOTIFICATION_STATUS_DOCTOR_SUCCESS = '[Change notification status doctor] Change notification status doctor Success';
interface GetAlertsData {
    type: typeof GET_ALERTS_DATA;
}

interface GetAlertsDataFail {
    type: typeof GET_ALERTS_DATA_FAIL;
    payload: string;
}

interface GetAlertsDataSuccess {
    type: typeof GET_ALERTS_DATA_SUCCESS;
    payload: any;
}

interface GetAlertMeasurementChart {
    type: typeof GET_ALERT_MEASUREMENT_CHART;
}

interface GetAlertMeasurementChartFail {
    type: typeof GET_ALERT_MEASUREMENT_CHART_FAIL;
    payload: string;
}

interface GetAlertMeasurementChartSuccess {
    type: typeof GET_ALERT_MEASUREMENT_CHART_SUCCESS;
    payload: any;
    id: any;
}

interface ClearAlertMeasurementCharts {
    type: typeof CLEAR_ALERT_MEASUREMENT_CHARTS;
}

interface ApproveAlertFail {
    type: typeof APPROVE_ALERT_FAIL;
    payload: string;
}

interface ApproveAlertSuccess {
    type: typeof APPROVE_ALERT_SUCCESS;
    payload: any;
}

interface GetPatientPlacesData {
    type: typeof GET_PATIENT_PLACES_DATA;
}

interface GetPatientPlacesDataFail {
    type: typeof GET_PATIENT_PLACES_DATA_FAIL;
    payload: string;
}

interface GetPatientPlacesDataSuccess {
    type: typeof GET_PATIENT_PLACES_DATA_SUCCESS;
    payload: any;
}

interface CreateConsultationFail {
    type: typeof CREATE_CONSULTATION_FAIL;
    payload: string;
}

interface CreateConsultationSuccess {
    type: typeof CREATE_CONSULTATION_SUCCESS;
    payload: any;
}

interface GetMeasurementDoctorListData {
    type: typeof GET_MEASUREMENT_DOCTOR_LIST_DATA;
}

interface GetMeasurementDoctorListDataFail {
    type: typeof GET_MEASUREMENT_DOCTOR_LIST_DATA_FAIL;
    payload: string;
}

interface GetMeasurementDoctorListDataSuccess {
    type: typeof GET_MEASUREMENT_DOCTOR_LIST_DATA_SUCCESS;
    payload: any;
}

interface ApproveMeasurementFail {
    type: typeof APPROVE_MEASUREMENT_FAIL;
    payload: string;
}

interface ApproveMeasurementSuccess {
    type: typeof APPROVE_MEASUREMENT_SUCCESS;
    payload: any;
}

interface ApproveAllMeasurementFail {
    type: typeof APPROVE_MEASUREMENT_ALL_FAIL;
    payload: string;
}

interface ApproveAllMeasurementSuccess {
    type: typeof APPROVE_MEASUREMENT_ALL_SUCCESS;
    payload: any;
}

interface GetConsultationsDoctorData {
    type: typeof GET_CONSULTATIONS_DOCTOR_DATA;
}

interface GetConsultationsDoctorDataFail {
    type: typeof GET_CONSULTATIONS_DOCTOR_DATA_FAIL;
    payload: string;
}

interface GetConsultationsDoctorDataSuccess {
    type: typeof GET_CONSULTATIONS_DOCTOR_DATA_SUCCESS;
    payload: any;
}

interface EditConsultationFail {
    type: typeof EDIT_CONSULTATION_FAIL;
    payload: string;
}

interface EditConsultationSuccess {
    type: typeof EDIT_CONSULTATION_SUCCESS;
    payload: any;
}

interface CompleteAllConsultationsFail {
    type: typeof COMPLETE_ALL_CONSULTATIONS_FAIL;
    payload: string;
}

interface CompleteAllConsultationsSuccess {
    type: typeof COMPLETE_ALL_CONSULTATIONS_SUCCESS;
    payload: any;
}

interface GetCalendarDoctorData {
    type: typeof GET_CALENDAR_DOCTOR_DATA;
}

interface GetCalendarDoctorDataFail {
    type: typeof GET_CALENDAR_DOCTOR_DATA_FAIL;
    payload: string;
}

interface GetCalendarDoctorDataSuccess {
    type: typeof GET_CALENDAR_DOCTOR_DATA_SUCCESS;
    payload: any;
}

interface GetNotificationsDoctorData {
    type: typeof GET_NOTIFICATIONS_DOCTOR_DATA;
}

interface GetNotificationsDoctorDataFail {
    type: typeof GET_NOTIFICATIONS_DOCTOR_DATA_FAIL;
    payload: string;
}

interface GetNotificationsDoctorDataSuccess {
    type: typeof GET_NOTIFICATIONS_DOCTOR_DATA_SUCCESS;
    payload: any;
}

interface ChangeNotificationStatusDoctorFail {
    type: typeof CHANGE_NOTIFICATION_STATUS_DOCTOR_FAIL;
    payload: string;
}

interface ChangeNotificationStatusDoctorSuccess {
    type: typeof CHANGE_NOTIFICATION_STATUS_DOCTOR_SUCCESS;
    payload: any;
}

export type DoctorDashboardCalendarActionTypes =
    GetCalendarDoctorData |
    GetCalendarDoctorDataFail |
    GetCalendarDoctorDataSuccess
;
export type DoctorDashboardActionTypes =
    GetAlertsData |
    GetAlertsDataFail |
    GetAlertsDataSuccess |
    GetAlertMeasurementChart |
    GetAlertMeasurementChartFail |
    GetAlertMeasurementChartSuccess |
    ClearAlertMeasurementCharts |
    ApproveAlertFail |
    ApproveAlertSuccess |
    GetPatientPlacesData |
    GetPatientPlacesDataFail |
    GetPatientPlacesDataSuccess |
    CreateConsultationFail |
    CreateConsultationSuccess |
    GetMeasurementDoctorListData |
    GetMeasurementDoctorListDataFail |
    GetMeasurementDoctorListDataSuccess |
    ApproveMeasurementFail |
    ApproveMeasurementSuccess |
    ApproveAllMeasurementFail |
    ApproveAllMeasurementSuccess |
    GetConsultationsDoctorData |
    GetConsultationsDoctorDataFail |
    GetConsultationsDoctorDataSuccess |
    EditConsultationFail |
    EditConsultationSuccess |
    CompleteAllConsultationsFail |
    CompleteAllConsultationsSuccess |
    GetNotificationsDoctorData |
    GetNotificationsDoctorDataFail |
    GetNotificationsDoctorDataSuccess |
    ChangeNotificationStatusDoctorFail |
    ChangeNotificationStatusDoctorSuccess
;
