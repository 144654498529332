import * as React from 'react';
import Footer from 'components/Footer/Footer';
import DoctorCalendar from 'views/Dashboard/Doctor/DoctorCalendar/DoctorCalendar';
import './Layout.css';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

interface LayoutProps {
    children?: React.ReactChild
    loggedIn: boolean;
    role: boolean;
}

const drawerWidth = 240;
const Layout = (props: LayoutProps) => {

    const bgSelect = () => {
        if (props.loggedIn && props.role) {
            return 'LayoutDoctor';
        } else if (props.loggedIn && !props.role) {
            return 'LayoutPatient';
        } else {
            return 'Layout';
        }
    }

    const classes = useStyles();
    const layout = bgSelect();
    const [drawerVisible, setDrawerVisible] = React.useState(false);
    const drawer = () => {
        if (layout !== 'LayoutDoctor') {
            return '';
        }
        return drawerVisible ? classes.drawerOpen : classes.drawerHidden;
    }

    const changeVisibility = (visible: boolean) => {
        setDrawerVisible(visible);
    }

    return (
        <div className={classes.wrapper}>
            {
                layout === 'LayoutDoctor' &&
                <DoctorCalendar
                    isDrawerVisible={drawerVisible}
                    changeVisibility={changeVisibility}
                />
            }
            <div className={`container-fluid text-white  p-0 ${layout} ${drawer()}`}>
                <div className="container d-flex flex-column min-vh-100 ">
                    {props.children}
                </div>
                <Footer />
            </div>
        </div>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: 'flex'
        },
        drawerOpen: {
            flexGrow: 1,
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: 0,
            padding: '1px !important',
            zIndex: 2000,
            transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            '& > .container > header > .MuiToolbar-root': {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: '240px',
                padding: '0',
                '& .NavElement .nav-link': {
                    [theme.breakpoints.down('lg')]: {
                        padding: '0 5px!important',
                        fontSize: '12px'
                    },
                    [theme.breakpoints.up('lg')]: {
                        padding: '0 20px!important',
                        fontSize: '14px'
                    },
                },
                '& .NavElement .dropdown-toggle': {
                    [theme.breakpoints.down('lg')]: {
                        margin: '0 4px!important',
                        fontSize: '12px'
                    },
                    [theme.breakpoints.up('lg')]: {
                        margin: '0 0.5rem 0 1rem !important',
                        fontSize: '14px'
                    },
                }
            },

        },
        drawerHidden: {
            flexGrow: 1,
            marginLeft: `-${drawerWidth}px`,
            zIndex: 2000,
            transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
        }
    })
);

export default Layout;