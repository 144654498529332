import {
    GET_CALENDAR_DATA,
    GET_CALENDAR_DATA_FAIL,
    GET_CALENDAR_DATA_SUCCESS,
    EXPORT_CALENDAR_SUCCESS,
    EXPORT_CALENDAR_FAIL,
    ADD_CALENDAR_EVENT_SUCCESS,
    ADD_CALENDAR_EVENT_FAIL
} from './types';
import { CalendarObject } from '../CalendarTypes';

export function getCalendarData() {
    return {
        type: GET_CALENDAR_DATA
    }
}

export function getCalendarDataSuccess(calendarData: CalendarObject[]) {
    return {
        type: GET_CALENDAR_DATA_SUCCESS,
        payload: calendarData
    }
}

export function getCalendarDataFail(errorMsg: string) {
    return {
        type: GET_CALENDAR_DATA_FAIL,
    }
}

export function exportCalendarSuccess(url: string) {
    return {
        type: EXPORT_CALENDAR_SUCCESS,
        payload: url
    }
}

export function exportCalendarFail(errorMsg: string) {
    return {
        type: EXPORT_CALENDAR_FAIL,
    }
}

export function addCalendarEventSuccess(result: boolean) {
    return {
        type: ADD_CALENDAR_EVENT_SUCCESS,
        payload: result
    }
}

export function addCalendarEventFail(errorMsg: string) {
    return {
        type: ADD_CALENDAR_EVENT_FAIL,
    }
}