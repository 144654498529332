import {
    DoctorDashboardActionTypes,
    DoctorDashboardCalendarActionTypes,
    GET_ALERTS_DATA,
    GET_ALERTS_DATA_FAIL,
    GET_ALERTS_DATA_SUCCESS,
    GET_ALERT_MEASUREMENT_CHART,
    GET_ALERT_MEASUREMENT_CHART_FAIL,
    GET_ALERT_MEASUREMENT_CHART_SUCCESS,
    CLEAR_ALERT_MEASUREMENT_CHARTS,
    APPROVE_ALERT_FAIL,
    APPROVE_ALERT_SUCCESS,
    GET_PATIENT_PLACES_DATA,
    GET_PATIENT_PLACES_DATA_FAIL,
    GET_PATIENT_PLACES_DATA_SUCCESS,
    CREATE_CONSULTATION_FAIL,
    CREATE_CONSULTATION_SUCCESS,
    GET_MEASUREMENT_DOCTOR_LIST_DATA,
    GET_MEASUREMENT_DOCTOR_LIST_DATA_FAIL,
    GET_MEASUREMENT_DOCTOR_LIST_DATA_SUCCESS,
    APPROVE_MEASUREMENT_FAIL,
    APPROVE_MEASUREMENT_SUCCESS,
    APPROVE_MEASUREMENT_ALL_FAIL,
    APPROVE_MEASUREMENT_ALL_SUCCESS,
    GET_CONSULTATIONS_DOCTOR_DATA,
    GET_CONSULTATIONS_DOCTOR_DATA_FAIL,
    GET_CONSULTATIONS_DOCTOR_DATA_SUCCESS,
    EDIT_CONSULTATION_FAIL,
    EDIT_CONSULTATION_SUCCESS,
    COMPLETE_ALL_CONSULTATIONS_FAIL,
    COMPLETE_ALL_CONSULTATIONS_SUCCESS,
    GET_CALENDAR_DOCTOR_DATA,
    GET_CALENDAR_DOCTOR_DATA_FAIL,
    GET_CALENDAR_DOCTOR_DATA_SUCCESS,
    GET_NOTIFICATIONS_DOCTOR_DATA,
    GET_NOTIFICATIONS_DOCTOR_DATA_FAIL,
    GET_NOTIFICATIONS_DOCTOR_DATA_SUCCESS,
    CHANGE_NOTIFICATION_STATUS_DOCTOR_FAIL,
    CHANGE_NOTIFICATION_STATUS_DOCTOR_SUCCESS
} from "./types";
import { AlertObject, AlertMeasurementChart, PlaceObject, DoctorMeasurementsObject } from '../DoctorDashboardTypes';
import { CalendarObject } from "views/Calendar/CalendarTypes";
import { NotificationObject } from "views/Dashboard/Patient/PatientDashboardTypes";

export interface DoctorDashboardState {
    receivedAlerts: boolean,
    receivedAlertMeasurements: boolean;
    receivedApproveAlert: boolean;
    receivedCreateConsultation: boolean;
    receivedDoctorMeasurements: boolean,
    receivedApproveMeasurement: boolean;
    receivedApproveAllMeasurements: boolean;
    receivedDoctorConsultations: boolean;
    receivedEditConsultation: boolean;
    receivedCompleteAllConsultations: boolean;
    receivedChangeNotificationStatus: boolean;
    alerts: AlertObject[] | undefined;
    doctorMeasurements: DoctorMeasurementsObject | undefined;
    doctorConsultations: CalendarObject[] | undefined;
    measurements: AlertMeasurementChart[];
    places: PlaceObject[];
    notifications: NotificationObject[] | undefined;
}

export interface DoctorDashboardCalendarState {
    receivedCalendar: boolean,
    calendarData: CalendarObject[] | undefined,
}

const initialState: DoctorDashboardState = {
    receivedAlerts: false,
    receivedAlertMeasurements: false,
    receivedApproveAlert: false,
    receivedCreateConsultation: false,
    receivedDoctorMeasurements: false,
    receivedApproveMeasurement: false,
    receivedApproveAllMeasurements: false,
    receivedDoctorConsultations: false,
    receivedEditConsultation: false,
    receivedCompleteAllConsultations: false,
    receivedChangeNotificationStatus: false,
    alerts: [],
    doctorMeasurements: undefined,
    doctorConsultations: undefined,
    measurements: [],
    places: [],
    notifications: []
}

const initialStateCalendar: DoctorDashboardCalendarState = {
    receivedCalendar: false,
    calendarData: []
}

export function doctorDashboardCalendarReducer(state = initialStateCalendar, action: DoctorDashboardCalendarActionTypes) {
    switch (action.type)
    {
        case GET_CALENDAR_DOCTOR_DATA:
            return {
                ...state,
                requestedCalendar: true,
                receivedCalendar: false,
                alertsErrorMsg: ''
            };

        case GET_CALENDAR_DOCTOR_DATA_FAIL:
            return {
                ...state,
                requestedCalendar: false,
                receivedCalendar: false,
                alertsErrorMsg: action.payload
            };

        case GET_CALENDAR_DOCTOR_DATA_SUCCESS:
            return {
                ...state,
                requestedCalendar: false,
                receivedCalendar: true,
                calendarData: action.payload
            }
        default:
            return state;
    }
}

export function doctorDashboardReducer(state = initialState, action: DoctorDashboardActionTypes) {
    switch (action.type) {

        case GET_ALERTS_DATA:
            return {
                ...state,
                requestedAlert: true,
                receivedAlert: false,
                alertsErrorMsg: ''
            };

        case GET_ALERTS_DATA_FAIL:
            return {
                ...state,
                requestedAlert: false,
                receivedAlert: false,
                alertsErrorMsg: action.payload
            };

        case GET_ALERTS_DATA_SUCCESS:
            return {
                ...state,
                requestedAlertMeasurements: false,
                receivedAlertMeasurements: true,
                alerts: action.payload
            }

        case GET_ALERT_MEASUREMENT_CHART:
            return {
                ...state,
                requestedAlertMeasurements: true,
                receivedAlertMeasurements: false,
                alertsMeasurementsErrorMsg: ''
            };

        case GET_ALERT_MEASUREMENT_CHART_FAIL:
            return {
                ...state,
                requestedAlertMeasurements: false,
                receivedAlertMeasurements: false,
                alertsMeasurementsErrorMsg: action.payload
            };

        case GET_ALERT_MEASUREMENT_CHART_SUCCESS:
            let measurements = state.measurements;
            if(action.hasOwnProperty('id')) {
                measurements[action.id] = action.payload;
            }
            return {
                ...state,
                requestedAlertMeasurements: false,
                receivedAlertMeasurements: true,
                measurements: measurements
            }
        case CLEAR_ALERT_MEASUREMENT_CHARTS:
            return {
                ...state,
                measurements: []
            }

        case APPROVE_ALERT_FAIL:
            return {
                ...state,
                requestedApproveAlert: false,
                receivedApproveAlert: false,
                alertsErrorMsg: action.payload
            };

        case APPROVE_ALERT_SUCCESS:
            return {
                ...state,
                requestedApproveAlertMeasurements: false,
                receivedApproveAlertMeasurements: true
            }

        case GET_PATIENT_PLACES_DATA:
            return {
                ...state,
                requestedPatientPlaces: true,
                receivedPatientPlaces: false,
                alertsErrorMsg: ''
            };

        case GET_PATIENT_PLACES_DATA_FAIL:
            return {
                ...state,
                requestedPatientPlaces: false,
                receivedPatientPlaces: false,
                alertsErrorMsg: action.payload
            };

        case GET_PATIENT_PLACES_DATA_SUCCESS:
            return {
                ...state,
                requestedPatientPlaces: false,
                receivedPatientPlaces: true,
                places: action.payload
            }

        case CREATE_CONSULTATION_FAIL:
            return {
                ...state,
                requestedCreateConsultation: false,
                receivedCreateConsultation: false,
                alertsErrorMsg: action.payload
            };

        case CREATE_CONSULTATION_SUCCESS:
            return {
                ...state,
                requestedCreateConsultationMeasurements: false,
                receivedCreateConsultationMeasurements: true
            }

        case GET_MEASUREMENT_DOCTOR_LIST_DATA:
            return {
                ...state,
                requestedDoctorMeasurements: true,
                receivedDoctorMeasurements: false,
                alertsErrorMsg: ''
            };

        case GET_MEASUREMENT_DOCTOR_LIST_DATA_FAIL:
            return {
                ...state,
                requestedDoctorMeasurements: false,
                receivedDoctorMeasurements: false,
                alertsErrorMsg: action.payload
            };

        case GET_MEASUREMENT_DOCTOR_LIST_DATA_SUCCESS:
            return {
                ...state,
                requestedDoctorMeasurements: false,
                receivedDoctorMeasurements: true,
                doctorMeasurements: action.payload
            }

        case APPROVE_MEASUREMENT_FAIL:
            return {
                ...state,
                requestedApproveMeasurement: false,
                receivedApproveMeasurement: false,
                alertsErrorMsg: action.payload
            };

        case APPROVE_MEASUREMENT_SUCCESS:
            return {
                ...state,
                requestedApproveMeasurement: false,
                receivedApproveMeasurement: true
            }

        case APPROVE_MEASUREMENT_ALL_FAIL:
                return {
                    ...state,
                    requestedApproveAllMeasurements: false,
                    receivedApproveAllMeasurements: false,
                    alertsErrorMsg: action.payload
                };

        case APPROVE_MEASUREMENT_ALL_SUCCESS:
            return {
                ...state,
                requestedApproveAllMeasurements: false,
                receivedApproveAllMeasurements: true
            }

        case GET_CONSULTATIONS_DOCTOR_DATA:
            return {
                ...state,
                requestedDoctorConsultations: true,
                receivedDoctorConsultations: false,
                alertsErrorMsg: ''
            };

        case GET_CONSULTATIONS_DOCTOR_DATA_FAIL:
            return {
                ...state,
                requestedDoctorConsultations: false,
                receivedDoctorConsultations: false,
                alertsErrorMsg: action.payload
            };

        case GET_CONSULTATIONS_DOCTOR_DATA_SUCCESS:
            return {
                ...state,
                requestedDoctorConsultations: false,
                receivedDoctorConsultations: true,
                doctorConsultations: action.payload
            }

        case EDIT_CONSULTATION_FAIL:
            return {
                ...state,
                requestedEditConsultation: false,
                receivedEditConsultation: false,
                editConsultationMsg: action.payload
            };

        case EDIT_CONSULTATION_SUCCESS:
            return {
                ...state,
                requestedEditConsultation: false,
                receivedEditConsultation: true
            }

        case COMPLETE_ALL_CONSULTATIONS_FAIL:
            return {
                ...state,
                requestedCompleteAllConsultations: false,
                receivedCompleteAllConsultations: false,
                completeAllConsultationMsg: action.payload
            };

        case COMPLETE_ALL_CONSULTATIONS_SUCCESS:
            return {
                ...state,
                requestedCompleteAllConsultations: false,
                receivedCompleteAllConsultations: true
            }

        case GET_NOTIFICATIONS_DOCTOR_DATA:
            return {
                ...state,
                requestedDoctorNotifications: true,
                receivedDoctorNotifications: false,
                alertsErrorMsg: ''
            };

        case GET_NOTIFICATIONS_DOCTOR_DATA_FAIL:
            return {
                ...state,
                requestedDoctorNotifications: false,
                receivedDoctorNotifications: false,
                alertsErrorMsg: action.payload
            };

        case GET_NOTIFICATIONS_DOCTOR_DATA_SUCCESS:
            return {
                ...state,
                requestedDoctorNotifications: false,
                receivedDoctorNotifications: true,
                notifications: action.payload
            }

        case CHANGE_NOTIFICATION_STATUS_DOCTOR_FAIL:
            return {
                ...state,
                requestedChangeNotificationStatus: false,
                receivedChangeNotificationStatus: false,
                changeNotificationStatusMsg: action.payload
            };

        case CHANGE_NOTIFICATION_STATUS_DOCTOR_SUCCESS:
            return {
                ...state,
                requestedChangeNotificationStatus: false,
                receivedChangeNotificationStatus: true
            }

        default:
            return state;
    }
}