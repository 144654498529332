import {
    GET_USER_DATA_PROFILE,
    GET_USER_DATA_PROFILE_FAIL,
    GET_USER_DATA_PROFILE_SUCCESS,

    USER_UPDATE_LANGUAGE_FAIL,
    USER_UPDATE_LANGUAGE_SUCCESS,
    USER_UPDATE_ACCOUNT_DATA_PARTIAL,
    USER_UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS,
    USER_UPDATE_ACCOUNT_DATA_PARTIAL_FAIL,
    USER_SET_LOCATION_FIELD,
    USER_SET_LOCATION_FIELD_SUCCESS,
    USER_SET_LOCATION_FIELD_FAIL,
    USER_CREATE_LOCATION_FIELD,
    USER_CREATE_LOCATION_FIELD_SUCCESS,
    USER_CREATE_LOCATION_FIELD_FAIL,
    USER_UPLOAD_AVATAR_SUCCESS,
    USER_UPLOAD_AVATAR_FAIL,
    USER_ACTIVE_PARAMETER_SUCCESS,
    USER_ACTIVE_PARAMETER_FAIL
} from './types';
import { AC_User, UserDataStateTypes, ActiveParameterType } from 'views/Account/account.types';

export function getUserDataProfile() {
    return {
        type: GET_USER_DATA_PROFILE
    }
}

export function getUserDataProfileSuccess(profile: AC_User) {
    return {
        type: GET_USER_DATA_PROFILE_SUCCESS,
        payload: profile
    }
}

export function getUserDataProfileFail(errorMsg: string) {
    return {
        type: GET_USER_DATA_PROFILE_FAIL,
    }
}

export function updateLanguageSuccess(data: number) {
    return {
        type: USER_UPDATE_LANGUAGE_SUCCESS,
        payload: data
    };
}

export function updateLanguageFail(errorMsg: string) {
    return {
        type: USER_UPDATE_LANGUAGE_FAIL,
        payload: errorMsg
    };
}

export function updateAccountPartialData() {
    return {
        type: USER_UPDATE_ACCOUNT_DATA_PARTIAL,
    };
}

export function updateAccountPartialDataSuccess(data: UserDataStateTypes) {
    return {
        type: USER_UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS,
        payload: data
    };
}

export function updateAccountPartialDataFail(errorMsg: string) {
    return {
        type: USER_UPDATE_ACCOUNT_DATA_PARTIAL_FAIL,
        payload: errorMsg
    };
}

export function setLocationField() {
    return {
        type: USER_SET_LOCATION_FIELD
    };
}

export function setLocationFieldSuccess(data: UserDataStateTypes) {
    return {
        type: USER_SET_LOCATION_FIELD_SUCCESS,
        payload: data
    };
}

export function setLocationFieldFail(error: string) {
    return {
        type: USER_SET_LOCATION_FIELD_FAIL,
        payload: error
    };
}

export function createLocationField() {
    return {
        type: USER_CREATE_LOCATION_FIELD,
    };
}

export function createLocationFieldSuccess(data: UserDataStateTypes) {
    return {
        type: USER_CREATE_LOCATION_FIELD_SUCCESS,
        payload: data
    };
}

export function createLocationFieldFail(error: string) {
    return {
        type: USER_CREATE_LOCATION_FIELD_FAIL,
        payload: error
    };
}

export function uploadAvatarSuccess() {
    return {
        type: USER_UPLOAD_AVATAR_SUCCESS
    };
}

export function uploadAvatarFail(error: string) {
    return {
        type: USER_UPLOAD_AVATAR_FAIL,
        payload: error
    };
}

export function activeParameterSuccess(activeParameter: ActiveParameterType) {
    return {
        type: USER_ACTIVE_PARAMETER_SUCCESS,
        payload: activeParameter
    };
}

export function activeParameterFail(error: string) {
    return {
        type: USER_ACTIVE_PARAMETER_FAIL,
        payload: error
    };
}