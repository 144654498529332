import { AxiosResponse } from 'axios';

import { store } from '../../index';
import { setSuccess } from '../store';
import { updateToken } from '../../Auth';

interface RemedizerApiResponse {
    code: number;
    message: string;
    token?: string
}

function responseHandler(response: AxiosResponse<RemedizerApiResponse>) {
    const token = response && response.data && response.data.token ? response.data.token : null;
    let message = response && response.data && response.data.message ? response.data.message : '';
    const params = response && response.config && response.config.params ? response.config.params : {};
    !!token && store.dispatch(updateToken(token));
    if (!!params && params.ignore && +response.status === +params.ignore) {
        return
    }
    if (response.config.url?.indexOf('webpush') !== -1 && response.status === 204) {
        message = 'Poprawanie zapisano zmiany';
    }
    response.config.method !== 'GET' && response.config.method !== 'get' && store.dispatch(setSuccess(message))
}

export default responseHandler;
