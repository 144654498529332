import {
    PatientDashboardActionTypes,
    GET_NOTIFICATIONS_DATA,
    GET_NOTIFICATIONS_DATA_FAIL,
    GET_NOTIFICATIONS_DATA_SUCCESS,
    GET_MEDICINES_TO_TAKE_DATA,
    GET_MEDICINES_TO_TAKE_DATA_FAIL,
    GET_MEDICINES_TO_TAKE_DATA_SUCCESS,
    CHANGE_NOTIFICATION_STATUS_FAIL,
    CHANGE_NOTIFICATION_STATUS_SUCCESS,
    TAKE_MEDICINES_FAIL,
    TAKE_MEDICINES_SUCCESS,
    GET_MEASUREMENTS_TO_MAKE_DATA,
    GET_MEASUREMENTS_TO_MAKE_DATA_FAIL,
    GET_MEASUREMENTS_TO_MAKE_DATA_SUCCESS,
    CHECK_IS_NEW_PATIENT,
    CHECK_IS_NEW_PATIENT_FAIL,
    CHECK_IS_NEW_PATIENT_SUCCESS
} from "./types";
import { NotificationObject, MedicineObject, MeasurementObject, PatientObject } from '../PatientDashboardTypes';

export interface PatientDashboardState {
    receivedNotification: boolean,
    receivedChangeNotificationStatus: boolean,
    receivedMedicines: boolean,
    receivedMedicinesToTake: boolean,
    receivedMeasurements: boolean,
    receivedPatient: boolean,
    notifications: NotificationObject[] | undefined,
    medicines: MedicineObject[] | undefined,
    measurements: MeasurementObject[] | undefined
    patient: PatientObject | undefined
}

const initialState: PatientDashboardState = {
    receivedNotification: false,
    receivedChangeNotificationStatus: false,
    receivedMedicines: false,
    receivedMedicinesToTake: false,
    receivedMeasurements: false,
    receivedPatient: false,
    notifications: [],
    medicines: [],
    measurements: [],
    patient: {
        caregivers: 0,
        medicines: 0,
        parameters: 0
    }
}

export function patientDashboardReducer(state = initialState, action: PatientDashboardActionTypes) {
    switch (action.type) {

        case GET_NOTIFICATIONS_DATA:
            return {
                ...state,
                requestedNotification: true,
                receivedNotification: false,
                calendarErrorMsg: ''
            };

        case GET_NOTIFICATIONS_DATA_FAIL:
            return {
                ...state,
                requestedNotification: false,
                receivedNotification: false,
                calendarErrorMsg: action.payload
            };

        case GET_NOTIFICATIONS_DATA_SUCCESS:
            return {
                ...state,
                requestedNotification: false,
                receivedNotification: true,
                notifications: action.payload
            }

        case CHANGE_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                receivedChangeNotificationStatus: action.payload
            }

        case CHANGE_NOTIFICATION_STATUS_FAIL:
            return {
                ...state,
                receivedChangeNotificationStatus: action.payload
            }

        case GET_MEDICINES_TO_TAKE_DATA:
            return {
                ...state,
                requestedMedicinesToTake: true,
                receivedMedicinesToTake: false,
                calendarErrorMsg: ''
            };

        case GET_MEDICINES_TO_TAKE_DATA_FAIL:
            return {
                ...state,
                requestedMedicinesToTake: false,
                receivedMedicinesToTake: false,
                calendarErrorMsg: action.payload
            };

        case GET_MEDICINES_TO_TAKE_DATA_SUCCESS:
            return {
                ...state,
                requestedMedicinesToTake: false,
                receivedMedicinesToTake: true,
                medicines: action.payload
            }

        case TAKE_MEDICINES_SUCCESS:
            return {
                ...state,
                receivedMedicinesToTake: action.payload
            }

        case TAKE_MEDICINES_FAIL:
            return {
                ...state,
                receivedMedicinesToTake: action.payload
            }

        case GET_MEASUREMENTS_TO_MAKE_DATA:
            return {
                ...state,
                requestedMeasurements: true,
                receivedMeasurements: false,
                calendarErrorMsg: ''
            };

        case GET_MEASUREMENTS_TO_MAKE_DATA_FAIL:
            return {
                ...state,
                requestedMeasurements: false,
                receivedMeasurements: false,
                calendarErrorMsg: action.payload
            };

        case GET_MEASUREMENTS_TO_MAKE_DATA_SUCCESS:
            return {
                ...state,
                requestedMeasurements: false,
                receivedMeasurements: true,
                measurements: action.payload
            }

        case CHECK_IS_NEW_PATIENT:
            return {
                ...state,
                requestedPatient: true,
                receivedPatient: false,
                patientErrorMsg: ''
            };

        case CHECK_IS_NEW_PATIENT_FAIL:
            return {
                ...state,
                requestedPatient: false,
                receivedPatient: false,
                patientErrorMsg: action.payload
            };

        case CHECK_IS_NEW_PATIENT_SUCCESS:
            return {
                ...state,
                requestedPatient: false,
                receivedPatient: true,
                patient: action.payload
            }

        default:
            return state;
    }
}