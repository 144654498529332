import { LanguagesState } from "./reducer";
import { Action, Dispatch } from "redux";
import { getSupportedLanguagesSuccess } from "./actions";
import axios from 'axios';
import { ThunkAction } from 'redux-thunk';
import { AuthState } from "../../Auth";

export const thunkGetLanguagesList = (): ThunkAction<void, LanguagesState | AuthState, void, Action<any>> => (dispatch: Dispatch<Action>) => {
    axios.get('languages')
        .then(
            (res) => {
                dispatch(getSupportedLanguagesSuccess(res.data.languages))
            }
        )
        .catch(() => {
        })
}