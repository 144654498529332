import { getExpDateFromLocalStorage } from 'utils/LocalStorage';

function checkAuthState() {
    const expDate = getExpDateFromLocalStorage() || '';
    const todayDate = new Date().getTime();
    const tokenExpDate = new Date(expDate).getTime();
    if (tokenExpDate - todayDate <= 0) {
        return false;
    }
    return true;
}

export default checkAuthState;
