import { LanguagesActionTypes, GET_SUPPORTED_LANGUAGES_SUCCESS } from "./types";

export interface LanguagesState {
    languages: LanguagesList;
}

export interface LanguagesList {
    [langName: string]: Language
}

export interface Language {
    id: number;
    shortcut: string;
}

const initialState: LanguagesState = {
    languages: {}
}

export function languagesReducer(state = initialState, action: LanguagesActionTypes) {
    switch (action.type) {
        case GET_SUPPORTED_LANGUAGES_SUCCESS:
            let LanguagesEntity = {}
            action.payload.map(
                language => {
                    return LanguagesEntity = {
                        ...LanguagesEntity,
                        [language.shortcut]: { ...language }
                    }
                }
            )
            return {
                ...state, languages: LanguagesEntity, getLaguagesAttempt: false
            };
        default:
            return state;
    }
}