import {
    PatientActionTypes,
    GET_PATIENTS_TABLE_DATA,
    GET_PATIENTS_TABLE_DATA_SUCCESS,
    GET_PATIENTS_TABLE_DATA_FAIL,
    GET_DICT_SUCCESS,
    GET_DICT_FAIL,
    GET_PLACES_FAIL,
    GET_PLACES_SUCCESS,
    GET_MEDICINE_SUCCESS,
    GET_MEDICINE_FAIL,
    GET_MEDICINE_IN_STORAGE_SUCCESS,
    GET_MEASUREMENT_PARAMETERS_SUCCESS,
    ADD_NEW_PATAIENT
} from "./types";
import { Fields } from "../patients.types";
import { OptionType } from "components/FormInputs/SelectField/SelectField";
import _ from 'lodash';
import { User } from "views/Measurements/measurements.types";
import newPatient from "../AddPatientForm/PersonalDataForm/Patient.type";


export interface PatientsState {
    patients: any;
    requestSent: boolean;
    answerRecived: boolean;
    patientsData: Fields[];
    filtres: FilterData;
    newPatient: newPatient.ExistingPatient
}

export interface FilterData {
    user_dieseases: OptionType[];
    user_parameters: { label: string, value: string, users: User[] }[];
    user_medicine_devices: OptionType[];
    places: OptionType[];
    medicines: OptionType[];
    medicine_in_storage_users: number[];
}

export interface DefaultFilterField {
    [key: string]: string
}

export interface MeidicinesInStorage {
    [key: string]: any[] | undefined
}



const initialState: PatientsState = {
    newPatient: {
        id: NaN,
        diesease: null,
        medicine_device: null,
    },
    patients: [],
    requestSent: false,
    answerRecived: false,
    patientsData: [],
    filtres: {
        medicine_in_storage_users: [],
        user_dieseases: [],
        user_parameters: [],
        user_medicine_devices: [],
        places: [],
        medicines: []
    },
}

export function patientsReducer(state = initialState, action: PatientActionTypes) {
    let paramsToOptionTypeArr: OptionType[]
    switch (action.type) {
        case GET_PATIENTS_TABLE_DATA:
            return {
                ...state, requestSent: true, answerRecived: true
            };
        case GET_PATIENTS_TABLE_DATA_FAIL:
            return {
                ...state, requestSent: false, answerRecived: false
            };
        case GET_PATIENTS_TABLE_DATA_SUCCESS:
            return {
                ...state, requestSent: false, answerRecived: true, patientsData: action.payload
            };
        case GET_DICT_SUCCESS:

            switch (action.type_of_dict) {
                case 'user_medicine_devices':
                    paramsToOptionTypeArr = _.map(action.payload, (el, id): OptionType => {
                        return { label: el.name, value: id }
                    })
                    state.filtres[action.type_of_dict] = paramsToOptionTypeArr
                    break;
                case 'medicine_in_storage_users':
                    break;
                case 'user_parameters':
                    break;
                default:
                    paramsToOptionTypeArr = _.map(action.payload, (name, id): OptionType => {
                        return { label: name, value: id }
                    })
                    state.filtres[action.type_of_dict] = paramsToOptionTypeArr

            }

            return {
                ...state, requestSent: false, answerRecived: true, filtres: state.filtres
            };
        case GET_DICT_FAIL:
            return {
                ...state, requestSent: false, answerRecived: false
            };
        case GET_PLACES_SUCCESS:
            paramsToOptionTypeArr = _.map(action.payload, (el): OptionType => {
                let label = `${el.name} - ${el.address}`
                return { label: label, value: el.id }
            })
            state.filtres['places'] = paramsToOptionTypeArr
            // console.log('[PLACES]', state.filtres['places'])
            return {
                ...state, requestSent: false, answerRecived: true, filtres: state.filtres
            };
        case GET_PLACES_FAIL:
            return {
                ...state, requestSent: false, answerRecived: false
            };
        case GET_MEDICINE_SUCCESS:
            paramsToOptionTypeArr = _.map(action.payload, (el): OptionType => {
                return { label: el.product_name, value: el.id }
            })
            state.filtres['medicines'] = paramsToOptionTypeArr
            return {
                ...state, requestSent: false, answerRecived: true, filtres: state.filtres
            };
        case GET_MEDICINE_IN_STORAGE_SUCCESS:
            //  console.log('[REDUCC]', action.payload, action.medicineId)

            let mis: number[] = _.uniq(_.flatMap(action.payload, (el) => { return _.map(el.users, (user) => user.user_id) }))
            state.filtres['medicine_in_storage_users'].push(...mis)
            return {
                ...state, requestSent: false, answerRecived: true, filtres: state.filtres
            };
        case GET_MEDICINE_FAIL:
            return {
                ...state, requestSent: false, answerRecived: false
            };
        case GET_MEASUREMENT_PARAMETERS_SUCCESS:
            state.filtres['user_parameters'] = action.payload
            return {
                ...state, requestSent: false, answerRecived: true, filtres: state.filtres
            };

        case ADD_NEW_PATAIENT:
            const newPatient = action.payload
            return {
                ...state, newPatient
            };

        default:
            return state;
    }
}

export const getPatientsData = (state: PatientsState): Fields[] => state.patientsData;
export const getParameters = (state: PatientsState): { label: string, value: string, users: User[] }[] => state.filtres.user_parameters
export const getNewPatientId = (state: PatientsState): number => state.newPatient.id


