export const GET_TEMPLATES_DATA = '[Templates] Get Templates Data';
export const GET_TEMPLATES_DATA_FAIL = '[Templates] Get Templates Data Fail';
export const GET_TEMPLATES_DATA_SUCCESS = '[Templates] Get Templates Data Success';
export const FIND_PARAMETERS_DATA = '[Templates find parameter] Find parameters for Templates Data';
export const FIND_PARAMETERS_DATA_FAIL = '[Templates find parameter] Find parameters for Templates Data Fail';
export const FIND_PARAMETERS_DATA_SUCCESS = '[Templates find parameter] Find parameters for Templates Data Success';
export const ADD_TEMPLATE_FAIL = '[Add Template] Add Template Fail';
export const ADD_TEMPLATE_SUCCESS = '[Add Template] Add Template Success';
interface GetTemplatesData {
    type: typeof GET_TEMPLATES_DATA;
}

interface GetTemplatesDataFail {
    type: typeof GET_TEMPLATES_DATA_FAIL;
    payload: string;
}

interface GetTemplatesDataSuccess {
    type: typeof GET_TEMPLATES_DATA_SUCCESS;
    payload: any;
}

interface FindParametersData {
    type: typeof FIND_PARAMETERS_DATA;
}

interface FindParametersDataFail {
    type: typeof FIND_PARAMETERS_DATA_FAIL;
    payload: string;
}

interface FindParametersDataSuccess {
    type: typeof FIND_PARAMETERS_DATA_SUCCESS;
    payload: any;
}

interface AddTemplateFail {
    type: typeof ADD_TEMPLATE_FAIL;
    payload: string;
}

interface AddTemplateSuccess {
    type: typeof ADD_TEMPLATE_SUCCESS;
    payload: any;
}

export type TemplatesActionTypes =
    GetTemplatesData |
    GetTemplatesDataFail |
    GetTemplatesDataSuccess |
    FindParametersData |
    FindParametersDataFail |
    FindParametersDataSuccess |
    AddTemplateFail |
    AddTemplateSuccess
;
