import React, { ReactElement, Component } from 'react';
import { Button, Theme, createStyles, withStyles } from '@material-ui/core';
import { getRoleFromLocalStorage } from 'utils/LocalStorage';
import RemedizerPulse from '../RemedizerPulse/RemedizerPulse';

interface IProps {
    type: "button" | "submit" | "reset" | undefined;
    children: string | ReactElement;
    variant: "text" | "contained" | "outlined" | undefined;
    onClick?: Function;
    disabled?: boolean;
    isLoading?: boolean;
    fullWidth?: boolean;
    customClasses?: object;
    customClassName?: string;
    porps?: any;
    href?: string;
    classes: {
        remedizerPulse: string;
    }
}

const styles = (theme: Theme) => createStyles({
    remedizerPulse: {
        marginRight: '0.25rem'
    }
})

const NormalButton = (Button);
const PatientButton = (Button);

class RemedizerButton extends Component<IProps> {
    render() {
        const isPatient = getRoleFromLocalStorage() === 'false' && getRoleFromLocalStorage() !== null ? true : false

        const { classes, children, type, variant, onClick, disabled, isLoading, fullWidth, customClasses, customClassName, ...props } = this.props;

        if (isPatient) {
            return (
                <PatientButton
                    key={children.toString()}
                    type={type}
                    variant={variant}
                    disabled={(type === "submit" && disabled) || isLoading}
                    onClick={onClick ? (event) => onClick(event) : () => null}
                    fullWidth={fullWidth && fullWidth}
                    classes={customClasses && customClasses}
                    className={customClassName && customClassName}
                    {...props}
                >
                    {isLoading && type === "submit" &&
                        <RemedizerPulse width="25px" height="25px" viewBox={"0 0 108.5 109.333"} style={{ width: 25, height: 25 }} className={classes.remedizerPulse} />
                    }
                    {
                        children
                    }
                </PatientButton>
            )
        }
        return (
            <NormalButton
                key={children.toString()}
                type={type}
                variant={variant}
                disabled={(type === "submit" && disabled) || isLoading}
                onClick={onClick ? (event) => onClick(event) : () => null}
                fullWidth={fullWidth && fullWidth}
                classes={customClasses && customClasses}
                className={customClassName && customClassName}
                {...props}
            >
                {isLoading && type === "submit" &&
                    <RemedizerPulse width="25px" height="25px" viewBox={"0 0 108.5 109.333"} style={{ width: 25, height: 25 }} className={classes.remedizerPulse} />
                }
                {children}
            </NormalButton>
        );
    }
}

export default withStyles(styles)(RemedizerButton);