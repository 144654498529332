
export const getTokenFromLocalStorage = () => {
    const token = localStorage.getItem('token');
    return token;
} 

export const getExpDateFromLocalStorage = () => {
    const expDate = localStorage.getItem('expires');
    return expDate;
}

export const getRoleFromLocalStorage = () => {
    const role = localStorage.getItem('role');
    return role;
}

export const getUserIdFromLocalStorage = () => {
    const userId = localStorage.getItem('userId');
    return userId;
}

export const getLanguageNameFromLocalStorage = () => {
    const languageId = localStorage.getItem('languageName');
    return languageId;
}

export const setInLocalStorage = (name: string, value: any) => localStorage.setItem(name, value);
