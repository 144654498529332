import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { DoctorDashboardState, DoctorDashboardCalendarState } from './reducer';
import {
    getAlertDataSuccess,
    getAlertDataFail,
    getAlertMeasurementChartSuccess,
    getAlertMeasurementChartFail,
    approveAlertSuccess,
    approveAlertFail,
    getPatientPlacesDataSuccess,
    getPatientPlacesDataFail,
    createConsultationSuccess,
    createConsultationFail,
    getMeasurementDoctorListDataSuccess,
    approveMeasurementSuccess,
    approveMeasurementFail,
    approveAllMeasurementSuccess,
    approveAllMeasurementFail,
    getConsultationsDoctorDataSuccess,
    getConsultationsDoctorDataFail,
    editConsultationFail,
    editConsultationSuccess,
    completeAllConsultationsFail,
    completeAllConsultationsSuccess,
    getCalednarDoctorDataSuccess,
    getCalednarDoctorDataFail,
    getNotificationsDoctorDataSuccess,
    getNotificationsDoctorDataFail,
    changeNotificationStatusDoctorSuccess,
    changeNotificationStatusDoctorFail
} from './actions';
import { AuthState } from 'Auth/store/reducer';
import loggedInInstance from 'axiosInstances/loggedIn.instance';
import {
    AlertsRequestData, AlertMeasurementRequestData, GetPatientPlacesRequestData, MeasurementDoctorListRequestData, ConsultationDoctorListRequestData
} from '../DoctorDashboardTypes';
import { PhoneConsultation, VisitConsultation } from '../Consultation/Consultation';
import { CalendarObject } from 'views/Calendar/CalendarTypes';
import { I18n } from 'react-redux-i18n';
import { NotificationsRequestData } from 'views/Dashboard/Patient/PatientDashboardTypes';

export const thunkGetAlerts = (user_id?: number, start_date?: string, end_date?: string): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<AlertsRequestData> = await loggedInInstance.get(`alert`, {
                params: {
                    user_id,
                    start_date,
                    end_date
                }
            });
            dispatch(getAlertDataSuccess(data.alerts));
        } catch (error) {
            dispatch(getAlertDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetAlertMeasurementChartDetail = (id: string, parameterId: string, userId: number, limit: number): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<AlertMeasurementRequestData> = await loggedInInstance.get(`measurement/chart/${parameterId}`, {
                params: {
                    user_id: userId,
                    limit: limit
                }
            });
            dispatch(getAlertMeasurementChartSuccess(id, data.data));
        } catch (error) {
            dispatch(getAlertMeasurementChartFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkApproveAlert = (id: string): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.put(`alert/approve/${id}`);
            dispatch(approveAlertSuccess(true));
        } catch (error) {
            dispatch(approveAlertFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetPatientPlaces = (userId: number): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<GetPatientPlacesRequestData> = await loggedInInstance.get(`place/user/${userId}`, {
                params: {
                    type: 3
                }
            });
            dispatch(getPatientPlacesDataSuccess(data.places));
        } catch (error) {
            dispatch(getPatientPlacesDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkCreateConsultation = (values: PhoneConsultation | VisitConsultation): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.post(`event/create`, {
                ...values
            });
            dispatch(createConsultationSuccess(true));
        } catch (error) {
            dispatch(createConsultationFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetMeasurementDoctorList = (start_date: string, end_date: string, user_id?: number): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<MeasurementDoctorListRequestData> = await loggedInInstance.get(`measurement/doctor-list`, {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    user_id
                }
            });
            dispatch(getMeasurementDoctorListDataSuccess(data.data));
        } catch (error) {
            dispatch(getAlertMeasurementChartFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkApproveMeasurement = (measurementId: string): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.patch(`measurement/making/approve/${measurementId}`);
            dispatch(approveMeasurementSuccess(true));
        } catch (error) {
            dispatch(approveMeasurementFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkApproveAllMeasurements = (measurementIds: string[]): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.patch(`measurement/making/approve-many`, {
                measurementIds
            });
            dispatch(approveAllMeasurementSuccess(true));
        } catch (error) {
            dispatch(approveAllMeasurementFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetDoctorConsultationsList = (start_date: string, end_date: string, user_id?: number): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<ConsultationDoctorListRequestData> = await loggedInInstance.get(`/event/consultations`, {
                params: {
                    start: start_date,
                    end: end_date,
                    user_id
                }
            });
            dispatch(getConsultationsDoctorDataSuccess(data.data));
        } catch (error) {
            dispatch(getConsultationsDoctorDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkEditConsultation = (eventId: string, date?: string, done?: boolean): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.patch(`/event/consultations`, {
                event_id: eventId,
                date: date,
                done: done ? 1 : 0
            });
            dispatch(editConsultationSuccess(true));
        } catch (error) {
            dispatch(editConsultationFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkCompleteALlConsultations = (eventIds: string[], date: string): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.patch(`/event/complete-consultations`, {
                date: date,
                eventIds
            });
            dispatch(completeAllConsultationsSuccess(true));
            return Promise.resolve(false)
        } catch (error) {
            dispatch(completeAllConsultationsFail(error.message));
            return Promise.reject(false)
        }
    }

export const thunkGetCalendarDoctorList = (start_date: string, end_date: string): ThunkAction<Promise<boolean>, DoctorDashboardCalendarState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<ConsultationDoctorListRequestData> = await loggedInInstance.get(`/event/consultations`, {
                params: {
                    start: start_date,
                    end: end_date
                }
            });

            data.data.map((element: CalendarObject, ind: number) => {
                let title = '';
                if (element.details.user) {
                    if (element.details.type === 'visit' && element.details.place) {
                        title = `${I18n.t('dashboard.consultation.visit')}: ${element.details.user.name} ${element.details.user.surname}, ${element.details.place.address}`
                    } else if (element.details.type === 'consultation') {
                        title = `${I18n.t('dashboard.consultation.consultation')}: ${element.details.user.name} ${element.details.user.surname}, ${element.details.user.phone}`
                    }
                }
                element.title = title;
                return element;
            });

            dispatch(getCalednarDoctorDataSuccess(data.data));
        } catch (error) {
            dispatch(getCalednarDoctorDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkGetNotifications = (start: string, end: string, limit: number, userId: number): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<NotificationsRequestData> = await loggedInInstance.get(`notifications`, {
                params: {
                    start_date: start,
                    end_date: end,
                    limit: limit,
                    user_id: userId
                }
            });
            dispatch(getNotificationsDoctorDataSuccess(data.notifications));
        } catch (error) {
            dispatch(getNotificationsDoctorDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkChangeNotificationStatus = (notification: number, status: number): ThunkAction<Promise<boolean>, DoctorDashboardState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.post(`notification/user/change-status`, {
                notification: notification,
                status: status
            });
            dispatch(changeNotificationStatusDoctorSuccess(true));
        } catch (error) {
            dispatch(changeNotificationStatusDoctorFail(error.message));
        }
        return Promise.resolve(false)
    }