import {
    IdNameType,
    normalizedParameterPropositions,
    AddParameterObject,
    normalizedMeasurements,
    NormalizedParametersEntities,
    AddMeasurement,
    GraphsSection,
    PdfGenerateSection,
    GraphDetails
} from "../measurements.types";
import { NormalizedSchema } from "normalizr";

export const GET_PARAMETERS_TABLE_DATA_SUCCESS = '[Measurements] Get Measurements Table Data Success';

export const GET_PARAMETER_PROPOSITIONS_SUCCESS = '[Measurements] Get Parameters propositions success';

export const GET_UNITS_PROPOSITIONS_SUCCESS = '[Measurement] Get Units Propositions success';

export const GET_INTERVAL_UNITS_SUCCESS = '[Measurement] Get Interval Units Propositions success';

export const GET_MEASUREMENT_CALCULATION_TYPES_SUCCESS = '[Measurement] Get Measurement calculation types success';

export const GET_MEASUREMENT_PARAMETER_FIELD_TYPES_SUCCESS = '[Measurement] Get Measurement parameter field types succsess';

export const GET_MEASUREMENT_PARAMETER_FIELDS_PROPOSITIONS_SUCCESS = '[Measurement] Get Measurement parameter fields propositions success';

export const ADD_NEW_PARAMETER_SUCCESS = '[Measurement] Add new parmeter success';

export const ADD_EXISTING_PARAMETER_TO_USER_SUCCESS = '[Measurement] Add existing parameter to user success';

export const GET_MEASUREMENTS_TABLE_DATA_SUCCESS = '[Measurement] Get Measurement Table Data Success';

export const GET_MEASUREMENT_ADD_PARAMETERS_LIST_SUCCESS = '[Measurement] Get Measurement Add Parameters List Success';

export const GET_CAREGIVER_CHILDREN_SUCCESS = '[Mesurement] Get Caregiver Children Success';

export const GET_PARAMETER_FIELDS_FOR_MEASUREMENT_SUCCESS = '[Mesurement] Get Parameter Fields For Measurment Success';
export const GET_PARAMETER_USERS_FOR_MEASUREMENT_SUCCESS = '[Mesurement] Get Parameter Users For Measurment Success';

export const SET_SELECTED_MEASUREMENT = '[Measurement] Set Selected Measurement';

export const GET_LAST_TEN_MEASUREMENTS = '[Measurement] Get last 10 measurements';

export const GET_AVIABLE_COLORS_SUCCESS = '[Measurement] Get aviable graph colors';

export const GET_GRAPHS_DATA_SUCCESS = '[Measurement] Get graphs data success';

export const GET_PDF_MAIN_PERSONAL_DATA_SUCCESS = '[PDF] Get main personal data';
export const GET_GRAPH_DETAILS_SUCCESS = '[Measurement] Get graph details success';

export const DETAILS_VIEW_SWITCH = '[Measurement] Details view switch';

export const SET_GRAPH_DETAILS_PARAMETER_ID = '[Measurement] Set Graph Details ParameterId';

export const GET_PDF_USER_PARAMETER_DATA_SUCCESS = '[PDF] Get user parameter data';



interface GetParameterPropositionsSuccess {
    type: typeof GET_PARAMETER_PROPOSITIONS_SUCCESS;
    payload: normalizedParameterPropositions.ParameterPropositionsNormalizedData;
}

interface GetUnitsPropositionsSuccess {
    type: typeof GET_UNITS_PROPOSITIONS_SUCCESS
    payload: IdNameType[]
}

interface GetIntervalUnitsPropositionsSuccess {
    type: typeof GET_INTERVAL_UNITS_SUCCESS;
    payload: IdNameType[];
}

interface GetMeasurementsCalculationTypesSuccess {
    type: typeof GET_MEASUREMENT_CALCULATION_TYPES_SUCCESS;
    payload: IdNameType[];
}

interface GetMeasurementParameterFieldTypesSuccess {
    type: typeof GET_MEASUREMENT_PARAMETER_FIELD_TYPES_SUCCESS;
    payload: IdNameType[];
}

interface GetMeasurementParameterFieldPropositionsSuccess {
    type: typeof GET_MEASUREMENT_PARAMETER_FIELDS_PROPOSITIONS_SUCCESS;
    payload: any;
}

interface AddNewParameterSuccess {
    type: typeof ADD_NEW_PARAMETER_SUCCESS;
    payload: AddParameterObject.NewParameterObject;
}

interface AddExistingParameterToUserSuccess {
    type: typeof ADD_EXISTING_PARAMETER_TO_USER_SUCCESS;
    payload: AddParameterObject.ExistingParameterObject
}

interface GetMeasurementsTableDataSuccess {
    type: typeof GET_MEASUREMENTS_TABLE_DATA_SUCCESS;
    payload: NormalizedSchema<normalizedMeasurements.MeasurementEntities, string[]>;
}

interface GetParametersTableDataSuccess {
    type: typeof GET_PARAMETERS_TABLE_DATA_SUCCESS;
    payload: NormalizedSchema<NormalizedParametersEntities, string[]>;
}

interface GetMeasurementAddParametersListSuccess {
    type: typeof GET_MEASUREMENT_ADD_PARAMETERS_LIST_SUCCESS
    payload: any
}

interface GetCaregiverChildren {
    type: typeof GET_CAREGIVER_CHILDREN_SUCCESS,
    payload: AddParameterObject.AddParamAviableUsers[]
}

interface GetParameterFieldsForMeasurementSuccess {
    type: typeof GET_PARAMETER_FIELDS_FOR_MEASUREMENT_SUCCESS,
    payload: AddMeasurement.MeasurementParameterField[],
}

interface GetParameterUsersForMeasurementSuccess {
    type: typeof GET_PARAMETER_USERS_FOR_MEASUREMENT_SUCCESS;
    payload: IdNameType[];
}

interface SetSelectedMeasurement {
    type: typeof SET_SELECTED_MEASUREMENT;
    payload: IdNameType;
}

interface GetLastTenMeasurements {
    type: typeof GET_LAST_TEN_MEASUREMENTS;
    payload: AddMeasurement.RecentMeasurement
}
interface GetAvibleColorsSuccess {
    type: typeof GET_AVIABLE_COLORS_SUCCESS;
    payload: string[];
}

interface GetGraphsDataSuccess {
    type: typeof GET_GRAPHS_DATA_SUCCESS;
    payload: GraphsSection.Parameter[]
}

interface GetPdfMainPersonalDataSuccess {
    type: typeof GET_PDF_MAIN_PERSONAL_DATA_SUCCESS;
    payload: PdfGenerateSection.MainPersonalData
}

interface GetPdfUserParameterDataSuccess {
    type: typeof GET_PDF_USER_PARAMETER_DATA_SUCCESS;
    payload: PdfGenerateSection.UserMeasurement[]
}


interface GetGraphDetailsSuccess {
    type: typeof GET_GRAPH_DETAILS_SUCCESS;
    payload: GraphDetails.GraphDetailsData
}

interface DetailsViewSwitch {
    type: typeof DETAILS_VIEW_SWITCH;
    payload: boolean
}

interface SetGraphDetailsParameterId {
    type: typeof SET_GRAPH_DETAILS_PARAMETER_ID;
    payload: string;
}

export type MeasurementsActionTypes =
    GetMeasurementsTableDataSuccess |
    GetParametersTableDataSuccess |
    GetParameterPropositionsSuccess |
    GetUnitsPropositionsSuccess |
    GetIntervalUnitsPropositionsSuccess |
    GetMeasurementsCalculationTypesSuccess |
    GetMeasurementParameterFieldTypesSuccess |
    GetMeasurementParameterFieldPropositionsSuccess |
    AddNewParameterSuccess |
    AddExistingParameterToUserSuccess |
    GetMeasurementAddParametersListSuccess |
    GetCaregiverChildren |
    GetParameterFieldsForMeasurementSuccess |
    GetParameterUsersForMeasurementSuccess |
    SetSelectedMeasurement |
    GetLastTenMeasurements |
    GetAvibleColorsSuccess |
    GetGraphsDataSuccess |
    GetPdfMainPersonalDataSuccess |
    GetAvibleColorsSuccess |
    GetGraphsDataSuccess |
    DetailsViewSwitch |
    GetGraphDetailsSuccess |
    SetGraphDetailsParameterId|
    GetPdfUserParameterDataSuccess
    ;