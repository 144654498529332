import {
    GET_TEMPLATES_DATA,
    GET_TEMPLATES_DATA_FAIL,
    GET_TEMPLATES_DATA_SUCCESS,
    FIND_PARAMETERS_DATA,
    FIND_PARAMETERS_DATA_FAIL,
    FIND_PARAMETERS_DATA_SUCCESS,
    ADD_TEMPLATE_FAIL,
    ADD_TEMPLATE_SUCCESS
} from './types';
import { Template } from '../TemplatesTypes';
import { Parameter } from 'views/Measurements/measurements.types';

export function getTemplatesData() {
    return {
        type: GET_TEMPLATES_DATA
    }
}

export function getTemplatesDataSuccess(templates: Template[]) {
    return {
        type: GET_TEMPLATES_DATA_SUCCESS,
        payload: templates
    }
}

export function getTemplatesDataFail(errorMsg: string) {
    return {
        type: GET_TEMPLATES_DATA_FAIL,
    }
}

export function findParametersData() {
    return {
        type: FIND_PARAMETERS_DATA
    }
}

export function findParametersDataSuccess(parameters: Parameter[]) {
    return {
        type: FIND_PARAMETERS_DATA_SUCCESS,
        payload: parameters
    }
}

export function findParametersDataFail(errorMsg: string) {
    return {
        type: FIND_PARAMETERS_DATA_FAIL,
    }
}

export function addTemplateSuccess(result: boolean) {
    return {
        type: ADD_TEMPLATE_SUCCESS,
        payload: result
    }
}

export function addTemplateFail(errorMsg: string) {
    return {
        type: ADD_TEMPLATE_FAIL,
    }
}