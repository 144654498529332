import {
    CalendarActionTypes,
    GET_CALENDAR_DATA,
    GET_CALENDAR_DATA_FAIL,
    GET_CALENDAR_DATA_SUCCESS,
    EXPORT_CALENDAR_SUCCESS,
    EXPORT_CALENDAR_FAIL,
    ADD_CALENDAR_EVENT_SUCCESS,
    ADD_CALENDAR_EVENT_FAIL,
} from "./types";
import { CalendarObject } from '../CalendarTypes';

export interface CalendarState {
    receivedCalendar: boolean,
    calendarData: CalendarObject[] | undefined,
    calendarUrl: string | undefined,
    addCalendarEventResult: boolean
}

const initialState: CalendarState = {
    receivedCalendar: false,
    calendarData: [],
    calendarUrl: '',
    addCalendarEventResult: false,
}

export function calendarReducer(state = initialState, action: CalendarActionTypes) {
    switch (action.type) {

        case GET_CALENDAR_DATA:
            return {
                ...state,
                requestedCalendar: true,
                receivedCalendar: false,
                calendarErrorMsg: ''
            };

        case GET_CALENDAR_DATA_FAIL:
            return {
                ...state,
                requestedCalendar: false,
                receivedCalendar: false,
                calendarErrorMsg: action.payload
            };

        case GET_CALENDAR_DATA_SUCCESS:
            return {
                ...state,
                requestedCalendar: false,
                receivedCalendar: true,
                calendarData: action.payload
            }

        case EXPORT_CALENDAR_FAIL:
            return {
                ...state,
                requestedCalendar: false,
                receivedCalendar: false,
                calendarErrorMsg: action.payload
            };

        case EXPORT_CALENDAR_SUCCESS:
            return {
                ...state,
                requestedCalendar: false,
                receivedCalendar: true,
                calendarUrl: action.payload
            }

        case ADD_CALENDAR_EVENT_SUCCESS:
            return {
                ...state,
                addCalendarEventResult: action.payload
            }

        case ADD_CALENDAR_EVENT_FAIL:
            return {
                ...state,
                addCalendarEventResult: action.payload
            }

        default:
            return state;
    }
}