import React, { Suspense, lazy } from 'react';
import { Action } from 'redux';
import Layout from 'components/Layout/Layout';
import NoPage from 'views/404/404';
import ConfirmAccount from 'views/ConfirmAccount/ConfirmAccount';
import { connect } from 'react-redux';
import { thunkCheckIfTokenIsValid } from './Auth/store/thunks';
import { ThunkDispatch } from 'redux-thunk';
import { AuthState } from './Auth/store/reducer';

import { getTokenFromLocalStorage, getRoleFromLocalStorage, getUserIdFromLocalStorage, getLanguageNameFromLocalStorage } from 'utils/LocalStorage';
import { loginUserSuccess, logoutUser } from './Auth/store/actions';

import { loadTranslations, setLocale } from 'react-redux-i18n';
import dictionaries from './i18n/index';
import getBrowserLanguage from 'utils/BrowserLanguage';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppState } from '.';
import RemedizerPulse from './components/FallbackRoute/FallbackRoute';
import { LanguagesList, thunkGetLanguagesList } from './Languages';

import CustomizedSnackbar from './components/MaterialUiThemes/SnackbarTheme';
import checkAuthState from './axiosInstances/checkAuthState';
import { ThemeProvider } from '@material-ui/styles';
import appTheme from './components/MaterialUiThemes/AppTheme';
import ResetPassword from 'views/ResetPassword/ResetPassword';

import 'components/ModalLayout/Modal.css';

interface Props {
	getLanguages: Function;
	logedStill: Function;
	setTranslationLanguage: Function;
	setLocale: Function;
	logout: Function;
	languages: LanguagesList;
	loggedIn: boolean;
	role: boolean;
	appLanguage: string;
	collectError: string;
	collectSuccess: string;
	checkAuthState: Function;
	storeToken: string;
	collectCode: number;
}

const StartPage = (
	lazy(() => (
		import('views/StartPage/StartPage')
	))
)

const Main = (
	lazy(() => (
		import('views/Main/Main')
	))
)


class App extends React.PureComponent<Props> {

	componentDidMount() {
		this.props.getLanguages();
		this.props.setTranslationLanguage(dictionaries);
		const localLanguage = getLanguageNameFromLocalStorage();
		if (!this.props.appLanguage && !localLanguage) {
			this.props.setLocale(getBrowserLanguage());
		} else if (!this.props.appLanguage && localLanguage) {
			this.props.setLocale(localLanguage);
		}
	}

	componentDidUpdate(prevProps: Props) {
		const token = getTokenFromLocalStorage();
		const role = getRoleFromLocalStorage() === 'true' ? true : false;
		const userId = getUserIdFromLocalStorage();
		if (token && userId && !this.props.storeToken) {
			this.checkAppAuthState(token, role, userId);
		}
	}

	checkAppAuthState(token: string, role: boolean, userId: string) {
		checkAuthState() ? this.props.logedStill(token, role, userId) : this.props.checkAuthState();
	}

	render() {

		return (
			<ThemeProvider theme={appTheme(this.props.loggedIn)}>
				<Layout role={this.props.role} loggedIn={this.props.loggedIn} >
					<BrowserRouter>
						<Suspense fallback={<RemedizerPulse />}>
							<Switch>
								<Route path="/" exact component={StartPage} />
								<Route path="/patient" component={Main} />
								<Route path="/doctor" component={Main} />
								<Route exact path="/register/confirm" component={ConfirmAccount} />
								<Route exact path="/password/reset/confirm" component={ResetPassword} />
								<Route component={NoPage} />
							</Switch>
						</Suspense>
						<CustomizedSnackbar
							error={this.props.collectError}
							success={this.props.collectSuccess}
							code={this.props.collectCode} />
					</BrowserRouter>
				</Layout>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state: AppState) => {
	return {
		languages: state.languages.languages,
		loggedIn: state.auth.loggedIn,
		role: state.auth.isDoctor,
		appLanguage: state.i18n.locale,
		collectError: state.ax.error,
		collectSuccess: state.ax.success,
		collectCode: state.ax.code,
		storeToken: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AuthState, void, Action<any>>) => ({
	checkAuthState: () => dispatch(thunkCheckIfTokenIsValid('200')),
	getLanguages: () => dispatch(thunkGetLanguagesList()),
	logedStill: (token: string, role: boolean, userId: number) => dispatch(loginUserSuccess(token, role, userId)),
	logout: () => dispatch(logoutUser()),
	setTranslationLanguage: () => dispatch(loadTranslations(dictionaries)),
	setLocale: (lang: string) => dispatch(setLocale(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
