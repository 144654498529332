import { AxActionTypes, SET_ERROR, SET_SUCCESS, NEW_REQUEST_SENT } from "./types";

export interface AxState {
    error: string;
    success: string;
    code: number;
}

const initialState: AxState = {
    error: '',
    success: '',
    code: NaN,
}

export function axReducer(state = initialState, action: AxActionTypes) {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state, error: action.payload, success: '', code: action.code
            };
        case SET_SUCCESS:
            return {
                ...state, error: '', success: action.payload, code: action.code
            };
        case NEW_REQUEST_SENT:
            return {
                ...state, error: '', success: '', code: NaN
            }
        default:
            return state
    }
}