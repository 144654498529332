import * as React from 'react';
import ResetFormValidationSchema from './ResetFormValidationSchema';
import { FormikActions, Formik, Form, Field } from 'formik';
import { AuthState, thunkResetPasswordConfirm } from 'Auth';
import { Box, Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
import { I18n, Translate } from 'react-redux-i18n';
import FormikTextField from 'components/FormInputs/FormikTextField';
import RemedizerButton from 'components/RemedizerButton';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter, RouteComponentProps } from 'react-router';

interface ResetPasswordFormProps extends RouteComponentProps {
    resetPassword: (plainPassword: {
        first: string;
        second: string;
    }, token: string) => Promise<boolean>;
    token: string | undefined;
}

export interface InitialFormikValues {
    first: string;
    second: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    textOverflow: {
        textOverflow: 'ellipsis',
        maxWidth: '100%'
    },
    button: {
        width: '100%',
    },
    link: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        color: theme.palette.text.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.secondary
        }
    }
}))

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ resetPassword, token, history }) => {
    const classes = useStyles();
    const [newRequestStatus, setRequestStatus] = React.useState<boolean>(false);

    let _isMounted: React.MutableRefObject<boolean> = React.useRef(false)

    const initialValues: InitialFormikValues = {
        first: '',
        second: '',
    }

    function handleSubmit(values: InitialFormikValues, actions: FormikActions<InitialFormikValues>) {
        setRequestStatus(!newRequestStatus);
        const plainPassword = {
            first: values.first,
            second: values.second,
        };
        resetPassword(plainPassword, token || '')
            .then(() => {
                actions.setSubmitting(false);
                _isMounted.current && setRequestStatus(!newRequestStatus);
                return history.push('/');
            })
            .catch(() => {
                actions.setSubmitting(false);
                return _isMounted.current && setRequestStatus(!newRequestStatus);

            })
    }

    return (
        <Formik
            validationSchema={ResetFormValidationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            render={({ errors, status, touched, isSubmitting, values, setFieldValue, handleChange, isValid }) =>
                <Form>
                    <Box minHeight="100%" paddingTop="25px" bgcolor="white" >
                        <Grid container justify="center">
                            <Box width="100%" minHeight="200px" padding="5px">
                                <Grid container justify="center">
                                    <Grid item xs={12} md={8}>
                                        <Box component="h4">
                                            <Translate value='loginForm.setNewPassword' />
                                        </Box>
                                        <Box padding="16px 0">
                                            <Field
                                                translateProps={{ min: 6 }}
                                                label={`${I18n.t('modal.uniFields.new_password')}`}
                                                name={'first'}
                                                type={'password'}
                                                component={FormikTextField}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Box padding="16px 0">
                                            <Field
                                                translateProps={{ min: 6 }}
                                                label={`${I18n.t('loginForm.rePassword')}`}
                                                name={'second'}
                                                type={'password'}
                                                component={FormikTextField}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Box
                            marginTop="112px"
                            paddingBottom="60px"
                        >
                            <Grid container justify="center" >
                                <Grid item xs={8}>
                                    <Grid container justify="center">
                                        <Grid item xs={6}>
                                            <RemedizerButton
                                                isLoading={isSubmitting}
                                                disabled={!isValid}
                                                type="submit"
                                                variant="contained"
                                                customClassName={classes.button}
                                            >
                                                <Translate value='loginForm.submit' />
                                            </RemedizerButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Form>
            }
        />
    );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AuthState, void, Action<any>>) => ({
    resetPassword: (plainPassword: { first: string, second: string }, token: string): Promise<boolean> => dispatch(thunkResetPasswordConfirm(plainPassword, token)),
});

export default withRouter(connect(null, mapDispatchToProps)(ResetPasswordForm));