import { UserDataStateTypes, ActiveParameterType } from "views/Account/account.types";

export const GET_USER_DATA_PROFILE = '[User data profile] Get user data profile';
export const GET_USER_DATA_PROFILE_FAIL = '[User data profile] Get user data profile Fail';
export const GET_USER_DATA_PROFILE_SUCCESS = '[User data profile] Get user data profile Success';

export const USER_UPDATE_LANGUAGE_SUCCESS = '[User data] Update App Language Success';
export const USER_UPDATE_LANGUAGE_FAIL = '[User data] Update App Language Fail';
export const USER_UPDATE_ACCOUNT_DATA_PARTIAL = '[User data] Upadte Part of Account Data';
export const USER_UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS = '[User data] Upadte Part of Account Data Success'
export const USER_UPDATE_ACCOUNT_DATA_PARTIAL_FAIL = '[User data] Upadte Part of Account Data Fail'
export const USER_SET_LOCATION_FIELD = '[User data] Set location field';
export const USER_SET_LOCATION_FIELD_SUCCESS = '[User data] Set location field Success';
export const USER_SET_LOCATION_FIELD_FAIL = '[User data] Set location field Fail';
export const USER_CREATE_LOCATION_FIELD = '[User data] Create locations'
export const USER_CREATE_LOCATION_FIELD_SUCCESS = '[User data] Create location Success'
export const USER_CREATE_LOCATION_FIELD_FAIL = '[User data] Create location Fail'
export const USER_UPLOAD_AVATAR_SUCCESS = '[User data] Upload Avatar Success';
export const USER_UPLOAD_AVATAR_FAIL = '[User data] Upload Avatar Fail';
export const USER_ACTIVE_PARAMETER_SUCCESS = '[User data] Active parameter Success';
export const USER_ACTIVE_PARAMETER_FAIL = '[User data] Active parameter Fail';
interface GetUserDataProfile {
    type: typeof GET_USER_DATA_PROFILE;
}

interface GetUserDataProfileFail {
    type: typeof GET_USER_DATA_PROFILE_FAIL;
    payload: string;
}

interface GetUserDataProfileSuccess {
    type: typeof GET_USER_DATA_PROFILE_SUCCESS;
    payload: any;
}

interface UserDataUpdateLanguageSuccess {
    type: typeof USER_UPDATE_LANGUAGE_SUCCESS;
    payload: number;
}

interface UserDataUpdateLanguageFail {
    type: typeof USER_UPDATE_LANGUAGE_FAIL;
    payload: string;
}

interface UserDataUpdateAccountPartialData {
    type: typeof USER_UPDATE_ACCOUNT_DATA_PARTIAL;
}

interface UserDataUpdateAccountDataPartialFail {
    type: typeof USER_UPDATE_ACCOUNT_DATA_PARTIAL_FAIL;
    payload: string;
}

interface UserDataUpdateAccountDataPartialSuccess {
    type: typeof USER_UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS;
    payload: UserDataStateTypes;
}

interface UserDataSetLocationField {
    type: typeof USER_SET_LOCATION_FIELD;
}

interface UserDataSetLocatonFieldSuccess {
    type: typeof USER_SET_LOCATION_FIELD_SUCCESS;
    payload: UserDataStateTypes;
}

interface UserDataSetLocatonFieldFail {
    type: typeof USER_SET_LOCATION_FIELD_FAIL;
    payload: string;
}

interface UserDataCreateLocationField {
    type: typeof USER_CREATE_LOCATION_FIELD;
}

interface UserDataCreateLocationFieldSuccess {
    type: typeof USER_CREATE_LOCATION_FIELD_SUCCESS;
    payload: UserDataStateTypes;
}

interface UserDataCreateLocationFieldFail {
    type: typeof USER_CREATE_LOCATION_FIELD_FAIL;
    payload: string;
}

interface UserDataUploadAvatarSuccess {
    type: typeof USER_UPLOAD_AVATAR_SUCCESS;
}

interface UserDataUploadAvatarFail {
    type: typeof USER_UPLOAD_AVATAR_FAIL;
    payload: string;
}

interface UserActiveParameterSuccess {
    type: typeof USER_ACTIVE_PARAMETER_SUCCESS;
    payload: ActiveParameterType;
}

interface UserActiveParameterFail {
    type: typeof USER_ACTIVE_PARAMETER_FAIL;
    payload: string;
}

export type UserDataActionTypes =
    GetUserDataProfile |
    GetUserDataProfileFail |
    GetUserDataProfileSuccess |
    UserDataUpdateLanguageSuccess |
    UserDataUpdateLanguageFail |
    UserDataUpdateAccountPartialData |
    UserDataUpdateAccountDataPartialSuccess |
    UserDataUpdateAccountDataPartialFail |
    UserDataSetLocationField |
    UserDataSetLocatonFieldSuccess |
    UserDataSetLocatonFieldFail |
    UserDataCreateLocationField |
    UserDataCreateLocationFieldSuccess |
    UserDataCreateLocationFieldFail |
    UserDataUploadAvatarSuccess |
    UserDataUploadAvatarFail |
    UserActiveParameterSuccess |
    UserActiveParameterFail
    ;
