import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { UserDataState } from './reducer';
import {
    getUserDataProfileSuccess,
    getUserDataProfileFail,
    updateLanguageSuccess,
    updateLanguageFail,
    updateAccountPartialData,
    updateAccountPartialDataSuccess,
    updateAccountPartialDataFail,
    setLocationField,
    setLocationFieldSuccess,
    setLocationFieldFail,
    createLocationField,
    createLocationFieldSuccess,
    createLocationFieldFail,
    uploadAvatarSuccess,
    uploadAvatarFail,
    activeParameterSuccess,
    activeParameterFail
} from './actions';
import { AuthState } from 'Auth/store/reducer';
import { Language } from 'Languages';
import loggedInInstance from 'axiosInstances/loggedIn.instance';

import { UserDataProfileRequestData } from '../UserDataTypes';
import { UserDataStateTypes, AC_PlaceLocation } from 'views/Account/account.types';

export const thunkGetProfile = (userId: number): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<UserDataProfileRequestData> = await loggedInInstance.get(`profile/${userId}`);
            dispatch(getUserDataProfileSuccess(data.user));
        } catch (error) {
            dispatch(getUserDataProfileFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkUpdateLanguage = (language: Language, userId: number): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.post(`profile/change-language`, { language_id: language.id, user_id: userId });

            dispatch(updateLanguageSuccess(language.id));
        } catch (error) {
            dispatch(updateLanguageFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkUpdatePartialData = (dataPartial: UserDataStateTypes, partial: { [key: string]: string }, userId: number): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        dispatch(updateAccountPartialData());
        try {
            await loggedInInstance.patch(`profile/${userId}`, { parameters: partial });
            dispatch(updateAccountPartialDataSuccess(dataPartial));
        } catch (error) {
            dispatch(updateAccountPartialDataFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkPatchLocationData = (dataPatch: AC_PlaceLocation, partial: { [key: string]: string }): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        dispatch(setLocationField());
        const placeId = dataPatch.id;
        try {
            await loggedInInstance.patch(`place/${placeId}`, { ...partial });

            dispatch(setLocationFieldSuccess(dataPatch));
        } catch (error) {
            dispatch(setLocationFieldFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkCreateLocationData = (dataCreate: AC_PlaceLocation, partial: { [key: string]: string }, userId: number): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        dispatch(createLocationField());
        try {
            let { data }: AxiosResponse = await loggedInInstance.post(`place/create`, partial);
            const placeId = data.placeId;
            const updateddata = {
                ...dataCreate, id: placeId
            }
            const subForm = {
                user: userId,
                place: placeId
            }

            try {
                await loggedInInstance.post(`place/user/create`, subForm);
                dispatch(createLocationFieldSuccess(updateddata))
            } catch (error) {
                dispatch(createLocationFieldFail(error.message));
            }
        } catch (error) {
            dispatch(setLocationFieldFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkUploadAvatar = (data: { name: string, file: File }, userId: number): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, UserDataState, void, Action<any>>>): Promise<boolean> => {
        const avatar = {
            type_name: "avatar",
            filename: data.name,
            relation_id: userId,
            file: {
                filename: data.name,
                binary: data.file
            }
        };

        try {
            await loggedInInstance.post(`attachment/create`, avatar);
            dispatch(uploadAvatarSuccess());
            dispatch(thunkGetProfile(userId))
        } catch (error) {
            dispatch(uploadAvatarFail(error.message));
        }
        return Promise.resolve(false)
    }

export const thunkEditAvatar = (data: { name: string, file: File }, userId: number, avatar_id: string): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, UserDataState, void, Action<any>>>): Promise<boolean> => {
        const avatar = {
            type_name: "avatar",
            filename: data.name,
            relation_id: userId,
            file: {
                filename: data.name,
                binary: data.file
            }
        };

        try {
            await loggedInInstance.post(`attachment/edit/${avatar_id}`, avatar)
            dispatch(uploadAvatarSuccess());
            dispatch(thunkGetProfile(userId))
            return Promise.resolve(false)
        } catch (error) {
            dispatch(uploadAvatarFail(error.message));
            return Promise.reject(false)
        }
    }


export const thunkActiveParameter = (active: boolean, userId: number, parameter: string): ThunkAction<Promise<boolean>, UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        dispatch(updateAccountPartialData());
        try {
            await loggedInInstance.patch(`profile/change-active-parameter`, {
                parameters: [{
                    user_id: userId,
                    parameter_name: parameter,
                    active: +active
                }]
            });
            dispatch(activeParameterSuccess({
                active: active,
                key: parameter
            }));
        } catch (error) {
            dispatch(activeParameterFail(error.message));
        }
        return Promise.resolve(false)
    }