import { AxiosError } from 'axios';
import { updateToken, logoutUser } from '../../Auth';
import { setError } from '../store';
import checkAuthState from '../checkAuthState';

import { store } from '../../index';
import { I18n } from 'react-redux-i18n';
interface errorResponseData {
	code: number;
	errors: any;
	message: string;
	token?: string;
}

function handleErrors(error: AxiosError<errorResponseData>) {
	if (error.response) {
		const resError = error.response;
		const resErrorData: errorResponseData = resError.data;
		// console.log('ERROR-TYPE-1: ', resError);
		handleError(resError.status, resErrorData.message, resError.config.params, resErrorData.token);
		return error.response.data;
	} else if (error.request) {
		console.log('ERROR-TYPE-2: ', error.message);
		console.log('ERR@2: ', error);
		error.code && handleError(error.code, error.message, error.config.params);
		return error.message;
	} else {
		console.log('ERROR-TYPE-3: ', error);
		console.log('Error3: ', error.message);
		handleError(error.code || 500, 'Unknown Error', error.config.params);
		return error.message;
	}
}

export const handleError = (statusCode: number | string, errorMessage: string, params?: any, token?: string) => {
	if (!checkAuthState() || statusCode === ('401' || 401)) {
		store.dispatch(setError(I18n.t(`account.invalidToken`, { seconds: 3 }), +statusCode));
		return setTimeout(() => store.dispatch(logoutUser()), 3000);
	}
	token && store.dispatch(updateToken(token));
	if (!!params && params.ignore && +statusCode === +params.ignore) {
		return
	}
	return store.dispatch(setError(errorMessage));
}

export default handleErrors;