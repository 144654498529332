import * as React from 'react';
import { Redirect } from 'react-router';
import { Translate } from 'react-redux-i18n';
import { Paper } from '@material-ui/core';

const NoPage = () => {
    const [redirectVal, switchValue] = React.useState(false);

    React.useEffect(() => {
        let TEST: any;
        TEST = setTimeout(() => !redirectVal && switchValue(true), 5000);
        return () => clearTimeout(TEST)
    });

    return (
        <div className="row h-100 d-flex flex-column align-items-center mt-5 ">
            <Paper>
                <div className="col-12 col-md-10 d-flex flex-column justify-content-center align-items-center mr-auto ml-auto mt-5 mb-5 text-center">
                    <h2 className="mt-4">
                        <Translate value="404.error" />
                    </h2>
                    <hr />
                    <h4 className="mb-4">
                        <Translate value="404.message" />
                    </h4>
                </div>
                {redirectVal && <Redirect to="/" />}
            </Paper>
        </div>
    )
};

export default NoPage;