import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { MeasurementsState } from './reducer';
import { AuthState } from '../../../Auth/store/reducer';
import loggedInInstance from '../../../axiosInstances/loggedIn.instance';
import { AxiosResponse } from 'axios';
import {
    ParametersRequestData,
    ParameterNameResponse,
    normalizedParameterPropositions,
    MeasurementsGetDictionaryResponse,
    ParameterFieldsResponse,
    ParameterFieldProposition,
    AddParameterObject,
    MeasurementsRequestData,
    normalizedMeasurements,
    NormalizedParametersEntities,
    AddMeasurement,
    GraphsSection,
    PdfGenerateSection,
    GraphDetails
} from '../measurements.types';
import {
    getMeasurementsTableDataSuccess,
    getParameterPropositionsSuccess,
    getUnitsDictionarySuccess,
    getUnitsIntervalDictionarySuccess,
    getMeasurementsCalculationTypesDictionarySuccess,
    getMeasurementsParameterFieldTypesDictionarySuccess,
    getMeasurementParameterFieldPropositionsSuccess,
    addNewParameterSuccess,
    getParametersTableDataSuccess,
    getMeasurementAddParametersListSuccess,
    getCaregiverChildrenSuccess,
    getParameterFieldsForMeasurementSuccess,
    getParameterUsersForMeasurementSuccess,
    getLastTenMeasurements,
    getAvibleColorsSuccess,
    getGraphsDataSuccess,
    getPdfMainPersonalDataSuccess,
    getGraphDetailsSuccess,
    getPdfUserParameterDataSuccess,
} from './actions';
import { normalize, NormalizedSchema } from 'normalizr';
import parametersSchema from './parametersSchema';
import parameterPropositionsSchema from './parameterPropositionsSchema';
import measurementTableDataSchema from './measurementTableDataSchema';
import { MeasurementTableFilters } from '../Patient/PatientFilters/MeasurementsFiltersBar/MeasurementsFiltersBar';
import moment from 'moment';
import _ from 'lodash';
// import { I18n } from 'react-redux-i18n';

export const thunkGetParametersTableData = (address: string, user_id?: number): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<ParametersRequestData> = await loggedInInstance.get(`measurement/${address}`, {
                params: {
                    user_id
                }
            });
            const { parameters } = data;

            const normalizedParameters: NormalizedSchema<NormalizedParametersEntities, string[]> = normalize(parameters, parametersSchema);

            dispatch(getParametersTableDataSuccess(normalizedParameters));
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetParametersPropositions = (filter: string): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {

        try {
            const { data }: AxiosResponse<ParameterNameResponse> = await loggedInInstance.get(`measurement/parameter/find`, {
                params: {
                    name: filter
                }
            });
            const { parameters } = data;
            const normalizedParameters: NormalizedSchema<normalizedParameterPropositions.ParameterPropositionsEntities, string[]> = normalize(parameters, parameterPropositionsSchema)

            dispatch(getParameterPropositionsSuccess(normalizedParameters));
            return Promise.resolve(false)
        } catch (error) {
            return Promise.resolve(false)
        }
    }

export const thunkGetUnitsPropositions = (): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>

    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {

        try {
            const { data }: AxiosResponse<MeasurementsGetDictionaryResponse> = await loggedInInstance.get(`measurement/dictionary/measurement-parameter-units`);

            const { elements } = data;
            dispatch(getUnitsDictionarySuccess(elements))
            return Promise.resolve(false)
        } catch (error) {
            return Promise.resolve(false)
        }
    }

export const thunkGetIntervalUnitsPropositions = (): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<MeasurementsGetDictionaryResponse> = await loggedInInstance.get(`measurement/dictionary/measurement-interval-units`);

            const { elements } = data;
            dispatch(getUnitsIntervalDictionarySuccess(elements))
            return Promise.resolve(false)
        } catch (error) {

            return Promise.resolve(false)
        }
    }

export const thunkGetMeasurementCalculationTypes = (): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<MeasurementsGetDictionaryResponse> = await loggedInInstance.get(`measurement/dictionary/measurement-parameter-types`);

            const { elements } = data;
            dispatch(getMeasurementsCalculationTypesDictionarySuccess(elements))
            return Promise.resolve(false)
        } catch (error) {
            return Promise.resolve(false)
        }
    }

export const thunkGetParameterFieldTypes = (): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<MeasurementsGetDictionaryResponse> = await loggedInInstance.get(`measurement/dictionary/measurement-parameter-field-types`);

            const { elements } = data;
            dispatch(getMeasurementsParameterFieldTypesDictionarySuccess(elements))
            return Promise.resolve(false)
        } catch (error) {
            return Promise.resolve(false)
        }
    }

export const thunkGetParameterFieldsPropositions = (filter: string): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<ParameterFieldsResponse> = await loggedInInstance.get(`measurement/field/find`, {
                params: {
                    name: filter
                }
            });
            const { fields } = data;

            let parameterFieldsIntoObject: { [id: string]: ParameterFieldProposition } = {};
            fields.map(
                (field: ParameterFieldProposition) => parameterFieldsIntoObject = {
                    ...parameterFieldsIntoObject, [field.name.toLowerCase()]: field
                }
            );
            dispatch(getMeasurementParameterFieldPropositionsSuccess(parameterFieldsIntoObject));
            return Promise.resolve(false)
        } catch (error) {
            return Promise.resolve(false)
        }
    }

export const thunkAddNewParameter = (parameter: AddParameterObject.NewParameterObject): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action<any> | ThunkAction<void, MeasurementsState, void, Action<any>>>): Promise<boolean> => {

        if (typeof parameter.unit === 'string') {
            try {
                const res: AxiosResponse<{ code: number, message: string, token: string, elementId: number }> = await loggedInInstance.post('measurement/dictionary/measurement-parameter-units', { name: parameter.unit })
                const { elementId } = res.data;
                parameter.unit = elementId;
                try {
                    await loggedInInstance.post('measurement/parameter', parameter)
                    dispatch(thunkGetParametersTableData('user-parameters'));
                    dispatch(addNewParameterSuccess(parameter));
                    return Promise.resolve(false);
                } catch (error) {
                    return Promise.reject(false);
                }
            } catch (error) {
                return Promise.reject(false);
            }
        }

        try {
            await loggedInInstance.post('measurement/parameter', parameter)
            dispatch(thunkGetParametersTableData('user-parameters'));
            dispatch(addNewParameterSuccess(parameter));
            return Promise.resolve(false);
        } catch (error) {
            return Promise.reject(false);
        }

    }

export const thunkAddExisitngParameterToUser = (parameter: AddParameterObject.ExistingParameterObject, isDoctor?: boolean): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action<any> | ThunkAction<void, MeasurementsState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.post('measurement/parameter/add-existing-parameter-to-user', parameter)
            dispatch(thunkGetParametersTableData('user-parameters', !!isDoctor ? parameter.user : undefined));
            return Promise.resolve(false);
        } catch (error) {
            return Promise.reject(false);
        }

    }


// export const thunkPatchExisitngParameter = (schedule: AddParameterObject.EditParameterObject, scheduleId: string | number, isDoctor?: boolean): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
//     async (dispatch: Dispatch<Action<any> | ThunkAction<void, MeasurementsState, void, Action<any>>>): Promise<boolean> => {
//         try {
//             await loggedInInstance.put('measurement/schedule/' + scheduleId, schedule)
//             dispatch(thunkGetParametersTableData('user-parameters', (!!isDoctor && !!schedule.user) ? +schedule.user : undefined));
//             return Promise.resolve(false);
//         } catch (error) {
//             return Promise.reject(false);
//         }

//     }

export const thunkGetMeasurementsTableData = (filters?: MeasurementTableFilters): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<MeasurementsRequestData> = await loggedInInstance.get(`measurement/list`, {
                params: {
                    ...filters
                }
            });
            const { measurements } = data;

            const normalizedMesurements: NormalizedSchema<normalizedMeasurements.MeasurementEntities, string[]> = normalize(measurements, measurementTableDataSchema);
            dispatch(getMeasurementsTableDataSuccess(normalizedMesurements));
            return Promise.resolve(false);
        } catch (error) {
            return Promise.reject(false);
        }
    }


export const thunkGetMeasurementAddParametersList = (date?: Date): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const params = date ? { date: moment(date).format('YYYY-MM-DD HH:mm:ss') } : {};
            const { data }: AxiosResponse<AddMeasurement.RequestParameters> = await loggedInInstance.get(`measurement/making/list`, {
                params
            });
            const { parameters } = data;
            let entities = {};
            parameters.map(parameter => entities = { ...entities, [parameter.name]: parameter })
            dispatch(getMeasurementAddParametersListSuccess(entities));
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetCaregiverChildren = (): ThunkAction<Promise<AddParameterObject.AddParamAviableUsers[]>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<AddParameterObject.AddParamAviableUsers[]> => {
        try {
            const { data }: AxiosResponse<AddParameterObject.CaregiverChildren> = await loggedInInstance.get(`caregiver/children`);
            const { children } = data;



            let careTableData: AddParameterObject.AddParamAviableUsers[] = children.filter((child: AddParameterObject.CaregiverObject) => child.accepted && child).map((child: AddParameterObject.CaregiverObject) => ({
                name: child.user.parameters.name + ' ' + child.user.parameters.surname,
                surname: child.user.parameters.surname,
                id: child.user.id,
                avatar: child.user.avatar,
            }))

            dispatch(getCaregiverChildrenSuccess(careTableData));
            return Promise.resolve(careTableData)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetAddMeasurementSelectedParamFields = (paramterId: string): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<AddMeasurement.MeasurementParameterFieldsRequest> = await loggedInInstance.get(`measurement/making/fields/${paramterId}`);
            const { fields } = data;
            dispatch(getParameterFieldsForMeasurementSuccess(fields))
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetAddMeasurementSelectedParamUsers = (parameterId: string): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<AddMeasurement.MeasurementParameterUsersRequest> = await loggedInInstance.get(`measurement/making/user-list/${parameterId}`);
            const { users } = data;
            dispatch(getParameterUsersForMeasurementSuccess(users))
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkAddNewMeasurement = (measurement: AddMeasurement.AddMeasurementForm): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            await loggedInInstance.post(`measurement/making/save-measurement`, measurement);
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetRecentMeasurements = (parameterId: string, userId: string | number): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<AddMeasurement.LastMeasurementsRequest> = await loggedInInstance.get(`measurement/making/recent-measurements/${parameterId}`, {
                params: {
                    user_id: userId
                }
            });
            const { parameter } = data;

            dispatch(getLastTenMeasurements(parameter))
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetDictData = (): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<AddMeasurement.DictRequestData> = await loggedInInstance.get(`export/get-dict/colors`);
            const { dict } = data;
            const colors: string[] = Object.keys(dict).map((key: string) => dict[key].value)
            dispatch(getAvibleColorsSuccess(colors));
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetGraphsData = (limit: number, userId: string | number): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<GraphsSection.GraphsResponse> = await loggedInInstance.get(`measurement/charts`, {
                params: {
                    user_id: userId,
                    limit: limit
                }
            });
            const { parameters } = data;

            dispatch(getGraphsDataSuccess(parameters))
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

/// GENERATE PDF THUNKS

export const thunkGetExportPersonalData = (userId?: string | number): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            let url = userId !== undefined ? `/export/get-main-personal-data?user_id=${userId}` : `/export/get-main-personal-data`
            const { data }: AxiosResponse<PdfGenerateSection.MainPersonalDataResponse> = await loggedInInstance.get(url);
            const { fields } = data;
            let excludedList = ['places']
            const fieldsOut: PdfGenerateSection.MainPersonalData = {} as PdfGenerateSection.MainPersonalData
            _.forEach(fields, (el, i) => {
                if (_.indexOf(excludedList, i) === -1) {
                    fieldsOut[i as keyof PdfGenerateSection.MainPersonalData] = el
                }
            })

            dispatch(getPdfMainPersonalDataSuccess(fieldsOut))
            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkGetExportUserParameters = (userId?: string | number): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<boolean> => {
        try {
            let url = userId !== undefined ? `/export/get-parameter-list-to-pdf?user_id=${userId}` : `/export/get-parameter-list-to-pdf`
            const { data }: AxiosResponse<PdfGenerateSection.MainPersonalDataResponse> = await loggedInInstance.get(url);
            const { fields } = data;
            let excludedList = ['places']

            const fieldsOut: PdfGenerateSection.UserMeasurement[] = []
            _.forEach(fields, (el, i) => {
                if (_.indexOf(excludedList, i) === -1) {
                    fieldsOut.push(el)
                }
            })
            dispatch(getPdfUserParameterDataSuccess(fieldsOut))
            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(false)
        }
    }



export const thunkGeneratePdfData = (inputParams: PdfGenerateSection.GeneratePdfType, userId?: number | string): ThunkAction<Promise<PdfGenerateSection.GeneratedPdfResponse>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action>): Promise<PdfGenerateSection.GeneratedPdfResponse> => {
        try {
            if (userId) {
                inputParams['user_id'] = userId
            }
            let { data }: AxiosResponse<PdfGenerateSection.PdfGenerateResponse> = await loggedInInstance.post(`/profile/export/generate-pdf`, inputParams);
            const { fields } = data;
            if (fields) {
                let path = fields.url || '';
                if (inputParams.email !== undefined) {
                    // dispatch(setSuccess(I18n.t(`measurements.pdf.send_pdf_success_status`)));
                } else {
                    // dispatch(setSuccess(I18n.t(`measurements.pdf.generate_pdf_success_status`)));
                }
                return Promise.resolve({ status: 'OK', data: path })
            } else {
                // dispatch(setError(I18n.t(`measurements.pdf.error_status`)));
                return Promise.resolve({ status: 'ERR', data: null })
            }
        } catch (error) {
            return Promise.reject(false)
        }
    }
export const thunkGetGraphDetails = (parameterId: string, user_id: number, limit?: number, start_date?: string, end_date?: string): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            const { data }: AxiosResponse<GraphDetails.GraphDetailsResponse> = await loggedInInstance.get(`measurement/chart/${parameterId}`, {
                params: {
                    user_id,
                    limit,
                    start_date,
                    end_date
                }
            });

            const recivedGraphData: GraphDetails.GraphDetailsData = data.data;
            dispatch(getGraphDetailsSuccess(recivedGraphData));
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkDeleteUserParameter = (user_id: string | number, parameterId: string | number): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.delete(`measurement/parameter/${parameterId}`, { data: { user_id: user_id } });

            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }

export const thunkToggleUserParameter = (user_id: string | number, measurement_parameter_id: string | number, active: 0 | 1): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.patch(`measurement/parameter/change-active`, {

                user_id: user_id,
                measurement_parameter_id: measurement_parameter_id,
                active: active,

            });
            return Promise.resolve(false)
        } catch (error) {
            return Promise.reject(false)
        }
    }


// TEST

export const thunkPatchExisitngParameter = (schedule: Partial<AddParameterObject.NewParameterObject>, parameterId: string | number, isDoctor?: boolean): ThunkAction<Promise<boolean>, MeasurementsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action<any> | ThunkAction<void, MeasurementsState, void, Action<any>>>): Promise<boolean> => {
        try {
            await loggedInInstance.patch('measurement/parameter/' + parameterId, schedule)
            dispatch(thunkGetParametersTableData('user-parameters', (!!isDoctor && !!schedule.measurement_schedule && !!schedule.measurement_schedule[0].user) ? +schedule.measurement_schedule[0].user : undefined));
            return Promise.resolve(false);
        } catch (error) {
            return Promise.reject(false);
        }

    }