import { FilterData } from "./reducer";
import newPatient from "../AddPatientForm/PersonalDataForm/Patient.type";

export const GET_PATIENTS_TABLE_DATA = '[Patients] Get Patient Table Data';
export const GET_PATIENTS_TABLE_DATA_SUCCESS = '[Patients] Get Patient Table Data Success';
export const GET_PATIENTS_TABLE_DATA_FAIL = '[Patients] Get Patient Table Data Fail';

export const GET_CHANGE_CARE_LEVEL_SUCCESS = '[Patients] Change Care Level Data Success';
export const GET_CHANGE_CARE_LEVEL_FAIL = '[Patients] Change Care Level Data Fail';

export const GET_DICT_SUCCESS = '[Patients] Get dictionary Success';
export const GET_DICT_FAIL = '[Patients] Get dictionary Fail';

export const GET_PLACES_SUCCESS = '[Patients] Get places Success';
export const GET_PLACES_FAIL = '[Patients] Get places Fail';

export const GET_MEDICINE_SUCCESS = '[Patients] Get medicines Success';
export const GET_MEDICINE_FAIL = '[Patients] Get medicines Fail';

export const GET_MEDICINE_IN_STORAGE_SUCCESS = '[Patients] Get medicines in Storage Success';
export const GET_MEASUREMENT_PARAMETERS_SUCCESS = '[Patients] Get mesurement parameters Success';

export const ADD_NEW_PATAIENT = '[Patients] Get patient data';


interface GetPatientTableData {
    type: typeof GET_PATIENTS_TABLE_DATA;
}

interface GetPatientTableDataSuccess {
    type: typeof GET_PATIENTS_TABLE_DATA_SUCCESS;
    payload: any;
}

interface GetPatientTableDataFail {
    type: typeof GET_PATIENTS_TABLE_DATA_FAIL;
    payload: string;
}

interface GetChangeCareLevelSuccess {
    type: typeof GET_CHANGE_CARE_LEVEL_SUCCESS;
    payload: any;
}

interface GetChangeCareLevelFail {
    type: typeof GET_CHANGE_CARE_LEVEL_FAIL;
    payload: string;
}

interface GetDictSuccess {
    type: typeof GET_DICT_SUCCESS;
    type_of_dict: keyof FilterData;
    payload: any;
}

interface GetMeasurementParametersSuccess {
    type: typeof GET_MEASUREMENT_PARAMETERS_SUCCESS;
    type_of_dict: string;
    payload: any;
}

interface GetDictFail {
    type: typeof GET_DICT_FAIL;
    payload: string;
}

interface GetPlacesSuccess {
    type: typeof GET_PLACES_SUCCESS;
    payload: any;
}

interface GetPlacesFail {
    type: typeof GET_PLACES_FAIL;
    payload: string;
}

interface GetMedicineSuccess {
    type: typeof GET_MEDICINE_SUCCESS;
    payload: any;
}

interface GetMedicineInStorageSuccess {
    type: typeof GET_MEDICINE_IN_STORAGE_SUCCESS;
    payload: any;
    medicineId: string,
}

interface GetMedicineFail {
    type: typeof GET_MEDICINE_FAIL;
    payload: string;
}

interface AddNewPatient {
    type: typeof ADD_NEW_PATAIENT;
    payload: newPatient.ExistingPatient;
}

export type PatientActionTypes = GetPatientTableData | GetPatientTableDataSuccess | GetPatientTableDataFail
    | GetChangeCareLevelSuccess | GetChangeCareLevelFail | GetDictSuccess | GetDictFail | GetPlacesSuccess | GetPlacesFail
    | GetMedicineSuccess | GetMedicineFail | GetMedicineInStorageSuccess | GetMeasurementParametersSuccess | AddNewPatient;
