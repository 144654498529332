import { Dictionary } from './Dictionary'

/* eslint-disable no-template-curly-in-string */

const pl: Dictionary = {
	nav: {
		Home: 'Pulpit',
		Login: 'Logowanie',
		Logout: 'Wylogowanie',
		ChangePassword: 'Zmień Hasło',
		Register: 'Rejestracja',
		Meds: 'Leki',
		MedChecks: 'Pomiary',
		Calendar: 'Kalendarz',
		My_Doctors: 'Moi Lekarze',
		Patients: 'Pacjenci',
		Sicknesses_Schemas: 'Szablony Schorzeń',
		Account: 'Dane',
		Pricing: 'Cennik',
		fontSize: {
			selectContrast: 'kontrast:',
			selectText: 'wielkość liter:'
		}
	},
	loginForm: {
		loginTitle: 'Zaloguj',
		registerTitle: 'Zarejestruj',
		email: 'Podaj swój email',
		password: 'Podaj swoje hasło',
		newPassword: 'Nowe hasło',
		submit: 'Wyślij',
		resetPassword: 'Zapomniałeś hasła?',
		rePassword: 'Podaj ponownie nowe hasło',
		goBack: 'Wróć do logowania',
		registerEmail: 'Podaj adres email',
		setNewPassword: 'Ustal nowe hasło'
	},
	startPage: {
		registerSection: {
			Title: 'Zarejestruj się i korzystaj bezpłatnie!',
			Paragraph: 'Jako lekarz, pacjent lub opiekun osoby chorej',
			ButtonGoogle: 'Zarejestruj przez Google +',
			ButtonFacebook: 'Zarejestruj przez Facebook',
			ToolTipFacebook: 'Wkrótce dostępne!',
			ToolTipGoogle: 'Wkrótce dostępne!',
		},
		textBox: {
			LeftTitle: 'Dzienniczek',
			LeftText: 'Możliwość prowadzenia samoobserwacji przez pacjentów.',
			CenterTitle: 'Apteczka / leki',
			CenterText: 'Zawiera informacje o posiadanych przez pacjenta lekach, ich dawkowaniu i godzinach przyjęcia.',
			RightTitle: 'Komunikacja',
			RightText: 'System pozwala na automatyczne przekazywanie informacji o stanie zdrowia do jednostki monitorującej.'
		},
		textSection: {
			FirstSectionTitle: 'DZIENNICZEK',
			FirstSectionText: 'Możliwość prowadzenia samoobserwacji przez pacjentów. Aplikacja współpracuje z zewnętrznymi urządzeniami pomiarowymi pozwalając na automatyczne przesyłanie danych o wynikach poprzez Bluetooth (m.in. ciśnieniomierz, pulsoksymetr, waga, temperatura, pedometr, tester INR, glukometr) lub też wprowadzać je ręcznie (np. w przypadku braku możliwości komunikacji – jak np. z pompami LVAD).',
			FirstSectionTitleLower: 'dzienniczek',
			SecondSectionTitle: 'APTECZKA / LEKI',
			SecondSectionText: 'Zawiera informacje o posiadanych przez pacjenta lekach, ich dawkowaniu i godzinach przyjęcia. Automatyczne przypomnienia wspomagają terapię a przypomnienia o kończących się lekach eliminują problem ich braku po stronie chorego (w przyszłości planowana jest integracja z systemem elektronicznej recepty). Dzięki integracji z bazą danych o lekach dostępne są szczegółowe informacje dot. ich stosowania, interakcji a także alerty zastosowania (np. przeciwskazania do prowadzenia pojazdów, spożywania alkoholu, karmienia piersią czy ciąży) co wspomaga pacjentów w codziennym funkcjonowaniu.',
			SecondSectionTitleLower: 'apteczka / leki',
			ThirdSectionTitle: 'KOMUNIKACJA',
			ThirdSectionText: 'System pozwala na automatyczne przekazywanie informacji o stanie zdrowia do jednostki monitorującej. Osoby posiadające odpowiednie uprawnienia otrzymują alerty w przypadku danych, które wykraczają poza określone normy co pozwala na przyspieszenie reakcji w przypadku występowania komplikacji a co za tym idzie lepszą opiekę nad pacjentem i mniejsze koszty. System przypomina o wizytach, sugeruje szczepienia i wspomaga komunikację między lekarzem/koordynatorem leczenia a pacjentem. Ponadto aplikacja pozwala na przekazywanie informacji o stanie zdrowia bliskim pacjenta.',
			ThirdSectionTitleLower: 'komunikacja'
		},
		screensSection: {
			Title: 'W prosty sposób załóż konto',
			Paragraph: 'Aby założyć konto w systemie Remedizer wpisz swój adres email i odbierz wiadomość z linkiem aktywacyjnym.'
		},
	},
	modal: {
		buttons: {
			submit: 'Wyślij',
			close: 'Zamknij',
			save: 'Zapisz',
			cancel: 'Anuluj',
			confirm: 'Potwierdź',
			skip: 'Pomiń',
			saveAndGo: 'Zapisz i przejdź dalej',
			goNext: 'Przejdź dalej',
		},
		uniFields: {
			password: 'Hasło',
			email: 'Email',
			name: 'Imię',
			surname: 'Nazwisko',
			age: 'Wiek',
			rePassowrd: 'Powtórz hasło',
			email_or_phone: 'Email lub numer telefonu',
			new_password: 'Nowe hasło',
			new_password_confirm: 'Powtórz nowe hasło',
			actual_password: 'Aktualne hasło'
		},
		titles: {
			deleteParam: 'Usuń parametr'
		}
	},
	404: {
		error: ' Błąd 404! Nie ma takiego adresu.',
		message: ' W ciągu 5 sekund zostaniesz przekierowany na istniejący adres.'
	},
	account: {
		invalidToken: 'Twoja sesja jest zakończona. Zostaniesz wylogowany za %{seconds} s',
		loginToContinue: 'Zaloguj się by kontynuować',
		languagePicker: {
			language: 'Język',
			pickLanguage: 'Język Aplikacji',
			pl: 'Polski',
			en: 'Angielski',
			de: 'Niemiecki',
			fr: 'Francuski',
		},
		userData: {
			birthDate: 'Data urodzenia',
			doctorPlaces: "Szpital lekarza",
			description: 'Opis',
			idNumber: 'PESEL',
			weight: 'Waga',
			height: 'Wzrost',
			glucose: 'Glukoza',
			sex: 'Płeć',
			sickness: 'Schorzenie',
			machine: 'Urządzenie',
			email: 'Email',
			phone: 'Numer telefonu',
			address: 'Adres zamieszkania',
			closestHospital: 'Najblizszy Szpital',
			closestPharmacy: 'Najblisza apteka',
			nameSurname: 'Imię i nazwisko',
			medicineDevice: 'Urządzenie',
			diesease: 'Choroba',
			glucose_measurement_unitEU: 'mmol/l',
			glucose_measurement_unitEN: 'mg/dl',
			height_unitEU: 'Centymetry',
			height_unitEN: 'Stopy',
			weight_unitEU: 'Kilogramy',
			weight_unitEN: 'Funty',
			unitsLabel: 'Jednostki',
			edition: 'Edycja',
			cancelEdit: 'Cofnij zmiany',
			confirmEdit: 'Potwierdź Zmiany',
			addAvatar: 'Dodaj avatar',
			caregiversTable: {
				headerLabelName: 'Imię',
				headerLabelSurname: 'Nazwisko',
				headerLabelEmail: 'Email',
				headerLabelAccepted: 'Zaakceptowano',
				sectionLabelCaregiverChildren: 'Twoi podopieczni',
				sectionLabelCaregiverParents: 'Twoi opiekunowie',
				caregiverPermissionAccepted: 'Tak',
				caregiverPermissionRejected: 'Nie',
				caregiverPermissionPending: 'W toku',
				noCaregiverChildrenLabel: 'Brak podopiecznych',
				noCaregiverParentslabel: 'Brak opiekunów'
			},
			caregiverRequest: {
				emailInputPlaceholder: 'Adres email',
				addAccountButtonLabel: 'Dodaj konto',
				addButtonLabel: 'Dodaj',
				informationLabel: 'Wysłałeś prośbę o dołączenie użytkownika do grupy kont, którymi się opiekujesz. Informacja o zaakceptowaniu lub odrzuceniu prośby zostanie przesłana na Twój adres email.',
				emailWrongFormatMsg: 'Niepoprawny format',
				emailRequiredMsg: 'Pole wymagane',
			},
			caregiverResponse: {
				acceptButtonLabel: 'Akceptuj',
				rejectButtonLabel: 'Odrzuć',
				deleteButtonLabel: 'Usuń',
				modalTitle: 'Pytanie',
				modalQuestion: 'Czy na pewno chcesz usunąć wybranego opiekuna?'
			},
			addUserDataForm: {
				title: 'Dodawanie elementu',
				name: 'Nazwa pola',
				value: 'Wartość',
			},
			addDieseaseForm: {
				title: 'Dodawanie choroby',
				name: 'Nazwa choroby'
			},
			addMedicineDeviceForm: {
				title: 'Dodawanie urządzenia',
				name: 'Nazwa urządzenia',
				weight: 'Waga',
				changesRotation: 'Obroty zmiana',
			},
			requiredFields: 'Wymagane pola'
		},
		webPushForm: {
			title: 'Powiadomienia',
			question: 'Zezwalaj na powiadomienia',
			yes: 'Tak',
			no: 'Nie',
			unsupported: 'Twoja przeglądarka nie wspiera powiadomień lub zablokowano ich wysyłanie.\nZmień ustawienia przeglądarki lub skorzystaj z nowoczesnych przeglądarek.'
		}
	},
	formInputs: {
		selectLabels: {
			unit: 'Filtruj po jednostkach',
			user: 'Filtruj po użytkownikach',
			every: 'Co',
			calendarUnit: 'Okres kalendarzowy',
			typeOfMeasurement: 'Sposób pomiaru',
			paramField: 'Typ pola',
			dieseases_P: "Schorzenie",
			places_P: "Placówka",
			medicines_P: "Leki",
			parameters_P: "Parametry",
			medicine_devices_P: "Urządzenie",
			age_from: "Wiek od",
			age_to: "Wiek do",
			parameterName: 'Filtruj po nazwie parametru',
			selectNumberOfResults: 'Filtruj po liczbie pomiarów',
		},
		hourPickerLabels: {
			onHour: 'O godzinie'
		},
		autocompleateLabels: {
			parameterSelect: 'Nazwa parametru',
			unitSelect: 'Jednostka paramteru',
			fieldSelect: 'Nazwa pola',
		}
	},
	patients: {
		patients: "Pacjenci",
		add_patient: "Dodaj pacjenta",
		sort: "Sortuj A-Z",
		age: "lat",
		age_1: "rok",
		age_2: "lata",
		age_3: "lata",
		age_4: "lata",
		contact: "Kontakt",
		email: "email",
		phone: "tel.",
		distant_care: "Pacjent zamierzchły",
		close_care: "Pacjent pod ścisłą kontrolą",
		filters_button: "rozwiń filtry wyszukiwania",
		search_patient: "Wyszukaj pacjenta",
		userCard: 'Karta',
		importantMeassages: 'Ważne komunikaty',
		medicines: 'Leki',
		parameters: 'Parametry',
		data: 'Dane',
		ageInYears: 'lat',
		noUser: 'Nie masz uprawnień do wyświetlenia tego użytkownika',
		from: 'od',
		to: 'do',
		patient_data: 'Dane osobowe',
		add_device_sickness: 'Urządzenie i schorzenie',
		add_medicines: 'Przyjmowane leki',
		add_parameters: 'Mierzone parametry',
		optional: 'Opcjonalny'
	},
	measurements: {
		pdf: {
			modal_title: 'Wygeneruj pdf z danymi pacjenta',
			share_modal_title: 'Wygeneruj i udostępnij pdf z danymi pacjenta',
			generate_pdf: 'Wygeneruj pdf',
			send_email: 'Wyślij',
			what_form_of_pdf: 'W jakiej formie chcesz wygenerować pdf?',
			date_time_range: "Zakres czasowy dat",
			informatation_shared_with_your_data: "Wybierz informacje które mają być udostępnione z danymi parametrów",
			parameters: "Parametry",
			basic_patient_medical_data: "Podstawowe dane medyczne pacjenta",
			personal_data: "Dane osobowe",
			from_begining: "Od początku",
			recipients_email: "email adresata",
			right_side_empty_information: "Kliknij przycisk \"Wygeneruj PDF\" aby wygenerować lub przycisk \"Wyślij\" aby wysłać e-mail z wygenerowanym PDF",
			error_status: "Wystąpił problem",
			generate_pdf_success_status: "Poprawnie wygenerowano PDF",
			send_pdf_success_status: "Poprawnie wysłano PDF",
			form_of_pdf: {
				table: "Tabela",
				chart: "Wykres",
			},
			additional_elements_to_show: {
				medicines_taken: "Leki pobrane",
				medicines_untaken: "Leki niepobrane",
				medicine_devices: "Urządzenia",
				medicine_storage: "Apteczka",
				alerts: "Alerty",
				my_doctors: "Moi lekarze",
				dieseases: "Schorzenia",
				future_dozens: "Przyszłe dawki"
			},
			personalData: {
				name: "Imię",
				surname: "Nazwisko",
				birth_date: "Data urodzenia",
				age: "Wiek",
				description: "Opis",
				address: "Adres",
				patient_id: "ID Pacjenta",
				mail: "E-mail",
				phone: "Telefon",
				medicine_devices: "Urzadzenia",
				dieseases: "Schorzenia",
				places: "Miejsca",
				initials: "Inicjały",
				avatar: "Avatar",
				avatar_url: "Avatar"
			}
		},
		bar: {
			addParameter: 'Dodaj parametr',
			addMeasurement: 'Dodaj pomiar',
			views: 'Widok:',
			measurements: 'Pomiary',
			parameters: 'Parametry',
			graphs: 'Wykresy',
			paramFromSchema: 'Dodaj parametr z szablonu',
			from: 'od',
			to: 'do',
			or: 'lub',
			lastLimit: 'Ostatnie',
			resultsPerParameter: 'rezultatów dla każdego parametru',
			allResults: 'Wszystkie parametry',
			unit: 'Jednostka',
			user: 'Użytkownicy',
			allUsers: 'Wszyscy użytkownicy',
			allParams: 'Wszystkie pomiary',
		},
		parametersTable: {
			action: 'Akcja',
			parameter: 'Parametr',
			unit: 'Jednostka',
			schedule: 'Częstotliwość pomiaru',
			users: 'Przypisany do',
			mesurementType: 'Sposób mierzenia',
			attachment: 'Załącznik',
			interval: 'Co',
			day: 'dni',
			month: 'miesiące',
			week: 'tygodnie',
			on: 'o: ',
			numberOfResults: 'Ilość wyników na stronie: ',
			select: 'Wybierz filtr',
			change: 'Zmień',
			filters: 'Filtry',
			noResults: 'Brak pasujących wyników',
			andNOthers: "I %{count} innych",
			exportToPdf: 'Eksportuj do pdf',
			shareData: 'Udostępnij dane',
			addUser: 'Dodaj użytkownika',
			delete: 'Usuń',
			active: 'Aktywny',
			inactive: 'Nieaktywny',
			noUserId: 'Aby zobaczyć to okno musisz najpierw utworzyć użytkownika',
		},
		measurementsTable: {
			measurements: 'Pomiary A-Z',
			value: 'Wartość',
			date: 'Data pomiaru',
			filterSearch: 'Znajdź pomiary',
			filterReset: 'Reset',
			startDate: 'Data początkowa',
			endDate: 'Data końcowa',
		},
		addParamModal: {
			editSchedule: "Edytuj harmonogram",
			advanced: "Ustawienia zaawansowane",
			basic: "Ustawienia podstawowe",
			parameterName: "Nazwa parametru",
			periodicity: "Częstotliwość pomiaru",
			tooltips: {
				addHour: 'Dodaj godzinę',
				removeHour: 'Usuń godzinę',
				removeField: 'Usuń pole',
				addField: 'Dodaj pole',
			},
			createOrFindParameter: 'Utwórz lub wyszukaj parametr',
			createOrFindField: 'Utwórz lub wyszukaj pole',
			createOrFindUnit: 'Utwórz lub wyszukaj jednostkę',
			incorectValueSelectedPickSomethingElse: 'Wybrano niepoprawną wartość. Wybierz inną',
			equation: 'Równanie:',
			noFieldSelected: 'Nie wybrano pola',
		},
		addExistingParameterModal: {
			title: "Przypisz parametr",
			description: "Przypisz parametr <b>%{parameter}</b> użytkownikowi:",
			user: "Użytkownik",
			userPlaceholder: "Wyszukaj użytkownika",
		},
		addMeasurementModal: {
			commentAndAttachment: 'Dodaj komentarz i/lub załącznik',
			addAttachment: 'Dodaj Załącznik',
			skipMeasurement: 'Pomiń',
			saveMeasurement: 'Zapisz',
			addComment: 'Dodaj komentarz',
			closeMeasurement: 'Zamknij',
			compleated: 'Wykonane pomiary',
			noParameters: 'Aby móc dodawać pomiary, musisz najpierw dodać jakiś parametr',
		},
		graphsSection: {
			alerts: 'Alerty',
			omittedMedicines: 'Pominięte leki',
			omittedMeasurements: 'Pominięte pomiary',
			lastMeasurement: 'Ostatni pomiar',
			noResult: 'Brak pomiaru',
			takenMedicines: 'Wzięte leki',
			attachment: 'Załącznik',
			comment: 'Komentarz',
			table: 'Tabela',
			details: 'Graf',
			omitted_medicine: 'nieprzyjęty',
			noMeasurements: 'Brak zleconego pomiaru',
			noMedicines: 'Brak przepisanego leku',
			measurements_results: 'Wyniki',
			date: 'Data',
			medicines: 'Leki',
			none: 'Brak',
			omitted_measurement: 'niewykonany',
			noGraphsToDisplay: 'Nie ma żadnych grafów do wyświetlenia. Dodaj jakieś pomiary by je zobaczyć.',
			noMatchingUser: "Wybrany użytkownik nie ma przypisanego tego parametru",
			confirmFilters: "Potwierdź wybór użytkownika przez kliknięcie 'OK' by zobaczyć dane"
		},
	},
	yupValidationDefaultMessages: {
		mixed: {
			required: 'Wymagane',
			oneOf: 'Hasła nie są takie same',
		},
		string: {
			email: 'Niepoprawny email',
			min: 'Minimum %{min} znaków',
			max: 'Maksimum %{max} znaków',
		},
		array: {
			min: 'Równanie musi mieć minimum  %{min} elementy'
		}
	},
	myDoctors: {
		doctor: {
			headerTitle: 'Moi lekarze',
			headerButton: 'Dodaj lekarza',
			name: 'Imię i nazwisko',
			specialization: 'Specjalizacja',
			place: 'Placówka',
			callButton: 'Zadzwoń',
			chatButton: 'Czat',
			noDoctorsMessage: 'Brak lekarzy',
			modalTitle: 'Dodaj lekarza',
			modalEmailPlaceHolder: 'Email',
			modalNamePlaceHolder: 'Imię',
			modalSurnamePlaceHolder: 'Nazwisko',
			modalDeleteQuestion: 'Czy na pewno usunąć lekarza z listy?',
		},
		place: {
			headerTitle: 'Moje placówki',
			headerButton: 'Dodaj placówkę',
			name: 'Placówka',
			address: 'Adres',
			noPlacesMessage: 'Brak placówek',
			modalDeleteQuestion: 'Czy na pewno usunąć placówkę z listy?',
			modalTitle: 'Dodaj miejsce',
			modalSelectPlaceHolder: 'Wpisz nazwę miejsca',
		},
		email: 'Email',
		phone: 'Telefon',
		deleteDoctorButton: 'Usuń',
		downloadPdfButton: 'Ściągnij Pdf',
	},
	calendar: {
		prev: 'Poprzedni',
		next: 'Następny',
		exportEvents: 'Eksportuj wydarzenia',
		addEvent: 'Dodaj wydarzenie',
		addEventForm: {
			modalTitle: 'Dodaj wydarzenie',
			title: 'Tytuł',
			description: 'Opis',
			date: 'Data'
		},
		formValidation: {
			required: 'To pole jest wymagane'
		},
		filterMedicine: 'Pokazuj leki',
		filterMeasurements: 'Pokazuj pomiary',
		filterConsultations: 'Pokazuj konsultacje',
	},
	filters: {
		medicine_device1: 'Urządzenie 1',
		medicine_device2: 'Urządzenie 2',
		medicine_device3: 'Urządzenie 3',
		schorzenie1: 'Schorzenie 1',
		schorzenie2: 'Schorzenie 2',
		schorzenie3: 'Schorzenie 3',
		marketing: 'Marketing',
		name: 'Imię',
		surname: 'Nazwisko',
		weight_unit: 'Jednostka wagi',
		height_unit: 'Jednostka wzrostu',
		glucose_measurement_unit: 'Jednostka pomiaru cukru',
		birth_date: 'Data urodzenia',
		description: 'Opis',
		pesel: 'PESEL',
		sex: 'Płeć',
		phone: 'Telefon',
		rotations: 'Obroty urządzenia',
		specialization: 'Specializacja',
		place_health: 'Placówka medyczna',
	},
	medicines: {
		table: {
			nameLabel: 'Lek',
			amountLabel: 'Ilość',
			sufficientToLabel: 'Wystarczy do',
			assignedToLabel: 'Przypisane do',
			dosageLabel: 'Dawka',
			takenLabel: 'Przyjęte przez',
			timeOfTakenLabel: 'Czas przyjęcia',
			shouldBeTakenByLabel: 'Miał(a) przyjąć',
			shouldBeTakenAtLabel: 'Miało być przyjęte przez',
			hasToBeTakenAt: 'Ma być przyjęte',
			hasToTake: 'Ma przyjąć',
			addUserButtonToolTip: 'Dodaj użytkownika',
			changeDosingButtonLabel: 'Zmień',
			noResults: 'Brak danych',
			fromDayLabel: 'Od dnia',
			toDayLabel: 'Do dnia',
			dosingLabel: 'Dawkowanie',
			noteLabel: 'Notatka',
		},
		toolBar: {
			addMedicineButtonLabel: 'Dodaj lek',
		},
		searchBar: {
			searchButtonLabel: 'Wyszukaj',
			searchText: {
				minLength: 'Minimum 3 znaki',
				label: 'Szukaj leku',
			},
			date: {
				startDateBeforeEndDate: 'Data początkowa musi być przed końcową',
				endDateAfterStartDate: 'Data końcowa musi być po początkowej',
				startDatePickerLabel: 'Data startowa',
				endDatePickerLabel: 'Data końcowa',
			},
			select: {
				selectLabel: 'Pokaż:',
				showAllMedicines: 'Wszystkie leki',
				showTakenMedicines: 'Wzięte leki',
				showFutureMedicines: 'Przyszłe leki',
				showSkippedMedicines: 'Pominięte leki',
			}
		},
		modal: {
			modalTitle: 'Dodaj lek',
			reservesTabLabel: 'Zapasy leku',
			scheduleTabLabel: 'Harmonogram przyjmowania',
			saveMedicine: 'Zapisz lek w apteczce',
			saveSchedule: 'Zapisz harmonogram',
			medicineReservesTab: {
				titlePlaceholder: 'Wyszukaj nazwę leku',
				amountlabel: 'Ilość',
				formLabel: 'Forma',
				dosagesLabel: 'Dawka',
				whoElseLabel: 'Kto jeszcze może korzystać z leku oprócz Ciebie?',
				usersAssignedToMedicineLabel: 'Użytkownicy',
				isUserAlarmedAboutEndOfMedicineLabel: 'Czy powiadomić Cię gdy lek się skończy?',
				isUserAlarmedAboutEndOfMedicineSwitchYesLabel: 'Tak',
				isUserAlarmedAboutEndOfMedicineSwitchNoLabel: 'Nie',
				isUserAlarmedAboutEndOfMedicineSwitchTrailingText: 'dni do końca leku',
				medicineDetailsPlaceholder: 'Podaj informacje o leku, by zobaczyć ulotkę',
				priceLabel: 'Cena',
				packageLabel: 'Opakowanie',
				producerLabel: 'Producent',
				deleteButtonLabel: 'Usuń lek',
				deleteModalQuestion: 'Czy na pewno chcesz usunąć ten lek?',
				patientSelectLabel: 'Pacjent',
				medicineDetails: {
					driving: 'Można prowadzić pojazdy',
					noDriving: 'Nie prowadzić pojazdow',
					alcohol: 'Można lączyć z alkoholem',
					noAlcohol: 'Nie lączyć z alkoholem',
					pregnacy: 'Dla kobiet w ciąży',
					noPregnacy: 'Nie dla kobiet w ciąży',
					lactation: 'Dla matek karmiących',
					noLactation: 'Nie dla karmiących matek',
					trimester1: 'Dla matek w pierwszym trymestrze',
					noTrimester1: 'Nie dla matek w pierwszym trymestrze',
					trimester2: 'Dla matek w drugim trymestrze',
					noTrimester2: 'Nie dla matek w drugim trymestrze',
					trimester3: 'Dla matek w trzecim trymestrze',
					noTrimester3: 'Nie dla matek w trzecim trymestrze',
					downloadFlyer: 'Pobierz ulotkę',
				},
			},
			medicineScheduleTab: {
				fastDosingSettingsLabel: 'Szybkie ustawienia dawkowania',
				advancedDosingSettingsLabel: 'Zaawansowane ustawienia dawkowania',
				every: 'Co',
				interval: 'Okres',
				hour: 'Godzina',
				amount: 'Ilość',
				addHour: 'Dodaj termin',
				removeHour: 'Usuń termin',
				remove: 'Usuń',
				add: 'Dodaj',
				errorNoDoubledHours: 'Godziny nie mogą się powtarzać',
				errorAtLeastOneHour: 'Należy wybrać minimum jeden termin',
			},
		},
		addUserToMedicineModal: {
			label: 'Dodaj użytkownika',
		},
	},
	notifications: {
		noResults: 'Brak powiadomień',
		handleReadClick: 'Oznacz jako przeczytane',
		handleDeleteClick: 'Usuń powiadomienie',
		status: {
			readed: 'Przeczytano: %{date}'
		},
		type: {
			consultation: 'Konsultacja',
			measurement: 'Pomiar',
			medicineReminder: 'Przyjęcie leku',
			phone: 'Konsultacja telefoniczna',
		},
		addNotificationForm: {
			type: 'Typ powiadomienia',
			typePlaceholder: 'Wyszukaj typ powiadomienia',
			content: 'Treść powiadomienia'
		}
	},
	medicinesToTake: {
		noResults: 'Brak leków do przyjęcia',
		medicinesToTakeAt: 'Leki do przyjęcia o',
		takeMedicines: 'Przyjmij leki',
		takeMedicine: 'Przyjmij lek',
		dosage: 'Dawka'
	},
	measurementsToMake: {
		lastMeasurement: 'Ostatni pomiar',
		measurementsToMake: 'Pomiary do wykonania',
		makeMeasurements: 'Wykonaj pomiary',
		noResults: 'Brak pomiarów do wykonania',
		notMeasuredYet: 'Jeszcze nie wykonano pomiaru'
	},
	dashboard: {
		medicines: {
			yourStorage: 'Twoja apteczka',
			yourStorageDescription: 'Tutaj zobaczysz wszystkie leki, które powinieneś zażyć danego dnia',
			storageLink: 'Wprowadź swój pierwszy lek do apteczki',
		},
		parameters: {
			yourParameters: 'Twoje pomiary',
			yourParametersDescription: 'Tutaj zobaczysz wszystkie zaplanowane pomiary, jakie musisz wykonać danego dnia',
			parametersLink: 'Wprowadź swój pierwszy parametr',
		},
		caregivers: {
			yourNotifications: 'Twoje powiadomienia',
			yourNotificationsDescription: 'Tutaj zobaczysz wszystkie powiadomienia, jakie otrzymałeś od swojego lekarza',
			inviteCaregiver: 'Zaproś lekarza do Remedizer',
		},
		alerts: {
			noComments: 'Brak komentarza',
			noAttachments: 'Brak załącznika',
			years: 'Lat',
			messages: 'Masz %{count} wiadomości które czekają na weryfikację',
			message: 'Masz %{count} wiadomość która czeka na weryfikację',
			compareResults: 'Porównaj wyniki %{parameter} z:',
			noResults: 'Brak wyników',
			noResultsChart: 'Brak wyników dla tego okresu czasu',
			chartSelect: {
				d5: '5 dni',
				d10: '10 dni',
				d20: '20 dni',
				m1: '1 miesiąca',
				m3: '3 miesięcy',
				m6: '6 miesięcy',
				y1: '1 rok',
				y2: '2 lata',
				y3: '3 lata',
			},
			approve: 'Zatwierdź',
			requiresConsultation: 'Wymaga konsultacji',
			approveAll: 'Zatwierdź wszystkie',
		},
		orderByDates: 'Sortuj po dacie',
		measurements: {
			alerts: 'Ostrzeżenia',
			notApproved: 'Niezatwierdzone',
			approved: 'Zatwierdone',
			modalQuestion: 'Czy na pewno chcesz zatwierdzić wszystkie pomiary?'
		},
		consultations: 'Konsultacje',
		consultation: {
			closest: 'Najbliższe',
			completed: 'Zrealizowane',
			visitContact: 'Wizyta',
			phoneContact: 'Kontakt telefoniczny',
			edit: 'Edytuj',
			completeConsultation: 'Konsultacja zrealizowana',
			sendRequestPhone: 'Wysłano prośbę o kontakt telefoniczny',
			sendRequestVisit: 'Wysłano prośbę o kontakt osobisty',
			complateAll: 'Zrealizowano wszystkie',
			month: 'Miesiąc',
			week: 'Tydzień',
			day: 'Dzień',
			consultation: 'Tel',
			visit: 'Wizyta',
			modalTitle: 'Pytanie',
			modalQuestion: 'Czy na pewno chcesz zakończyć wszystkie konsultacje?',
		}
	},
	templates: {
		addTemplate: 'Dodaj szablon',
		table: {
			name: 'Nazwa',
			parameters: 'Parametry'
		},
		measurementType: {
			manual: 'Wpisywany manualnie',
			auto: 'Pomiar automatyczny',
			equation: 'Pomiar wyliczeniowy',
		},
		addTemplateForm: {
			modalTitle: 'Dodaj szablon',
			editModalTitle: 'Edytuj szablon',
			name: 'Nazwa szablonu',
			type: 'Typ szablonu',
			typeDiesease: 'Choroba',
			typeDevice: 'Urządzenie',
			templateParameters: 'Parametry szablonu:',
			parameter: 'Parametr',
			searchParameter: 'Wyszukaj parametr',
			removeParameter: 'Usuń parametr',
			addParameter: 'Dodaj parametr',
		},
		addParamFromTemplate: {
			selectMinOne: 'Przynajmniej jeden parametr musi być wybrany',
			deleteParameterMessage: 'Czy jesteś pewny, że chcesz usunąć parametr?',
			selectTemplate: 'Wybierz szablon by dodać parametry',
		}
	},
	consultation: {
		tab: {
			phone: 'Kontakt telefoniczny',
			visit: 'Kontakt osobisty'
		},
		phone: {
			header: 'Data, kiedy pacjent powinien do Ciebie zadzwonić:',
		},
		visit: {
			header: 'Data wizyty:',
			place: 'Miejsce wizyty:',
			placeLabel: 'Miejsce',
			placePlaceholder: 'Wyszukaj miejsce',
		}
	},
	importantMessages: {
		header: {
			closestEvent: 'Najbliża konsultacja',
			addEvent: 'Dodaj konsultację',
			notifications: 'Powiadomienia',
			addNotification: 'Dodaj powiadomienie'
		}
	},
	confirmAccount: {
		pleaseWait: 'Proszę czekać. Trwa potwierdzenie rejestracji',
		accountConfirmed: 'Konto zostało potwierdzone poprawnie',
		somethingGoesWrong: 'Coś poszło nie tak',
		backToHomePage: 'Powrót do strony głównej'
	}
}


export default pl;
