import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Grid, Box, Paper } from '@material-ui/core';
import ResetPasswordForm from 'views/ResetPassword/ResetPasswordForm/ResetPasswordForm';

interface ResetPasswordProps extends RouteComponentProps {

}

const ResetPassword: React.FC<ResetPasswordProps> = ({ location }) => {
    const token = (location && location.search && location.search.split('=')) ? location.search.split('=').pop() : ''
    return (
        <Grid container justify="center">
            <Box marginTop="50px">
                <Paper>
                    <ResetPasswordForm
                        token={token}
                    />
                </Paper>
            </Box>
        </Grid>
    );
}

export default withRouter(ResetPassword);

