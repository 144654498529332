export const LOGIN_SUCCESS = '[Auth] Login Success';

export const LOGOUT = '[Auth] Logout';

export const CONFIRM_ACCOUNT = '[Auth] Confirm Account Attempt';
export const CONFIRM_ACCOUNT_SUCCESS = '[Auth] Confirm Account Attempt Success ';
export const CONFIRM_ACCOUNT_FAILED = '[Auth] Confirm Account Attempt Failed';

export const UPDATE_TOKEN = '[Auth] Update Token';

export interface UserLoginData {
    email_or_phone: string;
    password: string
}

export interface UserRegistrationData {
    email: string;
    language_id: number;
}

interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    payload: string;
    role: boolean;
    userId: number;
}

export interface LogutUser {
    type: typeof LOGOUT
}

interface ConfirmAccount {
    type: typeof CONFIRM_ACCOUNT,
}

interface ConfirmAccountSuccess {
    type: typeof CONFIRM_ACCOUNT_SUCCESS;
}

interface ConfirmAccountFailed {
    type: typeof CONFIRM_ACCOUNT_FAILED,
    payload: string
}


interface UpdateToken {
    type: typeof UPDATE_TOKEN,
    payload: string,
}


export type AuthActionTypes =
    LoginSuccessAction |
    LogutUser |
    ConfirmAccount |
    ConfirmAccountFailed |
    ConfirmAccountSuccess |
    UpdateToken
    ;
