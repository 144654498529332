import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { AxiosResponse } from 'axios';
import loggedInInstance from 'axiosInstances/loggedIn.instance';
import ErrorMessage from 'axiosInstances/ErrorHandling/error.types';

import { PatientsState, FilterData } from './reducer';
import { AuthState } from 'Auth/store/reducer';
import { PatientsRequestData, ChangeCareLevelRequestData, RequestDataBase, Fields, DictRequestData, PlacesRequestData, MedicineRequestData, MedicinesInStorageRequestData } from '../patients.types';
import {
    getPatientsTableDataFail,
    getPatientsTableDataSuccess,
    getChangeCareLevelFail,
    getChangeCareLevelSuccess,
    getDictSuccess,
    getDictFail,
    getPlacesSuccess,
    getPlacesFail,
    getMedicinesSuccess,
    getMedicinesFail,
    getMedicinesInStorageSuccess,
    getMeasurementParametersSuccess,
    addNewPatient,
} from './actions';

import { ParametersRequestData, User, RemedizerApiResponse } from 'views/Measurements/measurements.types';
import _ from 'lodash';
import { TemplatesState } from 'views/Templates/store';
import { UserDataState } from 'views/UserData/store';
import newPatient from '../AddPatientForm/PersonalDataForm/Patient.type';

export const thunkGetPatientsTableData = (address: string): ThunkAction<Promise<boolean>, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {

        try {
            const { data }: AxiosResponse<PatientsRequestData> = await loggedInInstance.get(`caregiver/${address}`);
            const { children } = data;

            dispatch(getPatientsTableDataSuccess(children));
            return Promise.resolve(false)

        } catch (error) {
            dispatch(getPatientsTableDataFail(error.message));
            return Promise.reject(false)
        }
    }

export const thunkGetDictData = (dictName: keyof FilterData): ThunkAction<void, PatientsState | TemplatesState | UserDataState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>) => {
        try {
            const { data }: AxiosResponse<DictRequestData> = await loggedInInstance.get(`export/get-dict/${dictName}`);
            const { dict } = data;
            dispatch(getDictSuccess(dict, dictName));
            return Promise.resolve(false)
        } catch (error) {
            dispatch(getDictFail(error.message));
            return Promise.reject(false)
        }
    }


export const thunkGetMeasurementParametersData = (): ThunkAction<Promise<boolean>, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>) => {
        try {
            const { data }: AxiosResponse<ParametersRequestData> = await loggedInInstance.get(`measurement/user-parameters`);
            const { parameters } = data;
            let paramsToOptionTypeArr = _.map(parameters, (el): { label: string, value: string, users: User[] } => {
                return { label: el.name, value: el.id, users: el.users }
            })

            dispatch(getMeasurementParametersSuccess(paramsToOptionTypeArr));
            return Promise.resolve(true)
        } catch (error) {
            dispatch(getDictFail(error.message));
            return Promise.reject(false)
        }
    }

export const thunkGetPlacesData = (filter?: string, type?: number): ThunkAction<Promise<boolean>, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>) => {
        try {
            const { data }: AxiosResponse<PlacesRequestData> = await loggedInInstance.get(`place/search`, {
                params: {
                    filter,
                    type
                }
            });
            const { places } = data;

            dispatch(getPlacesSuccess(places));
            return Promise.resolve(true)
        } catch (error) {
            dispatch(getPlacesFail(error.message));
            return Promise.reject(false)
        }
    }

export const thunkGetMedicineData = (medicineName: string): ThunkAction<Promise<boolean>, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>) => {
        try {
            const { data }: AxiosResponse<MedicineRequestData> = await loggedInInstance.get(`/medicine/medicine-list/${medicineName}`);
            const { medicine_list, } = data;

            dispatch(getMedicinesSuccess(medicine_list));
            return Promise.resolve(true)
        } catch (error) {
            dispatch(getMedicinesFail(error.message));
            return Promise.reject(false)
        }
    }


export const thunkGetMedicinesInStorage = (medicineId: string): ThunkAction<Promise<any[] | boolean>, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>) => {

        try {
            const { data }: AxiosResponse<MedicinesInStorageRequestData> = await loggedInInstance.get(`/medicine/medicines-in-storage?medicine_id=${medicineId}`);
            const { medicines } = data;
            dispatch(getMedicinesInStorageSuccess(medicines, medicineId));
            return Promise.resolve(true)
        } catch (error) {
            dispatch(getMedicinesFail(error.message));
            return Promise.reject(false)
        }
    }

export const thunkChangeCareLevelData = (data: ChangeCareLevelRequestData): ThunkAction<void, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>, getState: () => PatientsState) => {

        loggedInInstance.patch(`/caregiver/parent/level`, {
            child_id: data.child_id,
            level: data.level.id
        })
            .then(
                (res: AxiosResponse<RequestDataBase>) => {
                    const state = getState()
                    let patientsData: Fields[] = state.patients.patientsData || []

                    updatePatientLevel(patientsData, data)
                    return dispatch(getChangeCareLevelSuccess())
                }
            )
            .catch((error: ErrorMessage) => {
                dispatch(getChangeCareLevelFail(error.message))
            })
    };

const updatePatientLevel = (patientsData: Fields[], data: ChangeCareLevelRequestData) => {

    return patientsData.map((v) => {
        let user = v.user
        if (+data.child_id === +user.id) {
            v.level = data.level
        }
        return v
    })
}

export const thunkAddDeviceAndSickness = (partial: { [key: string]: string | number }, userId: number): ThunkAction<Promise<boolean>, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, AuthState, void, Action<any>>>): Promise<boolean> => {

        try {
            await loggedInInstance.patch(`profile/${userId}`, { parameters: partial });
        } catch (error) {
        }
        return Promise.resolve(false)
    }

export const thunkRegisterNewPatient = (form: newPatient.Patient): ThunkAction<Promise<boolean>, PatientsState, void, Action<any>> =>
    async (dispatch: Dispatch<Action | ThunkAction<void, PatientsState, void, Action<any>>>): Promise<boolean> => {
        try {
            if (form.address !== '') {
                const { data }: AxiosResponse<RemedizerApiResponse & { placeId: string }> = await loggedInInstance.post(`place/create`, {
                    name: 'address',
                    address: form.address,
                    type: 5,
                    latitude: 11.2345123,
                    longitude: 52.6789123,
                    country: 134,
                })
                const placeId = data.placeId;
                form.address = placeId;
            }
            try {
                const { data }: AxiosResponse<newPatient.AddPatientApiResponse> = await loggedInInstance.post(`caregiver/register`, form);
                dispatch(addNewPatient(data.user))
                return Promise.resolve(true)

            } catch (error) {
                return Promise.reject(false)
            }
        }
        catch (error) {
            return Promise.reject(false)
        }


    }