import React, { SyntheticEvent } from 'react';
import ReactDOM from "react-dom";
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { newRequestSent } from '../../axiosInstances';

const variantIcon = {
	success: <i className="far fa-check-circle mr-3"></i>,
	warning: <i className="fas fa-exclamation-triangle mr-3"></i>,
	error: <i className="fas fa-exclamation-circle mr-3"></i>,
	info: <i className="fas fa-info-circle mr-3"></i>,
};

const useStyles1 = makeStyles((theme: Theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export interface Props {
	className?: string;
	message?: string;
	onClose?: () => void;
	variant: keyof typeof variantIcon;
}

function MySnackbarContentWrapper(props: Props) {
	const classes = useStyles1();
	const { className, message, onClose, variant, ...other } = props;

	const dispatch = useDispatch();

	React.useEffect(() => {
		return () => { dispatch(newRequestSent()) }
	}, [dispatch])

	return (
		<SnackbarContent
			className={classes[variant]}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					{variantIcon[variant]}
					{message}
				</span>
			}
			action={[
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
					<i className="far fa-times-circle text-white"></i>
				</IconButton>,
			]}
			{...other}
		/>
	);
}

interface SnackBarProps {
	error?: string;
	success: string;
	code?: number
}

const CustomizedSnackbar = (props: SnackBarProps) => {
	const [open, setOpen] = React.useState(false);
	const [errorToDisplay, changeErrorToDisplay] = React.useState('');
	const [successMessage, setSuccessMessage] = React.useState('');

	const { error, success } = props;

	React.useEffect(() => {
		error && error.length > 0 && setOpen(true);
		error && error.length > 0 && changeErrorToDisplay(error);

		success.length > 0 && setOpen(true);
		success.length > 0 && setSuccessMessage(success);
		!error && !success && setOpen(false);

	}, [error, success])

	function handleClose(event?: SyntheticEvent, reason?: string) {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			open={open}
			autoHideDuration={3000}
			onClose={handleClose}
			style={{
				zIndex: 1000000
			}}
		>
			<MySnackbarContentWrapper
				onClose={handleClose}
				variant={error ? 'error' : 'success'}
				message={error ? errorToDisplay || 'Some Unknown Error' : successMessage || 'Success'}
			/>
		</Snackbar>
	);
}

function SnackBarPortal(props: SnackBarProps) {
	return ReactDOM.createPortal(<CustomizedSnackbar {...props} />, document.querySelector("#snackbar") || document.body);
}

export default SnackBarPortal;