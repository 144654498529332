import * as React from 'react';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { AppState } from '../..';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AuthState } from 'Auth/store/reducer';
import { LanguagesList } from '../../Languages';
import { Action } from 'redux';
import { thunkConfirmAccount } from 'Auth/store/thunks';
import { Paper, Typography } from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import RemedizerPulse from '../../components/RemedizerPulse/RemedizerPulse';

interface Props extends RouteComponentProps {
    registrationCompleated: boolean,
    registrationInProgress: boolean,
    confirmRegistration: Function,
    error: string,
    languageList: LanguagesList;
    languageName: string;
}

class ConfirmAccount extends React.Component<Props> {

    componentDidUpdate(prevProps: Props) {
        if (prevProps.languageList !== this.props.languageList) {
            const tokenParam = this.props.location.search;
            const separatedToken = this.getTokenFromParams(tokenParam);
            this.props.confirmRegistration(separatedToken, this.getLangId());
        }
    }

    getLangId() {
        return this.props.languageList[this.props.languageName].id
    }

    getTokenFromParams(tokenParam: string) {
        return tokenParam.split('?token=').pop();
    }

    render() {
        return (
            <div className="row mt-5 ">
                <div className="col-10 ml-auto mr-auto">
                    <Paper>
                        <Typography component="div" style={{ padding: '30px 50px', textAlign: 'center' }}>
                            {this.props.registrationInProgress &&
                                <>
                                    <RemedizerPulse width="40px" height="40px" viewBox={"0 0 108.5 109.333"} style={{ width: 40, height: 40 }} className="" />
                                    <Typography>
                                        <Translate value="confirmAccount.pleaseWait" />
                                    </Typography>
                                </>
                            }
                            {this.props.registrationCompleated &&
                                <Typography component="div" className="text-success">
                                    <p><i className="fas fa-check-circle"></i></p>
                                    <Translate value="confirmAccount.accountConfirmed" />
                                </Typography>
                            }
                            {!this.props.registrationInProgress && !this.props.registrationCompleated && this.props.error &&
                                <Typography component="div" className="text-danger">
                                    <p><i className="fas fa-exclamation-triangle"></i></p>
                                    {/* <Translate value="confirmAccount.somethingGoesWrong" /> */}
                                    <Typography> {this.props.error} </Typography>
                                </Typography>
                            }
                        </Typography>

                        <Typography component="p" style={{ padding: '30px 50px', textAlign: 'center' }}>
                            <NavLink to="/">
                                <Translate value="confirmAccount.backToHomePage" />
                            </NavLink>
                        </Typography>
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    registrationCompleated: state.auth.confirmAccountSuccess,
    registrationInProgress: state.auth.confirmAccountAttempt,
    error: state.auth.error,
    languageName: state.i18n.locale,
    languageList: state.languages.languages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AuthState, void, Action<any>>) => ({
    confirmRegistration: (tokenData: string, languageId: number) => dispatch(thunkConfirmAccount(tokenData, languageId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccount);
