import React from 'react';
import { ReferenceObject } from 'popper.js';
import Popper from '@material-ui/core/Popper';

import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import './Event.css';

import OmittedMeasurement from './Details/OmittedMeasurement';
import OmittedMedicine from './Details/OmittedMedicine';
import Medicine from './Details/Medicine';
import Measurement from './Details/Measurement';
import DefaultEvent from './Details/DefaultEvent';
import Visit from './Details/Visit';
import Consultation from './Details/Consultation';

interface EventProps {
    el: ReferenceObject | null;
	open: boolean;
	details: any;
	doctor?: boolean;
}

const Event = (props: EventProps) => {
	const id = 'event-reference-popper';
	const type = props.details.type;
	let template = <React.Fragment />

	switch(type) {
		case 'omitted_measurement' :
			template = <OmittedMeasurement { ...props.details }/>
		break;
		case 'omitted_medicine' :
			template = <OmittedMedicine {...props.details}/>
		break;
		case 'medicine' :
			template = <Medicine {...props.details}/>
		break;
		case 'measurement' :
			template = <Measurement {...props.details}/>
		break;
		case 'visit' :
			template = <Visit {...props.details} isDoctor={props.doctor} />
		break;
		case 'consultation' :
			template = <Consultation {...props.details} isDoctor={props.doctor} />
		break;
		default:
			template = <DefaultEvent {...props.details}/>
		break;
	}

  	return (
		<>
			<Popper
				id={id}
				open={props.open}
				anchorEl={props.el}
				transition
				placement="top"
			>
				{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<Paper>
						{template}
					</Paper>
				</Fade>
				)}
			</Popper>
		</>
  	);
}

export default Event;