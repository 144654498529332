import { AC_User, UserDataStateTypes } from '../account.types';
import { CaregiverObject } from '../CaregiversCard/CaregiversTypes';

export const GET_ACCOUNT_DATA = '[Account] Get Account Data';
export const GET_ACCOUNT_DATA_SUCCESS = '[Account] Get Account Data Success';
export const GET_ACCOUNT_DATA_FAIL = '[Account] Get Account Data Fail';

export const UPDATE_ACCOUNT_DATA = '[Account] Update Account Data';
export const UPDATE_ACCOUNT_DATA_SUCCESS = '[Account] Update Account Data Success';
export const UPDATE_ACCOUNT_DATA_FAIL = '[Account] Update Account Data Fail';

export const UPDATE_ACCOUNT_DATA_PARTIAL = '[Account] Upadte Part of Account Data'
export const UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS = '[Account] Upadte Part of Account Data Success'
export const UPDATE_ACCOUNT_DATA_PARTIAL_FAIL = '[Account] Upadte Part of Account Data Fail'

export const UPDATE_LANGUAGE = '[Account] Update App Language';
export const UPDATE_LANGUAGE_SUCCESS = '[Account] Update App Language Success';
export const UPDATE_LANGUAGE_FAIL = '[Account] Update App Language Fail';

export const SET_LOCATION_FIELD = '[Account] Set location field';
export const SET_LOCATION_FIELD_SUCCESS = '[Account] Set location field success';
export const SET_LOCATION_FIELD_FAIL = '[Account] Set location field fail';

export const CREATE_LOCATION_FIELD = '[Account] Create location'
export const CREATE_LOCATION_FIELD_SUCCESS = '[Account] Create location Success'
export const CREATE_LOCATION_FIELD_FAIL = '[Account] Create location Fail'

export const GET_ADDRESS_FROM_MAP = '[Account] Get address from map';
export const GET_ADDRESS_FROM_MAP_SUCCESS = '[Account] Get address from map success';
export const GET_ADDRESS_FROM_MAP_FAIL = '[Account] Get address from map fail';

export const GET_COUNTRYID = '[Account] Get country Id';
export const GET_COUNTRYID_SUCCESS = '[Account] Get country Id success';
export const GET_COUNTRYID_FAIL = '[Account] Get country Id fail';

export const UPLOAD_AVATAR = '[Account] Upload Avatar';
export const UPLOAD_AVATAR_SUCCESS = '[Account] Upload Avatar Success';
export const UPLOAD_AVATAR_FAIL = '[Account] Upload Avatar Fail';

export const GET_CAREGIVER_CHILDREN_DATA = '[Account] Get Caregivers Children Data';
export const GET_CAREGIVER_CHILDREN_DATA_FAIL = '[Account] Get Caregivers Children Data Fail';
export const GET_CAREGIVER_CHILDREN_DATA_SUCCESS = '[Account] Get caregivers Children Data Success';

export const GET_CAREGIVER_PARENTS_DATA = '[Account] Get Caregivers Parents Data';
export const GET_CAREGIVER_PARENTS_DATA_FAIL = '[Account] Get Caregivers Parents Data Fail';
export const GET_CAREGIVER_PARENTS_DATA_SUCCESS = '[Account] Get caregivers Parents Data Success';

export const ADD_DIESEASE_MEDICINE_DEVICE = '[Account] Add diesease or medicine device';
export const ADD_DIESEASE_MEDICINE_DEVICE_SUCCESS = '[Account] Add diesease or medicine device Success';
export const ADD_DIESEASE_MEDICINE_DEVICE_FAIL = '[Account] Add diesease or medicine device Fail';
interface GetAccountData {
    type: typeof GET_ACCOUNT_DATA;
}

interface GetAccountDataFail {
    type: typeof GET_ACCOUNT_DATA_FAIL;
    payload: string;
}

interface GetAccountDataSuccess {
    type: typeof GET_ACCOUNT_DATA_SUCCESS;
    payload: AC_User;
    langId: number;
}

interface UpdateAccountData {
    type: typeof UPDATE_ACCOUNT_DATA;
}

interface UpdateAccountDataSuccess {
    type: typeof UPDATE_ACCOUNT_DATA_SUCCESS;
    payload: AC_User;
}

interface UpdateAccountDataFail {
    type: typeof UPDATE_ACCOUNT_DATA_FAIL;
    payload: string;
}

interface UpdateAccountDataPartial {
    type: typeof UPDATE_ACCOUNT_DATA_PARTIAL;
}

interface UpdateAccountDataPartialFail {
    type: typeof UPDATE_ACCOUNT_DATA_PARTIAL_FAIL;
    payload: string;
}

interface UpdateAccountDataPartialSuccess {
    type: typeof UPDATE_ACCOUNT_DATA_PARTIAL_SUCCESS;
    payload: UserDataStateTypes;
}

interface UpdateLanguage {
    type: typeof UPDATE_LANGUAGE;
}

interface UpdateLanguageSuccess {
    type: typeof UPDATE_LANGUAGE_SUCCESS;
    payload: number;
}

interface UpdateLanguageFail {
    type: typeof UPDATE_LANGUAGE_FAIL;
    payload: string;
}

interface SetLocationField {
    type: typeof SET_LOCATION_FIELD;
}

interface SetLocatonFieldSuccess {
    type: typeof SET_LOCATION_FIELD_SUCCESS;
    payload: UserDataStateTypes;
}

interface SetLocatonFieldFail {
    type: typeof SET_LOCATION_FIELD_FAIL;
    payload: string;
}

interface GetAddressFromPlace {
    type: typeof GET_ADDRESS_FROM_MAP;
}

interface GetAddressFromPlaceSuccess {
    type: typeof GET_ADDRESS_FROM_MAP_SUCCESS;
    payload: string;
}

interface GetAddressFromPlaceFail {
    type: typeof GET_ADDRESS_FROM_MAP_FAIL;
    payload: string;
}

interface GetCountrieId {
    type: typeof GET_COUNTRYID;
}

interface GetCountyIdSuccess {
    type: typeof GET_COUNTRYID_SUCCESS;
    payload: number;
}

interface GetCountryIdFail {
    type: typeof GET_COUNTRYID_FAIL;
    payload: string;
}

interface CreateLocationField {
    type: typeof CREATE_LOCATION_FIELD;
}

interface CreateLocationFieldSuccess {
    type: typeof CREATE_LOCATION_FIELD_SUCCESS;
    payload: UserDataStateTypes;
}

interface CreateLocationFieldFail {
    type: typeof CREATE_LOCATION_FIELD_FAIL;
    payload: string;
}

interface UploadAvatar {
    type: typeof UPLOAD_AVATAR;
}

interface UploadAvatarSuccess {
    type: typeof UPLOAD_AVATAR_SUCCESS;
}

interface UploadAvatarFail {
    type: typeof UPLOAD_AVATAR_FAIL;
    payload: string;
}

interface GetCaregiverChildrenData {
    type: typeof GET_CAREGIVER_CHILDREN_DATA;
}

interface GetCaregiverChildrenDataFail {
    type: typeof GET_CAREGIVER_CHILDREN_DATA_FAIL;
    payload: string;
}

interface GetCaregiverChildrenDataSuccess {
    type: typeof GET_CAREGIVER_CHILDREN_DATA_SUCCESS;
    payload: Array<CaregiverObject>;
}

interface GetCaregiverParentsData {
    type: typeof GET_CAREGIVER_PARENTS_DATA;
}

interface GetCaregiverParentsDataFail {
    type: typeof GET_CAREGIVER_PARENTS_DATA_FAIL;
    payload: string;
}

interface GetCaregiverParentsDataSuccess {
    type: typeof GET_CAREGIVER_PARENTS_DATA_SUCCESS;
    payload: Array<CaregiverObject>;
}

interface AddDieseaseMedicineDevice {
    type: typeof ADD_DIESEASE_MEDICINE_DEVICE;
}

interface AddDieseaseMedicineDeviceFail {
    type: typeof ADD_DIESEASE_MEDICINE_DEVICE_FAIL;
    payload: string;
}

interface AddDieseaseMedicineDeviceSuccess {
    type: typeof ADD_DIESEASE_MEDICINE_DEVICE_SUCCESS;
    payload: Array<CaregiverObject>;
}

export type AccountActionTypes = GetAccountData |
    GetAccountDataFail |
    GetAccountDataSuccess |
    UpdateAccountData |
    UpdateAccountDataFail |
    UpdateAccountDataSuccess |
    UpdateAccountDataPartial |
    UpdateAccountDataPartialFail |
    UpdateAccountDataPartialSuccess |
    UpdateLanguage |
    UpdateLanguageFail |
    UpdateLanguageSuccess |
    SetLocationField |
    SetLocatonFieldSuccess |
    SetLocatonFieldFail |
    GetAddressFromPlace |
    GetAddressFromPlaceFail |
    GetAddressFromPlaceSuccess |
    GetCountrieId |
    GetCountryIdFail |
    GetCountyIdSuccess |
    CreateLocationField |
    CreateLocationFieldFail |
    CreateLocationFieldSuccess |
    UploadAvatarFail |
    UploadAvatar |
    UploadAvatarSuccess |
    GetCaregiverChildrenData |
    GetCaregiverChildrenDataFail |
    GetCaregiverChildrenDataSuccess |
    GetCaregiverParentsData |
    GetCaregiverParentsDataFail |
    GetCaregiverParentsDataSuccess |
    AddDieseaseMedicineDevice |
    AddDieseaseMedicineDeviceFail |
    AddDieseaseMedicineDeviceSuccess
    ;
